
import BaseModel from '../../../helpers/basemodel'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  data({ selectedObject: item }) {
    return {
      ...item,
    }
  },

  listOptions({ listOptions: opt }) {
    const query = new QueryString([
      `limit=${opt.pagelimit}`,
      `skip=` + (opt.pagenumber - 1) * opt.pagelimit,
    ])

    if (opt.format) query.push(`format=${opt.format}`)
    if (opt.brand) query.push(`brand=${opt.brand}`)
    if (opt.category) query.push(`category=${opt.category}`)
    if (opt.supplier) query.push(`supplier=${opt.supplier}`)
    if (opt.search) query.push(`search=${opt.search}`)

    return query.string
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get current_stock() {
        return this.last_stock + this.current_in - this.current_out
      },
    }))
  }
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/item/transactionreport?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}