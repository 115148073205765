
import BaseModel from '../../../helpers/basemodel'
import Api from '../../../helpers/api'
import moment from 'moment'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
  period: moment().format(`YYYYMM`)
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  currentPeriod({ period }) {
      return period
  },

  prevPeriod({ period }) {
      return Number(period) - 1
  },

  listOptions({ listOptions: opt }) {
    const query = new QueryString()

    return query.string
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/monthlysummary/${getters.currentPeriod}${getters.listOptions}`)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}