
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import moment from 'moment'

/**
 * Const
 *
 */
const api = new Api()
const TRANSTYPE = {
  I: `STOK MASUK`,
  O: `STOK KELUAR`,
}



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  itemlist: [],
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get date_readable() {
        if (!this.date) return ``
        return moment(this.date).format(`DD/MM/YYYY`)
      },
      get type_readable() {
        if (!this.type) return ``
        return TRANSTYPE[this.type]
      },
      get note_html() {
        if (!this.note) return ``
        return this.note.replace(`\n`, `<br>`)
      },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/item/transaction?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}