<script>
export default {
  props: {
    value: null,
    placeholder: {
      type: String,
      default: ``,
    },
    formValue: {
      type: null,
      default: ``
    },
    defaultValue: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    editmode: 0,
  }),
  methods: {
    submit(event) {
      this.$emit(`submit`, event.target.value)
      this.editmode = 0
    }
  },
}
</script>

<style scoped>
  a > span {
    color: #646464;
    border-bottom: 1px dashed #aaaaaa;
  }
</style>

<template>
  <div>
    <a v-if="!editmode" href="#" @click.prevent="editmode = 1">
      <span v-if="value">{{ value }}</span>
      <span v-if="!value && placeholder"><i>{{ placeholder }}</i></span>
    </a>

    <select class="form-control" v-else v-focus
      :value="formValue"
      @blur="editmode = 0"
      @input="submit"
    >
      <option value="" v-if="defaultValue">{{ placeholder }}</option>
      <option v-for="(opt, index) in options" :key="index"
        :value="opt.value"
        v-html="opt.label"
      />
    </select>
  </div>
</template>