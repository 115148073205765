<template>
  <ol class="breadcrumb push-10-t">
    <li v-for="link in links" :key="link.id">
      <span class="text-primary font-w700" v-if="(link.id == linkCount - 1)">
        {{ link.title }}
      </span>

      <span v-else>
        {{ link.title }}
      </span>
    </li>
  </ol>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    links: `getLinks`,
    linkCount: `getLinkCount`,
  }),
}
</script>