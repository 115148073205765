
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/loadingState`,
    data: `Employee/profile`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  updateData: `Employee/update`,
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.isProfileLocked = true
  this.profile = {
      attend_time_coming: this.data.attend_time_coming,
      attend_absent_deduction: this.data.attend_absent_deduction,
      attend_late_deduction: this.data.attend_late_deduction,
   }

}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.data.id,
    data: this.profile,
  })

  this.reset()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="SETTING ABSENSI"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)" >
    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <label>Jam Masuk / Absen</label>
          <p v-if="profile.attend_time_coming">{{ profile.attend_time_coming }}</p>
          <p v-else><span class="subdata">(none)</span></p>
        </Col12>

        <Col12>
          <label>Potongan Absen</label>
          <p>Rp. {{ profile.attend_absent_deduction || 0 }} <span class="subdata">/ hari</span></p>
        </Col12>

        <Col12>
          <label>Potongan Terlambat</label>
          <p>Rp. {{ profile.attend_late_deduction || 0 }} <span class="subdata">/ menit</span></p>
        </Col12>
      </Row>
      <FormGroup v-else>
        <TextField
          id="attend_time_coming"
          label="Jam Masuk / Absen"
          sublabel="Contoh: 9:00"
          width="8"
          :forceupper=true
          v-model="profile.attend_time_coming">
        </TextField>

        <TextField
          id="attend_absent_deduction"
          label="Potongan Tidak Hadir"
          width="8"
          :forceupper=true
          :raddon=true
          v-model="profile.attend_absent_deduction">
          <template #after>/ hari</template>
        </TextField>

        <TextField
          id="attend_late_deduction"
          label="Potongan Terlambat"
          width="8"
          :forceupper=true
          :raddon=true
          v-model="profile.attend_late_deduction">
          <template #after>/ menit</template>
        </TextField>
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="updateProfile"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>