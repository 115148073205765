<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Data
 *
 * */
const data = () => ({
  username: ``,
  password: ``,
})


/**
 * Computed
 *
 * */
const computed = mapGetters({
  isloading: `Auth/loadingState`,
  iserror: `Auth/errorState`,
  errmsg: `Auth/errorMessage`,
})

/**
 * Methods
 *
 * */
const methods = mapActions({
  auth: `Auth/login`,
  showAlert: `Auth/showAlert`,
  dismissAlert: `Auth/dismissAlert`,
})
methods.login = async function() {
  if (!this.username) return this.showAlert(`Username tidak boleh kosong.`)
  if (!this.password) return this.showAlert(`Password tidak boleh kosong.`)

  const auth = await this.auth({
    username: this.username,
    password: this.password
  })

  if (auth) window.location.href = '/'
}

export default {
  data,
  computed,
  methods,
}
</script>

<template>
  <Block :header=true :loading="isloading" title="KASWARUNG DASHBOARD" color="primary">
    <Row>
      <Col10Center>
        <!-- <h1 class="h2 font-w600 push-30-t push-5 text-center">KASWARUNG DASHBOARD</h1> -->
        <p class="text-center">
          <img src="/img/app/logo.png" height="72"/>
        </p>

        <form class="form-horizontal push-30-t push-50" @submit.prevent="login">
          <div class="form-group">
            <TextField
              id="login-username"
              name="login-username"
              label="Username"
              :forceupper=true
              allow="a-zA-Z0-9@."
              v-model="username">
            </TextField>
            <div class="col-xs-12">
                <label for="login-password">Password</label>
                <input class="form-control" type="password" id="login-password" name="login-password" v-model="password">
            </div>
          </div>

          <p v-if="errmsg" class="text-danger">* {{ errmsg }}</p>

          <div class="form-group">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <Button color="primary" icon="hand-o-right" iconpos="right" text="Log In" />
            </div>
          </div>
        </form>

        <hr>

        <p class="text-center">
          Belum punya akun?
          <a href="/register">Daftar Sekarang</a>
        </p>
      </Col10Center>
    </Row>
  </Block>
</template>
