<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ConfirmDialog from '@/components/uicomp/ModalDialog-Confirm'
const components = {
  layout,
  ConfirmDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Sales/loadingState`,
    data: `Sales/data`,
    paymentlist: `Sales/paymentlist`,
    paymentMethodOptions: `PayMethod/options`,
  }),
  total_unpaid: function() {
    return this.data.total_unpaid
  },
  validated: function() {
    return this.input.amount
      && this.input.method_id
      && this.input.approval_code
  }
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Sales/fetchData`,
  modelPay: `Sales/pay`,
  fetchListPayment: `Sales/fetchListPayment`,
})

methods.reload = async function () {
  this.fetchListPayment()
}

methods.goback = function() {
  this.$router.go(-1)
}

methods.reset = function() {
  this.input = {
    method_id: ``,
    amount: this.data.total_unpaid,
    approval_code: `0`,
  }
}

methods.confirmPay = function() {
  this.$refs.ConfirmDialog.confirm(`PAY`, {
    confmsg: `Apakah anda yakin ingin melakukan pembayaran`,
    alertmsg: `Data yang sudah masuk tidak dapat dihapus kembali.`
  })
}

methods.confirmAction = async function (action) {
  if (action === `PAY`) {
    const success = await this.modelPay(this.input)
    this.reset()

    if (success) return this.goback()
  }
}

/**
 * Watcher
 *
 */
const watch = {
  total_unpaid: function(value) {
    this.input.amount = value
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  if (!this.paymentlist.length) await this.fetchListPayment()

  this.reset()
  this.$store.commit(`add_breadcrumb`, this.data.record_id)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const id = this.$route.params.id

  if (!this.data.id) await this.fetchData(id)

  this.$store.commit(`set_page_title`, `Kasir`)
  this.$store.commit(`set_breadcrumb`, [ `Data Penjualan`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable :width="8">
        <template #header>RIWAYAT PEMBAYARAN</template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <label>Record ID</label>
              <p>{{ data.record_id }}</p>
            </div>
            <div class="col-md-6">
              <label>Sisa Pembayaran</label>
              <p>Rp. {{ data.total_unpaid_readable }}</p>
            </div>
          </Row>

          <Row>
            <TableList>
              <template #head>
                <th class="text-center" width="48">#</th>
                <th width="120" class="text-left">Tanggal</th>
                <th width="120" class="text-left">Metode</th>
                <th class="text-left">Kode Approval</th>
                <th width="120" class="text-right">Jumlah</th>
                <th width="40" class="text-right"></th>
              </template>

              <template #body>
                <tr v-for="(item, index) in paymentlist" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-left">{{ item.date_readable }}</td>
                  <td class="text-left">{{ item.method_name }}</td>
                  <td class="text-left">{{ item.approval_code }}</td>
                  <td class="text-right">{{ item.amount_readable }}</td>
                  <td class="text-right">
                    <label v-if="item.amount < 0"
                      class="label label-warning"
                      data-toggle="tooltip"
                      title="Refund">
                      R
                    </label>
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>
        </template>
      </BlockTable>

      <BlockTable :width="4" v-if="!data.locked">
        <template #header>FORM PEMBAYARAN</template>

        <template #content>
          <FormGroup>
            <TextField
              label="Jumlah"
              width="12"
              :forceupper=true
              v-model="input.amount" />

            <SelectField
              label="Metode Bayar"
              width="12"
              defaultLabel="-- Pilih Metode Bayar --"
              defaultValue=""
              :options=paymentMethodOptions
              v-model="input.method_id" />

            <TextField
              label="Ref. Pembayaran"
              width="12"
              :forceupper=true
              v-model="input.approval_code" />
          </FormGroup>
        </template>

        <template #footer>
          <ButtonFormSet
            :disabled="!validated"
            :buttoncancel=false
            labelsave="Submit"
            @submit="confirmPay"
          />
        </template>
      </BlockTable>
    </Row>

    <ConfirmDialog ref="ConfirmDialog" @submit="confirmAction($event)" />
  </layout>
</template>