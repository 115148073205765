
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  selectedFilterMode: false,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listMeta: (state, getters) => {
    const total = getters.list.reduce((ac, item) => {
      console.log(item)
      ac.asset += item.total_asset
      ac.expense += item.total_expense
      ac.over += (item.balance > 0) ? item.balance * item.price_asset : 0

      return ac
    }, { asset: 0, expense: 0, over: 0, })

    return {
      version: state.listVersion,
      nrecord: state.nrecord,
      total_asset: total.asset,
      total_expense: total.expense,
      total_over: total.over,
      pagelimit: state.listOptions.pagelimit,
      curlength: state.list.length,
      curpage: state.listOptions.pagenumber,
      filter: state.listOptions,
      get curstart() {
        if (this.nrecord < 1) return 0
        return (this.curpage - 1) * this.pagelimit + 1
      },
      get curend() {
        if (this.nrecord < 1) return 0
        return (this.curpage - 1) * this.pagelimit + this.curlength
      },
      get npage() {
        if (this.nrecord < 1) return 0
        return Math.ceil(this.nrecord / this.pagelimit)
      },
      get total_asset_readable() {
        return this.total_asset.toLocaleString()
      },
      get total_expense_readable() {
        return this.total_expense.toLocaleString()
      },
      get total_over_readable() {
        return this.total_over.toLocaleString()
      },
    }
  },

  listOptions({ listOptions: opt }) {
    const query = new QueryString()

    if (opt.category) query.push(`category=${opt.category}`)
    if (opt.supplier) query.push(`supplier=${opt.supplier}`)
    if (opt.type) query.push(`type=${opt.type}`)

    return query.string
  },

  list({ list, selectedFilterMode, selectedList }) {
    const filteredList = (selectedFilterMode)
      ? list.filter(({ id }) => selectedList.includes(id))
      : list

    return filteredList.map((item) => ({
      ...item,
      current_stock_readable: item.current_stock.toLocaleString(),
      minimum_stock_readable: item.minimum_stock.toLocaleString(),
      price_asset_readable: item.price_asset.toLocaleString(),
      ischecked: state.selectedList.includes(item.id),
      get balance() { return this.current_stock - this.minimum_stock },
      get status() {
        if (this.balance < 0) return `Kurang`
        else if (this.balance > 0) return `Overload`
        else return `Balanced`
      },
      get minus() { return this.balance < 0 ? this.balance * -1 : 0 },
      get minus_readable() { return this.minus.toLocaleString() },
      get total_asset() { return this.current_stock * this.price_asset },
      get total_asset_readable() { return this.total_asset.toLocaleString() },
      get total_expense() { return this.minus * this.price_asset },
      get total_expense_readable() { return this.total_expense.toLocaleString() },
      get total_over() { return (this.balance > 0) ? this.balance * this.price_asset : 0 },
      get total_over_readable() { return this.total_over.toLocaleString() },
    }))
  }
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/item/stock?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  filterListSelected({ commit, state }) {
    commit(`set_selected_filter_mode`, true)
  },

  resetListSelected({ commit }) {
    commit(`set_selected_filter_mode`, false)
    commit(`clear_selected_list`)
    commit(`update_list_version`)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_selected_filter_mode(state, value) {
    state.selectedFilterMode = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}