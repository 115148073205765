
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import AddPiutangAccountDialog from './AddPiutangAccountDialog'
const components = {
  layout,
  AddPiutangAccountDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/loadingState`,
    employeeid: `Employee/id`,
    data: `Employee/profile`,
  }),
}

computed.deptAccounts = function () {
  return this.data.deptAccounts || []
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  updateData: `Employee/update`,
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.isProfileLocked = true
  this.input = { ...this.data }
}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.employeeid,
    data: this.input,
  })

  await this.fetchData(this.employeeid)
    .then(() => this.reset())
}

methods.gotoSallarySetting = async function() {
  this.$router.push(`/employee/sallary-setting/${this.employeeid}`)
}

methods.addPiutangAccount = async function() {
  this.$refs.AddPiutangAccountDialog.show()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="GAJI DAN TUNJANGAN KARYAWAN"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    :editbutton="false"
  >
    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <p>
            <label>Setting Gaji</label>
            <router-link :to="`/employee/sallary-setting/${employeeid}`">
              <TButton id="btn-setting" color="primary" text="BUKA" tooltip="Setting Gaji" />
            </router-link>
          </p>
          <p>
            <label>Slip Gaji</label>
            <router-link :to="`/employee/sallary/${employeeid}`">
              <TButton id="btn-sallary" color="primary" text="BUKA" tooltip="Lihat Gaji" />
            </router-link>
          </p>

          <hr />
        </Col12>

        <Col12>
          <p>
            <label>Akun Rekening Piutang</label>
            <TButton color="primary" text="+" @click="addPiutangAccount" tooltip="Tambah Akun Rekening" />
          </p>
        </Col12>

        <Col12 v-for="(item, index) in deptAccounts" :key="index">
          <label>{{ item.name }}</label>
          <p class="pull-right">Rp. {{ item.balance.readable(0) }}</p>
        </Col12>
      </Row>

      <AddPiutangAccountDialog ref="AddPiutangAccountDialog"></AddPiutangAccountDialog>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="updateProfile"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>