
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/storepurchase/category?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchOptions({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/storepurchase/category?format=options`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_options`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/storepurchase/category/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async update({ commit, dispatch, rootState }, { id, data }) {
    commit(`loading_mode`, 1)

    if (!id) id = ``

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      await dispatch(`fetchData`, result.id)

      if (id) alert.success(`Data berhasil diupdate`)
      else alert.success(`Data berhasil ditambahkan`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STPURCHASE_CATEGORY_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/storepurchase/category/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async remove({ commit, dispatch, rootState }, id) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_options`)
      // await dispatch(`fetchList`)
      alert.success(`Data berhasil dihapus`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STPURCHASE_CATEGORY_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.delete(`/storepurchase/category/${id}`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}