
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import ReportTable from './ReportTable'
const components = {
  ReportTable,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/SallarySetting/loadingState`,
    employee: `Employee/profile`,
    meta: `Employee/SallarySetting/listMeta`,
    data: `Employee/SallarySetting/list`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchEmployee: `Employee/fetchData`,
  fetch: `Employee/SallarySetting/fetch`,
  setListOption: `Employee/SallarySetting/setListOption`,
})

methods.reload = async function() {
  const id = this.$route.params.id

  await this.fetch(id)
}

methods.goback = function () {
  return this.$router.go(-1)
}

/**
 * Watchers
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const employee_id = this.$route.params.id

  if (!this.employee || this.employee.id !== employee_id) await this.fetchEmployee(employee_id)
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <BlockTable>
      <template #header>
        SETTING GAJI KARYAWAN
      </template>

      <template #content>
        <Row>
          <ReportTable />

          <Col12>
            <p><i>* Settingan ini akan diterapkan pada penggajian sebelum tanggal 1</i></p>
          </Col12>
        </Row>
      </template>
    </BlockTable>
  </Row>
</template>