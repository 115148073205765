<script>

export default {
  props: {
    text: {
      type: String,
      default: ``,
    },

    icon: {
      type: String,
      default: ``,
    },

    tooltip: {
      type: String,
      default: ``,
    },

    color: {
      type: String,
      default: `default`
    }
  }
}
</script>


<template>
  <Button class="pull-right push-10-l"
    size="xs"
    :tooltip="tooltip"
    :icon="icon"
    :color="color"
    :text="text"
    @click="$emit(`click`)"
  />
</template>