<script>
export default {
  props: {
    width: {
      type: Number,
      default: 12,
    },
    title: String,
    loading: Boolean,
    locked: Boolean,
  },
  computed: {
    panelWidth() {
      return `col-md-${this.width}`
    }
  }
}
</script>

<style scoped>
  .block-content-footer {
    margin-top: -20px;
  }
  .content-heading Button {
    margin-top: -5px;
  }
</style>

<template>
  <div :class="panelWidth">
    <h2 class="content-heading">
      <slot name="header" />
    </h2>

    <Block :header=false :loading="loading">
      <slot name="content" />
    </Block>

    <div class="block-content-footer">
      <slot name="footer" />
    </div>
  </div>
</template>