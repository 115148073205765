
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import ReportTable from './ReportTable'
import PaySallaryDialog from './PaySallaryDialog'
const components = {
  ReportTable,
  PaySallaryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    filter_month: moment().format(`MM`),
    filter_year: moment().format(`YYYY`),
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/Sallary/loadingState`,
    employee: `Employee/profile`,
    meta: `Employee/Sallary/listMeta`,
    data: `Employee/Sallary/data`,
    label: `Employee/Sallary/categoryLabel`,
    dateMonth: `dateMonth`,
    dateYear: `dateYear`,
  }),
}

computed.sallaryReportList = function() {
  const data = this.data.items
    ? Object.entries(this.data.items)
    : []

  const accumulator = {
    totalIncome: 0,
    totalDeduction: 0,
    totalPayable: 0,
    list: {}
  }

  const res = data
    .reduce((accumulate, [ id, data ]) => {
      if (id.startsWith(`SI_`)) accumulate.totalIncome += data.amount
      if (id.startsWith(`SO_`)) accumulate.totalDeduction += data.amount
      if (id.startsWith(`SI_`) && !data.account_id) accumulate.totalPayable += data.amount

      accumulate.list[id] = {
        id,
        value: data.amount,
        name: data.name,
        account_id: data.account_id
      }

      return accumulate
    }, accumulator)

  return res
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchEmployee: `Employee/fetchData`,
  fetch: `Employee/Sallary/fetch`,
  setListOption: `Employee/Sallary/setListOption`,
  pay: `Employee/Sallary/pay`,
})

methods.reload = async function() {
  const id = this.$route.params.id

  await this.fetch(id)
}

methods.goback = function () {
  return this.$router.go(-1)
}

methods.paySallary = async function () {
  this.$refs.PaySallaryDialog.show()
}

methods.paySallaryAction = async function (params) {
  await this.pay({
    employee_id: this.$route.params.id,
    params,
  })

  await this.reload()
  this.$refs.PaySallaryDialog.dismiss()
}

/**
 * Watchers
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_month: function (n, o) {
    n !== o && this.setListOption({ month: n })
  },
  filter_year: function (n, o) {
    n !== o && this.setListOption({ year: n })
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  if (this.meta.filter.month) this.filter_month = this.meta.filter.month
  if (this.meta.filter.year) this.filter_year = this.meta.filter.year

  this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const employee_id = this.$route.params.id

  if (!this.employee || this.employee.id !== employee_id) await this.fetchEmployee(employee_id)
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <BlockTable>
      <template #header>
        SLIP GAJI KARYAWAN
      </template>

      <template #content>
        <Row>
          <Col6>
            <table class="no-style">
              <tr>
                <td width="160"></td>
                <td width="10"></td>
                <td></td>
              </tr>
              <tr>
                <td><label>NAMA</label></td>
                <td><label>:</label></td>
                <td>{{ employee.name }}</td>
              </tr>
              <tr>
                <td><label>PERIODE</label></td>
                <td><label>:</label></td>
                <td>
                  <ul class="list-inline-style">
                    <li>
                      <SelectFieldChangable
                        :defaultValue="false"
                        :value="dateMonth.labelFromCode[filter_month]"
                        :formValue="filter_month"
                        :options="dateMonth.options"
                        @submit="filter_month = $event"
                      />
                    </li>
                    <li>
                      <SelectFieldChangable
                        :defaultValue="false"
                        :value="filter_year"
                        :formValue="filter_year"
                        :options="dateYear.options"
                        @submit="filter_year = $event"
                      />
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
            </table>
          </Col6>
          <Col6>
            <label class="label label-success pull-right" v-if="data.id && data.is_paid">Sudah Dibayar</label>

            <ButtonR text="Bayar Gaji" color="primary"
              v-if="data.id && !data.is_paid"
              @click="paySallary"
            />
            <br style="clear: both">
          </Col6>

          <Col12>
            <br style="clear: both">
          </Col12>

          <ReportTable
            :data="sallaryReportList.list"
            :totalincome="sallaryReportList.totalIncome"
            :totaldeduction="sallaryReportList.totalDeduction"
            :totalpayable="sallaryReportList.totalPayable - sallaryReportList.totalDeduction"
            :editable="data.id && !data.is_paid"
          />
        </Row>
      </template>
    </BlockTable>

    <PaySallaryDialog ref="PaySallaryDialog" @submit="paySallaryAction" />
  </Row>
</template>