
import merge from 'lodash/merge'

import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  loaded: false,
  data: {
    transaction: {
      unpaid_tx_count: 0,
      unpaid_tx_amount: 0,
    },
  },
}


/**
 * Getters
 *
 */
const getters = {
  loadingState: ({ loaded }) => loaded > 0,

  transaction: (state) => state.data.transaction,
}


/**
 * Actions
 *
 */
const actions = {
  async fetch({ commit }, modules) {
    commit(`loading_mode`, 1)

    const modQuery = modules.map(i => `modules=${i}`).join(`&`)

    let action = api.get(`/storeinfo?${modQuery}`)
    let callback = ({ data }) => {
      commit(`loading_mode`, 0)
      commit(`set_data`, data)
    }

    return action
      .then(callback)
      .catch(err => {
        commit(`loading_mode`, 0)
        alert(`danger`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  loading_mode(state, mode) {
    mode = (mode) ? 1 : -1
    state.loaded += mode
  },

  set_data(state, data) {
    state.data = merge(state.data, data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}