<template>
  <div :class="[ 'text-field', (width) ? `col-sm-${width}` : `col-sm-12` ]">
    <label :for="componentid">{{ label }}</label>
    <div :class="[isInputGroup, isBorderless, isUppercased]">
      <span class="input-group-addon" v-if="laddon">
        <slot name="before"></slot>
      </span>

      <input class="form-control"
        ref="inputfield"
        autocomplete="off"
        :id="componentid"
        :placeholder="placeholder"
        :value="value"
        :readonly="disabled"
        :type="type"
        @input="handle"
        @keypress="keypress"
        @paste="paste"
        />

      <span class="input-group-addon" v-if="raddon">
        <slot name="after"></slot>
      </span>
    </div>
    <p class="sublabel" v-html="sublabel"></p>
  </div>
</template>

<script>
import forceucase from '@/helpers/forminput-forceupper'

let inputDelayTimer = null

export default {
  props: {
    id: String,
    value: {},

    label: String,
    sublabel: String,
    placeholder: String,
    type: {
      type: String,
      default: `text`,
    },

    width: String,
    laddon: {
      type: Boolean,
      default: false,
    },
    raddon: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceupper: {
      type: Boolean,
      default: false,
    },
    styleupper: {
      type: Boolean,
      default: false,
    },
    allow: {
      type: String,
      default: ``,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      input: null,
    }
  },
  computed: {
    componentid() {
      if (this.id) return this.id

      return this.label ? this.label.toLowerCase().replace(' ', '') : ``
    },
    isInputGroup() {
      return (this.laddon || this.raddon) ? `input-group` : ``
    },
    isBorderless() {
      return this.borderless ? `borderless` : ``
    },
    isUppercased() {
      return this.styleupper ? `styleupper` : ``
    },
    filterExp() {
      if (!this.allow) return false

      return RegExp('[^' + this.allow + ']', `g`)
    },
  },
  methods: {
    handle(event) {
      this.data = event.target.value || null
      if (!this.delay) return this.$emit(`input`, this.data)

      clearTimeout(inputDelayTimer)
      inputDelayTimer = setTimeout(() => {
        this.$emit(`input`, this.data)
      }, this.delay)
    },
    keypress(event) {
      if (event.keyCode === 13) this.$emit(`enter`, this.data)

      if (this.filterExp && event.key.match(this.filterExp)) {
        event.preventDefault()
        return false
      }

      if (this.forceupper) forceucase(event)
    },
    paste(event) {
      const pastedText = event.clipboardData.getData('text')
      event.pastedText = pastedText

      if (this.filterExp && pastedText.match(this.filterExp)) {
        const filteredText = pastedText.replace(this.filterExp, ``)
        event.pastedText = filteredText
      }

      if (this.forceupper) forceucase(event)
    },
    focus() {
      this.$refs.inputfield.focus()
    }
  }
}
</script>

<style scoped>
  .sublabel {
    font-size: 80%;
    font-style: italic;
    margin-top: 5px;
    color: #999999;
  }

  .borderless input {
    background: none;
    border: none;
  }
</style>