
import Brand from './brand'
import Category from './category'
import Supplier from './supplier'
import Item from './item'
import Service from './service'
import Package from './package'
import Stock from './stock'
import Transaction from './transaction'
import TransactionItem from './transactionitem'
import TransactionReport from './transactionreport'
import SOpname from './sopname'
import Expenditure from './expenditure'
import ExpenditureReport from './expenditure-report'
import ExpenditureReportItem from './expenditure-report-item'
import ExpenditureReportPayment from './expenditure-report-payment'

export default {
  namespaced: true,
  modules: {
    Brand,
    Category,
    Item,
    Supplier,
    Service,
    Package,
    Stock,
    Transaction,
    TransactionItem,
    TransactionReport,
    SOpname,
    Expenditure,
    ExpenditureReport,
    ExpenditureReportItem,
    ExpenditureReportPayment,
  }
}