<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import Profile from './Entry-Profile'
import CostSetting from './Entry-Cost'
import ResellerCommissionSetting from './Entry-ResellerCommission'
import AttendanceSetting from './Entry-AttendanceSetting'
import AccountSetting from './Entry-Account'

const components = {
  layout,
  Profile,
  CostSetting,
  ResellerCommissionSetting,
  AccountSetting,
  AttendanceSetting,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    loadingState: 0,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  employeeid: `Employee/id`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
})

methods.goback = function() {
  return this.$router.push(`/employee`)
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Karyawan`, `List` ])

  const id = this.$route.params.id

  // if (this.employeeid !== id) {
    this.loadingState += 1
    this.fetchData(id)
      .then(() => this.loadingState -= 1)
  // }
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <div v-if="!loadingState">
      <Row>
        <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
      </Row>
      <Row style="margin-bottom: 15px">
        <Profile />
        <AttendanceSetting />
        <CostSetting />
        <ResellerCommissionSetting />
        <AccountSetting />
      </Row>
    </div>
  </layout>
</template>