
import RouteHelper from '../helpers/routes'
const routes = RouteHelper();

/**
 * Base Route Path = `/auth`
 *
 */

import Register from '@/views/Auth/Register.vue'
routes.set(``, Register)

export default {
  children: routes.get(),
}