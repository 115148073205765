
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Sales/data`,
  processing: `Sales/loadingState`,
})
computed.date_readable = function () {
  if (!this.input.date_ordered) return ``
  return moment(this.input.date_ordered).format('DD/MM/YYYY HH:mm')
}
computed.validate = function() {
  return !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Sales/fetchData`,
  entry: `Sales/update`,
})

methods.reset = function() {
  this.input = {
    date_ordered: moment().format(`YYYY-MM-DD HH:mm`),
  }
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.addrecord = async function() {
  const data = this.input
  const successid = await this.entry({ data })

  if (successid) {
    this.dismiss()
    this.$router.push(`/sales/${successid}`)
  }
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog"
    labelsave="Confirm"
    :valid="validate"
    @submit="addrecord" >
    <p>
      Konfirmasi untuk menambahkan record baru.
    </p>

    <p class="font-w700">
      {{ date_readable }}
    </p>
  </EntryDialog>
</template>