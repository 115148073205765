
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    dialogTitle: `Tambah Record`,
    input: {},
    costTypeOptions: [
      { value: `F`, label: `Fixed` },
      { value: `P`, label: `Persentase` },
    ],
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `PayMethod/data`,
  processing: `PayMethod/loadingState`,
  accountOptions: `CashAccount/options`,
})
computed.validate = function() {
  return !!this.input.name && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `PayMethod/fetchData`,
  entry: `PayMethod/update`,
})

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  this.dialogTitle = `Tambah Record`
  return this.fetch(null).then(this.show)
}

methods.edit = async function(id) {
  this.id = id
  this.dialogTitle = `Edit Record`
  return this.fetch(id).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id
  const success = await this.entry({ id, data })

  if (success) this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord">
    <FormGroup>
      <TextField
        label="Metode Pembayaran"
        :forceupper=true
        v-model="input.name" />

      <SelectField
        label="Akun Keuangan"
        :options="accountOptions"
        v-model="input.account_id" />

      <SelectField
        label="Biaya Transaksi"
        :options="costTypeOptions"
        v-model="input.cost_type" />

      <TextField
        v-if="input.cost_type === `F`"
        label="Jumlah Potongan"
        :forceupper=true
        :laddon="true"
        v-model="input.cost_value" >
        <template #before>Rp.</template>
      </TextField>

      <TextField
        v-if="input.cost_type === `P`"
        label="Jumlah Potongan"
        :forceupper=true
        :raddon="true"
        v-model="input.cost_value" >
        <template #after>%</template>
      </TextField>

    </FormGroup>
  </EntryDialog>
</template>