<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="modal">
    <div :class="modalclass">
      <div class="modal-content">
        <div class="block block-themed block-transparent">
          <div class="block-header bg-primary-dark">
            <ul class="block-options">
              <li>
                <button data-dismiss="modal" type="button"><i class="si si-close"></i></button>
              </li>
            </ul>
            <h3 class="block-title">{{ title }}</h3>
          </div>
          <div class="block-content">
            <slot></slot>
          </div>
        </div>

        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    modalid: String,
    title: String,
    size: String,
  },
  computed: {
    modalsize() {
      if (this.size === `sm`) return `modal-sm`
      else if (this.size === `lg`) return `modal-lg`
      else return `modal-md`
    },
    modalclass() {
      return [
        `modal-dialog`,
        this.modalsize,
      ]
    }
  },
  methods: {
    show: function () {
      window.$(this.$refs.modal).modal(`show`)
    },
    dismiss: function () {
      window.$(this.$refs.modal).modal(`hide`)
    },
  },
  mounted: function() {
    window.$(this.$refs.modal).on(`shown.bs.modal`, () => this.$emit(`shown`))
  }
}
</script>
