<script>
import { mapGetters, mapActions } from 'vuex'
const jQuery = window.$

/**
 * Props
 *
 */
const props = {
  id: {
    type: String,
  },
  valid: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: `Tambah Record`,
  },
  addbutton: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
}

/**
 * Data
 *
 */
const data = function() {
  return {
    selectedLabel: ``,
    selectedValue: undefined,
    filterValue: undefined,
  }
}

/**
 * Computed
 *
 */
const optionShowed = 6
const computed = {
  list() {
    let options = this.options

    if (this.filterValue) {
      const filters = this.filterValue.trim().split(` `).map(f => new RegExp(f, `ig`))
      options = this.options.map((item) => {
        item.matched = filters.reduce((ac, matcher) => {
          if(item.label.match(matcher)) ac += 1
          return ac
        }, 0)
        return item
      })
      options = options
        .filter(i => i.matched > 0)
        .sort((a, b) => b.matched - a.matched)
    }

    return options
  },
  listShowed() {
    return this.list.slice(0, optionShowed)
  },
  remainList() {
    const length = this.list.length
    return (length > optionShowed) ? (length - optionShowed) : 0
  },
}

/**
 * Methods
 *
 */
const methods = {}

methods.reset = function () {
  this.selectedLabel = ``
  this.selectedValue = undefined
  this.filterValue = undefined
}

methods.show = function () {
  jQuery(this.$refs.modal.$el).modal(`show`)
}

methods.dismiss = function () {
  this.reset()
  jQuery(this.$refs.modal.$el).modal(`hide`)
}

methods.select = function (value, label) {
  this.selectedLabel = label
  this.selectedValue = value
}

methods.submit = function () {
  this.$emit(`select`, this.selectedValue)
  this.dismiss()
}

export default {
  props,
  data,
  computed,
  methods,
}
</script>

<style scoped>
  .select-list {
    padding-left: 0;
  }
  .select-list li{
    list-style: none;
    margin-left: 0;
  }
  .select-list li .list{
    padding: 5px 20px;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    border-top: 0px;
  }
  .select-list li:first-child .list {
    border-top: 1px solid #f0f0f0;
  }
  .select-list li.selected,
  .select-list li:hover {
    background-color: #f4f8ff;
  }
  .subdata {
    font-size: 80%;
    color: #aaaaaa;
    font-style: italic;
    margin-left: 20px;
  }
</style>

<template>
  <ModalDialog ref="modal" :title="title" size="md">
    <Row>
      <TextField
        :id="id + 'SelectedLabel'"
        :laddon=true
        :block=true
        :disabled=true
        :value="selectedLabel"
      >
        <template #before>DIPILIH :</template>
      </TextField>
      <TextField
        :id="id + 'FilterValue'"
        :laddon=true
        :block=true
        :forceupper=true
        :delay=300
        style="margin-top: -20px"
        placeholder="Filter berdasarkan kata, tag atau keyword"
        v-model="filterValue"
      >
        <template #before><fa-filter /></template>
      </TextField>
    </Row>

    <ul class="select-list">
      <li v-for="(item, index) in listShowed" :key="index">
        <div class="list" @click="select(item.value, item.label)">
          {{ item.label }}
          <small class="subdata pull-right">Pilih</small>
        </div>
      </li>
    </ul>
    <p class="subdata" v-if="remainList">{{ remainList }} more items</p>

    <template #footer>
      <ButtonL v-if="addbutton"
        text="Tambah Baru"
        color="primary"
        @click="$emit(`addaction`)"
      />
      <ButtonFormSet
        labelsave="Select"
        :disabled="!valid"
        @submit="submit"
        @cancel="dismiss"
      />
    </template>
  </ModalDialog>
</template>
