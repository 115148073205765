<script>
export default {
  props: {
    width: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    panelWidth() {
      return `col-md-${this.width}`
    }
  }
}
</script>

<style scoped>
  .table-data tr th,
  .table-data tr td {
    font-size: 90%;
  }
</style>

<template>
  <div :class="panelWidth">
    <table class="table table-data table-hover table-striped table-condensed">
      <thead>
        <tr>
          <slot name="head" />
        </tr>
      </thead>

      <tbody>
        <slot name="body" />
      </tbody>

      <tfoot>
        <slot name="foot" />
      </tfoot>
    </table>
  </div>
</template>