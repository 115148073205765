
import RouteHelper from '../helpers/routes'
const routes = RouteHelper()

/**
 * Base Route Path = `/`
 *
 */

import Home from '@/views/Home/Homepage'
routes.set(``, Home)
routes.set(`dashboard`, Home)

/**
 * `Store Routes`
 *
 */
import SettingGeneral from '@/views/Store/GeneralSetting/Main'
routes.set(`store/setting`, SettingGeneral, {
  meta: {
    hasPerm: `ACCESS_STCONF_GENERAL_R`,
  },
})

import StoreCashAccountList from '@/views/Store/CashAccount/List'
routes.set(`store/cashaccount`, StoreCashAccountList, {
  meta: {
    hasPerm: `ACCESS_STCONF_CACCOUNT_R`,
  },
})

import StorePayMethodList from '@/views/Store/PaymentMethod/List'
routes.set(`store/paymethod`, StorePayMethodList, {
  meta: {
    hasPerm: `ACCESS_STCONF_MPAYMENT_R`,
  },
})

/**
 * `Employee Presence`
 *
 */
import EmployeePresenceList from '@/views/Employee/Presence/List'
routes.set(`employee/presence`, EmployeePresenceList, {
  meta: {
    hasPerm: `ACCESS_EMPPRESENCE_R`,
  },
})

/**
 * `Employee Sallary`
 *
 */
import EmployeeSallary from '@/views/Employee/Sallary/Main'
routes.set(`employee/sallary/:id`, EmployeeSallary, {
  meta: {
    hasPerm: `ACCESS_EMPLIST_R`,
  },
})

import EmployeeSallarySetting from '@/views/Employee/SallarySetting/Main'
routes.set(`employee/sallary-setting/:id`, EmployeeSallarySetting, {
  meta: {
    hasPerm: `ACCESS_EMPLIST_R`,
  },
})

/**
 * `Employee Routes`
 *
 */
import EmployeeList from '@/views/Employee/List/List'
routes.set(`employee`, EmployeeList, {
  meta: {
    hasPerm: `ACCESS_EMPLIST_R`,
  },
})
import EmployeeEntry from '@/views/Employee/List/Entry'
routes.set(`employee/:id`, EmployeeEntry, {
  meta: {
    hasPerm: `ACCESS_EMPLIST_R`,
  },
})
import EmployeePermission from '@/views/Employee/List/Permission'
routes.set(`employee/:id/permission`, EmployeePermission, {
  meta: {
    hasPerm: `ACCESS_EMPACCOUNT_A`,
  },
})

// /**
//  * `Courier Routes`
//  *
//  */
// import CourierRoutes from './courier'
// routes.set(`courier`, DefaultPage, CourierRoutes)


// /**
//  * `Inventory Routes`
//  *
//  */
// import Inventory from './inventory'
// routes.set(`inventory`, DefaultPage, Inventory)

/**
 * `Supplier Routes`
 *
 */
import SupplierList from '@/views/Catalog/Supplier/List'
routes.set(`supplier`, SupplierList, {
  meta: {
    hasPerm: `ACCESS_CATSUPPLIER_R`,
  },
})
import SupplierEntry from '@/views/Catalog/Supplier/Entry'
routes.set(`supplier/:id`, SupplierEntry, {
  meta: {
    hasPerm: `ACCESS_CATSUPPLIER_R`,
  },
})

/**
 * `Product Brand Routes`
 *
 */
import ProductBrandList from '@/views/Catalog/Brand/List'
routes.set(`product/brand`, ProductBrandList, {
  meta: {
    hasPerm: `ACCESS_CATBRAND_R`,
  },
})

/**
 * `Product Category Routes`
 *
 */
import ProductCategoryList from '@/views/Catalog/Category/List'
routes.set(`product/category`, ProductCategoryList, {
  meta: {
    hasPerm: `ACCESS_CATCATEGORY_R`,
  },
})

/**
 * `Product Item Routes`
 *
 */
import ProductItemList from '@/views/Catalog/Item/List'
routes.set(`product/item`, ProductItemList, {
  meta: {
    hasPerm: `ACCESS_CATITEM_R`,
  },
})
import ProductItemEditCover from '@/views/Catalog/Item/EditCover'
routes.set(`product/item/:id/cover`, ProductItemEditCover, {
  meta: {
    hasPerm: `ACCESS_CATITEM_R`,
  },
})
import ProductItemEntry from '@/views/Catalog/Item/Entry'
routes.set(`product/item/:id`, ProductItemEntry, {
  meta: {
    hasPerm: `ACCESS_CATITEM_R`,
  },
})

/**
 * `Product Stock Routes`
 *
 */
import ProductStockList from '@/views/Inventory/ProductStock/List'
routes.set(`product/stock`, ProductStockList, {
  meta: {
    hasPerm: `ACCESS_INVSTOCKREC_R`,
  },
})

/**
 * `Product Stock Transaction Routes`
 *
 */
import ProductTransaction from '@/views/Inventory/ProductTransaction/List'
routes.set(`product/transaction`, ProductTransaction, {
  meta: {
    hasPerm: `ACCESS_INVTRANSC_R`,
  },
})
import ProductTransactionItem from '@/views/Inventory/ProductTransactionItem/List'
routes.set(`product/transactionitem`, ProductTransactionItem, {
  meta: {
    hasPerm: `ACCESS_INVTRANSC_R`,
  },
})
import TransactionReportList from '@/views/Inventory/TransactionReport/List'
routes.set(`product/transaction/report`, TransactionReportList, {
  meta: {
    hasPerm: `ACCESS_INVTRANSREP_R`,
  },
})
import ProductTransactionEntry from '@/views/Inventory/ProductTransaction/Entry'
routes.set(`product/transaction/:id`, ProductTransactionEntry, {
  meta: {
    hasPerm: `ACCESS_INVTRANSC_R`,
  },
})

/**
 * `Product Service Routes`
 *
 */
import ProductServiceList from '@/views/Catalog/Service/List'
routes.set(`product/service`, ProductServiceList, {
  meta: {
    hasPerm: `ACCESS_CATSERVICE_R`,
  },
})
import ProductServiceEntry from '@/views/Catalog/Service/Entry'
routes.set(`product/service/:id`, ProductServiceEntry, {
  meta: {
    hasPerm: `ACCESS_CATSERVICE_R`,
  },
})

/**
 * `Product Package Routes`
 *
 */
import ProductPackageList from '@/views/Catalog/Package/List'
routes.set(`product/package`, ProductPackageList, {
  meta: {
    hasPerm: `ACCESS_CATPACKAGE_R`,
  },
})
import ProductPackageEditCover from '@/views/Catalog/Package/EditCover'
routes.set(`product/package/:id/cover`, ProductPackageEditCover, {
  meta: {
    hasPerm: `ACCESS_CATPACKAGE_R`,
  },
})
import ProductPackageEditItem from '@/views/Catalog/Package/EditItem'
routes.set(`product/package/:id/item`, ProductPackageEditItem, {
  meta: {
    hasPerm: `ACCESS_CATPACKAGE_R`,
  },
})
import ProductPackageEntry from '@/views/Catalog/Package/Entry'
routes.set(`product/package/:id`, ProductPackageEntry, {
  meta: {
    hasPerm: `ACCESS_CATPACKAGE_R`,
  },
})

/**
 * `Stock Opname Routes`
 *
 */
import StockOpnameList from '@/views/Inventory/StockOpname/List'
routes.set(`inventory/opname`, StockOpnameList, {
  meta: {
    hasPerm: `ACCESS_INVSTOCKOP_R`,
  },
})
import StockOpnameEntry from '@/views/Inventory/StockOpname/Entry'
routes.set(`inventory/opname/:id`, StockOpnameEntry, {
  meta: {
    hasPerm: `ACCESS_INVSTOCKOP_R`,
  },
})

/**
 * `Customer Routes`
 *
 */
import CustomerGroupList from '@/views/Cashier/CustomerGroup/List'
routes.set(`customer/group`, CustomerGroupList, {
  meta: {
    hasPerm: `ACCESS_STCONF_CUSTGROUP_R`,
  },
})
import CustomerGroupEntry from '@/views/Cashier/CustomerGroup/Entry'
routes.set(`customer/group/:id`, CustomerGroupEntry, {
  meta: {
    hasPerm: `ACCESS_STCONF_CUSTGROUP_R`,
  },
})

import CustomerList from '@/views/Cashier/Customer/List'
routes.set(`customer`, CustomerList, {
  meta: {
    hasPerm: `ACCESS_CUST_R`,
  },
})
import CustomerEntry from '@/views/Cashier/Customer/Entry'
routes.set(`customer/:id`, CustomerEntry, {
  meta: {
    hasPerm: `ACCESS_CUST_R`,
  },
})

/**
 * `Courier Routes`
 *
 */
import CourierList from '@/views/Cashier/Courier/List'
routes.set(`courier`, CourierList, {
  meta: {
    hasPerm: `ACCESS_COUR_R`,
  },
})

/**
 * `Sales Routes`
 *
 */
import SalesList from '@/views/Cashier/Sales/List'
routes.set(`sales`, SalesList, {
  meta: {
    hasPerm: `ACCESS_SALES_R`,
  },
})
import SalesEntry from '@/views/Cashier/Sales/Entry'
routes.set(`sales/:id`, SalesEntry, {
  meta: {
    hasPerm: `ACCESS_SALES_R`,
  },
})
import SalesPayment from '@/views/Cashier/Sales/Payment'
routes.set(`sales/:id/pay`, SalesPayment, {
  meta: {
    hasPerm: `ACCESS_SALES_R`,
  },
})

/**
 * `Expenditure Routes`
 *
 */
import ExpenditureList from '@/views/Inventory/Expenditure/List'
routes.set(`expenditure`, ExpenditureList, {
  meta: {
    hasPerm: `ACCESS_EXPND_R`,
  },
})
import ExpenditureEntry from '@/views/Inventory/Expenditure/Entry'
routes.set(`expenditure/:id`, ExpenditureEntry, {
  meta: {
    hasPerm: `ACCESS_EXPND_R`,
  },
})
import ExpenditurePayment from '@/views/Inventory/Expenditure/Payment'
routes.set(`expenditure/:id/pay`, ExpenditurePayment, {
  meta: {
    hasPerm: `ACCESS_EXPND_R`,
  },
})

/**
 * `Report Sales Routes`
 *
 */
import RepSalesList from '@/views/Cashier/SalesReport/List'
routes.set(`rep/sales-transaction`, RepSalesList, {
  meta: {
    hasPerm: `ACCESS_REPSALESITEM_R`,
  },
})
import RepSalesListDetail from '@/views/Cashier/SalesReport/Entry'
routes.set(`rep/sales-transaction/:id`, RepSalesListDetail, {
  meta: {
    hasPerm: `ACCESS_REPSALESITEM_R`,
  },
})
import RepSalesItemList from '@/views/Cashier/SalesReportItem/List'
routes.set(`rep/sales-transaction-item`, RepSalesItemList, {
  meta: {
    hasPerm: `ACCESS_REPSALESITEM_R`,
  },
})
import RepSalesPaymentList from '@/views/Cashier/SalesReportPayment/List'
routes.set(`rep/sales-transaction-payment`, RepSalesPaymentList, {
  meta: {
    hasPerm: `ACCESS_REPSALESITEM_R`,
  },
})


/**
 * `Report Expenditure Routes`
 *
 */
import RepExpenditureList from '@/views/Inventory/ExpenditureReport/List'
routes.set(`rep/exp-transaction`, RepExpenditureList, {
  meta: {
    hasPerm: `ACCESS_REPEXPNDITEM_R`,
  },
})
import RepExpenditureListDetail from '@/views/Inventory/ExpenditureReport/Entry'
routes.set(`rep/exp-transaction/:id`, RepExpenditureListDetail, {
  meta: {
    hasPerm: `ACCESS_REPEXPNDITEM_R`,
  },
})
import RepExpenditureItemList from '@/views/Inventory/ExpenditureReportItem/List'
routes.set(`rep/exp-transaction-item`, RepExpenditureItemList, {
  meta: {
    hasPerm: `ACCESS_REPEXPNDITEM_R`,
  },
})
import RepExpenditurePaymentList from '@/views/Inventory/ExpenditureReportPayment/List'
routes.set(`rep/exp-transaction-payment`, RepExpenditurePaymentList, {
  meta: {
    hasPerm: `ACCESS_REPEXPNDITEM_R`,
  },
})


/**
 * `Report Finance Routes`
 *
 */
import RepFinDailyJournalList from '@/views/Finance/DailyJournal/List'
routes.set(`rep/daily-journal`, RepFinDailyJournalList, {
  meta: {
    hasPerm: `ACCESS_REPFINANCE_R`,
  },
})

/**
 * `Operasional Store Purchase Category`
 *
 */
import StorePurchaseCategory from '@/views/Cashier/StorePurchaseCategory/List'
routes.set(`stpurchase/category`, StorePurchaseCategory, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_CATEGORY_R`,
  },
})

/**
 * `Operasional Store Purchase`
 *
 */
import StorePurchase from '@/views/Cashier/StorePurchase/List'
routes.set(`stpurchase`, StorePurchase, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})

/**
 * `Operasional Purchase Transaction`
 *
 */
import OperationalPurchaseTransaction from '@/views/OperationalPurchase/Transaction/List'
routes.set(`oppurchase/transaction`, OperationalPurchaseTransaction, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})

/**
 * `Operasional Purchase Account`
 *
 */
import OperationalPurchaseAccount from '@/views/OperationalPurchase/Account/List'
routes.set(`oppurchase/account`, OperationalPurchaseAccount, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})

/**
 * `Other Income`
 *
 */
import OtherIncome from '@/views/Finance/OtherIncome/List'
routes.set(`otherincome`, OtherIncome, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})

/**
 * `Cash Account Transaction`
 *
 */
import CashAccountTransaction from '@/views/Finance/CashAccountTransaction/List'
routes.set(`cashaccount/transaction`, CashAccountTransaction, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})

import CashAccountBalanceSummarize from '@/views/Finance/CashAccountBalanceSummarize/List'
routes.set(`cashaccount/balance-summarize`, CashAccountBalanceSummarize, {
  meta: {
    hasPerm: `ACCESS_STPURCHASE_R`,
  },
})


/**
 * `Default Route`
 *  Should return 404 - Not Found.
 */
import Page404 from '@/views/Error/404'
routes.set('*', Page404)

export default {
  children: routes.get(),
  meta: {
    requiresAuth: true,
  }
}