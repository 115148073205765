<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ConfirmDialog from '@/components/uicomp/ModalDialog-Confirm'
const components = {
  layout,
  ConfirmDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    changes: {},
    isChanged: 0,
    loadingState: 0,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Employee/data`,
  permissions: `Employee/permissions`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  fetchPermission: `Employee/fetchPermission`,
  updatePermission: `Employee/updatePermission`,
})

methods.goback = function() {
  this.$router.go(-1)
}

methods.cprop = function (prop, value) {
  if (this.input[prop] === undefined) {
    this.input[prop] = false
  }

  if (this.input[prop] === value) {
    delete this.changes[prop]
    this.isChanged -= 1
  } else {
    this.changes[prop] = value
    this.isChanged += 1
  }
}

methods.doUpdate = async function () {
  await this.updatePermission(this.changes)
  this.changes = {}
  this.isChanged = 0
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.input = this.permissions
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Karyawan`, `List` ])

  const id = this.$route.params.id

  if (this.data.id !== id) {
    this.loadingState += 1
    await this.fetchData(id)
      .then(() => this.loadingState -= 1)
  }

  if (!this.permission) {
    await this.fetchPermission()
      .then(() => this.input = this.permissions)
  }
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          USER PERMISSION

          <ButtonR v-if="isChanged" text="Update Permission" color="primary" @click="doUpdate" />
        </template>

        <template #content>
          <Row>
            <Col12>
              <label>GENERAL ACCESS</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    AKSES DASHBOARD
                    <p class="subdata">User dapat melakukan Login ke dashboard</p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Allow"
                      :value="!!input.ACCESS_DASBOARD"
                      @input="cprop(`ACCESS_DASBOARD`, $event)" />
                  </td>
                  <td class="text-left"></td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP PENGATURAN TOKO</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>

                <!-- <tr>
                  <td class="text-left">MENU</td>
                  <td class="text-left">
                    MENU PENGATURAN TOKO
                    <p class="subdata">
                      Akses ke semua Menu Pengaturan Toko<br>
                      <i>General</i>, <i>Akun Keuangan</i>, <i>Motede Pembayaran</i>, <i>Grup Pelanggan</i>.
                    </p>
                  </td>
                  <td class="text-left"><CheckButton value="" label="Allow" /></td>
                  <td class="text-left"></td>
                </tr> -->
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU PENGATURAN TOKO › GENERAL
                    <p class="subdata">
                      User dapat melihat dan mengganti profil toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STCONF_GENERAL_R"
                      @input="cprop(`ACCESS_STCONF_GENERAL_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STCONF_GENERAL_M"
                      @input="cprop(`ACCESS_STCONF_GENERAL_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU PENGATURAN TOKO › AKUN KEUANGAN
                    <p class="subdata">
                      User dapat melihat dan mengganti data master akun keuangan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STCONF_CACCOUNT_R"
                      @input="cprop(`ACCESS_STCONF_CACCOUNT_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STCONF_CACCOUNT_M"
                      @input="cprop(`ACCESS_STCONF_CACCOUNT_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU PENGATURAN TOKO › METODE PEMBAYARAN
                    <p class="subdata">
                      User dapat melihat dan mengganti data master metode pembayaran
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STCONF_MPAYMENT_R"
                      @input="cprop(`ACCESS_STCONF_MPAYMENT_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STCONF_MPAYMENT_M"
                      @input="cprop(`ACCESS_STCONF_MPAYMENT_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU PENGATURAN TOKO › GRUP PELANGGAN
                    <p class="subdata">
                      User dapat melihat dan mengganti data master grup pelanggan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STCONF_CUSTGROUP_R"
                      @input="cprop(`ACCESS_STCONF_CUSTGROUP_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STCONF_CUSTGROUP_M"
                      @input="cprop(`ACCESS_STCONF_CUSTGROUP_M`, $event)" />
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP KARYAWAN</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>

                <!-- <tr>
                  <td class="text-left">MENU</td>
                  <td class="text-left">
                    MENU DATA KARYAWAN
                    <p class="subdata">
                      Akses ke semua Menu Data Karyawan<br>
                      <i>List Karyawan</i>, <i>Rekap Absensi</i>.
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Allow"
                      :value="!!input.ACCESS_DASBOARD"
                      @input="cprop(`ACCESS_DASBOARD`, $event)" />
                  </td>
                  <td class="text-left"></td>
                </tr> -->
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU DATA KARYAWAN › LIST KARYAWAN
                    <p class="subdata">
                      User dapat melihat dan mengedit data karyawan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_EMPLIST_R"
                      @input="cprop(`ACCESS_EMPLIST_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_EMPLIST_M"
                      @input="cprop(`ACCESS_EMPLIST_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    SETTING AKUN DASHBOARD KARYAWAN
                    <p class="subdata">Menambahkan / menghapus akses akun karyawan ke dashboard, set permission, reset password</p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Allow"
                      :value="!!input.ACCESS_EMPACCOUNT_A"
                      @input="cprop(`ACCESS_EMPACCOUNT_A`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU DATA KARYAWAN › REKAP ABSENSI
                    <p class="subdata">
                      User dapat melihat dan mengelola data absensi karyawan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_EMPPRESENCE_R"
                      @input="cprop(`ACCESS_EMPPRESENCE_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_EMPPRESENCE_M"
                      @input="cprop(`ACCESS_EMPPRESENCE_M`, $event)" />
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP PRODUCT</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>

                <!-- <tr>
                  <td class="text-left">MENU</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK
                    <p class="subdata">
                      Akses ke semua Menu Katalog Produk<br>
                      <i>Data Brand</i>, <i>Data Kategori</i>, <i>Data Barang</i>, <i>Data Layanan</i>, <i>Data Paket</i>.
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Allow"
                      :value="!!input.ACCESS_DASBOARD"
                      @input="cprop(`ACCESS_DASBOARD`, $event)" />
                  </td>
                  <td class="text-left"></td>
                </tr> -->
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK › DATA BRAND
                    <p class="subdata">
                      User dapat melihat dan mengedit data brand produk
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATBRAND_R"
                      @input="cprop(`ACCESS_CATBRAND_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATBRAND_M"
                      @input="cprop(`ACCESS_CATBRAND_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK › DATA KATEGORI
                    <p class="subdata">
                      User dapat melihat dan mengedit data kategori produk
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATCATEGORY_R"
                      @input="cprop(`ACCESS_CATCATEGORY_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATCATEGORY_M"
                      @input="cprop(`ACCESS_CATCATEGORY_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK › DATA BARANG
                    <p class="subdata">
                      User dapat melihat dan mengedit data pada katalog barang
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATITEM_R"
                      @input="cprop(`ACCESS_CATITEM_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATITEM_M"
                      @input="cprop(`ACCESS_CATITEM_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA BARANG > HARGA MODAL
                    <p class="subdata">
                      User dapat melihat semua harga beli
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATITEM_ASSETPRICE"
                      @input="cprop(`ACCESS_CATITEM_ASSETPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA BARANG > HARGA JUAL KE PELANGGAN
                    <p class="subdata">
                      User dapat melihat harga jual ke pelanggan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATITEM_SALEPRICE"
                      @input="cprop(`ACCESS_CATITEM_SALEPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA BARANG > HARGA JUAL KE TOKO
                    <p class="subdata">
                      User dapat melihat harga jual ke toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATITEM_STOREPRICE"
                      @input="cprop(`ACCESS_CATITEM_STOREPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK › DATA LAYANAN
                    <p class="subdata">
                      User dapat melihat dan mengedit data pada katalog layanan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATSERVICE_R"
                      @input="cprop(`ACCESS_CATSERVICE_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATSERVICE_M"
                      @input="cprop(`ACCESS_CATSERVICE_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KATALOG PRODUK › DATA PAKET
                    <p class="subdata">
                      User dapat melihat dan mengedit data paket produk
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATPACKAGE_R"
                      @input="cprop(`ACCESS_CATPACKAGE_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATPACKAGE_M"
                      @input="cprop(`ACCESS_CATPACKAGE_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA PAKET > HARGA MODAL
                    <p class="subdata">
                      User dapat melihat semua harga beli
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATPACKAGE_ASSETPRICE"
                      @input="cprop(`ACCESS_CATPACKAGE_ASSETPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA PAKET > HARGA JUAL KE PELANGGAN
                    <p class="subdata">
                      User dapat melihat harga jual ke pelanggan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATPACKAGE_SALEPRICE"
                      @input="cprop(`ACCESS_CATPACKAGE_SALEPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    DATA PAKET > HARGA JUAL KE TOKO
                    <p class="subdata">
                      User dapat melihat harga jual ke toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATPACKAGE_STOREPRICE"
                      @input="cprop(`ACCESS_CATPACKAGE_STOREPRICE`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU DATA SUPPLIER
                    <p class="subdata">
                      User dapat melihat dan mengedit data supplier toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CATSUPPLIER_R"
                      @input="cprop(`ACCESS_CATSUPPLIER_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CATSUPPLIER_M"
                      @input="cprop(`ACCESS_CATSUPPLIER_M`, $event)" />
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP INVENTORI</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>
                <!--
                <tr>
                  <td class="text-left">MENU</td>
                  <td class="text-left">
                    INVENTORI
                    <p class="subdata">
                      Akses ke semua Menu Inventori<br>
                      <i>Data Keluar Masuk</i>, <i>Lap. Pergerakan</i>, <i>Stok Barang</i>, <i>Stok Opname</i>.
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Allow"
                      :value="!!input.ACCESS_DASBOARD"
                      @input="cprop(`ACCESS_DASBOARD`, $event)" />
                  </td>
                  <td class="text-left"></td>
                </tr> -->
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU INVENTORI › DATA KELUAR MASUK
                    <p class="subdata">
                      User dapat melihat dan mengelola transaksi stok barang
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_INVTRANSC_R"
                      @input="cprop(`ACCESS_INVTRANSC_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_INVTRANSC_M"
                      @input="cprop(`ACCESS_INVTRANSC_M`, $event)" />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU INVENTORI › LAPORAN PERGERAKAN STOK
                    <p class="subdata">
                      User dapat melihat pergerakan stok barang
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_INVTRANSREP_R"
                      @input="cprop(`ACCESS_INVTRANSREP_R`, $event)" />
                  </td>
                  <td class="text-left"></td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU INVENTORI › STOK BARANG
                    <p class="subdata">
                      User dapat melihat sisa stok barang
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_INVSTOCKREC_R"
                      @input="cprop(`ACCESS_INVSTOCKREC_R`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU INVENTORI › STOK OPNAME
                    <p class="subdata">
                      User dapat melihat dan melakukan pengecekan stok opname
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_INVSTOCKOP_R"
                      @input="cprop(`ACCESS_INVSTOCKOP_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_INVSTOCKOP_M"
                      @input="cprop(`ACCESS_INVSTOCKOP_M`, $event)" />
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP KASIR</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>

                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › BELANJA TOKO
                    <p class="subdata">
                      User dapat melihat dan menginput belanja operasional toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STPURCHASE_R"
                      @input="cprop(`ACCESS_STPURCHASE_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STPURCHASE_M"
                      @input="cprop(`ACCESS_STPURCHASE_M`, $event)" />
                  </td>
                </tr>
<!--
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › KATEGORI BELANJA TOKO
                    <p class="subdata">
                      User dapat melihat dan menginput belanja operasional toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_STPURCHASE_CATEGORY_R"
                      @input="cprop(`ACCESS_STPURCHASE_CATEGORY_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_STPURCHASE_CATEGORY_M"
                      @input="cprop(`ACCESS_STPURCHASE_CATEGORY_M`, $event)" />
                  </td>
                </tr> -->

                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › DATA PENJUALAN
                    <p class="subdata">
                      User dapat melihat dan mengelola data penjualan toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_SALES_R"
                      @input="cprop(`ACCESS_SALES_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_SALES_M"
                      @input="cprop(`ACCESS_SALES_M`, $event)" />
                  </td>
                </tr>

                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › DATA PEMBELANJAAN
                    <p class="subdata">
                      User dapat melihat dan mengelola data pembelanjaan toko
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_EXPND_R"
                      @input="cprop(`ACCESS_EXPND_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_EXPND_M"
                      @input="cprop(`ACCESS_EXPND_M`, $event)" />
                  </td>
                </tr>

                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › DATA PELANGGAN
                    <p class="subdata">
                      User dapat melihat dan mengelola data pelanggan
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_CUST_R"
                      @input="cprop(`ACCESS_CUST_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_CUST_M"
                      @input="cprop(`ACCESS_CUST_M`, $event)" />
                  </td>
                </tr>

                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU KASIR › DATA KURIR
                    <p class="subdata">
                      User dapat melihat dan mengelola data kurir
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_COUR_R"
                      @input="cprop(`ACCESS_COUR_R`, $event)" />
                  </td>
                  <td class="text-left">
                    <CheckButton label="Modify"
                      :value="!!input.ACCESS_COUR_M"
                      @input="cprop(`ACCESS_COUR_M`, $event)" />
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

          <Row>
            <Col12>
              <label>ACCESS GROUP LAPORAN</label>
            </Col12>
            <TableList>
              <template #head>
                <th width="120" class="text-left">Tipe</th>
                <th class="text-left">Rules</th>
                <th width="120" class="text-left">Access</th>
                <th width="120" class="text-left"></th>
              </template>

              <template #body>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU LAPORAN › LAPORAN PENJUALAN
                    <p class="subdata">
                      User dapat melihat laporan data penjualan dan rekap barang terjual
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_REPSALESITEM_R"
                      @input="cprop(`ACCESS_REPSALESITEM_R`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU LAPORAN › LAPORAN PEMBELANJAAN
                    <p class="subdata">
                      User dapat melihat laporan data pembelanjaan dan rekap barang terbeli
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_REPEXPNDITEM_R"
                      @input="cprop(`ACCESS_REPEXPNDITEM_R`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
                <tr>
                  <td class="text-left">ACCESS</td>
                  <td class="text-left">
                    MENU LAPORAN › LAPORAN KEUANGAN
                    <p class="subdata">
                      User dapat melihat laporan keuangan, neraca, dan transaksi akun
                    </p>
                  </td>
                  <td class="text-left">
                    <CheckButton label="Lihat"
                      :value="!!input.ACCESS_REPFINANCE_R"
                      @input="cprop(`ACCESS_REPFINANCE_R`, $event)" />
                  </td>
                  <td class="text-left">
                  </td>
                </tr>
              </template>
            </TableList>
          </Row>

        </template>
      </BlockTable>
    </Row>

    <ConfirmDialog ref="ConfirmDialog" @submit="confirmAction($event)" />
  </layout>
</template>