
import Account from './account'
import Summarize from './summarize'
import Transaction from './transaction'

export default {
  namespaced: true,
  modules: {
    Account,
    Summarize,
    Transaction,
  }
}