
<script>
import { mapActions } from 'vuex'

/**
 * Components
 *
 */
import RowData from './ReportTableRowData'
const components = {
  RowData
}

/**
 * Static Data
 *
 */
const props = {
  data: {
    type: Object,
    default: () => ({})
  },
  totalincome: {
    type: Number,
    default: 0,
  },
  totaldeduction: {
    type: Number,
    default: 0,
  },
  totalpayable: {
    type: Number,
    default: 0,
  },
  editable: {
    type: Boolean,
    default: false,
  },
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    //
  }
}

/**
 * Computed
 *
 */
const computed = {
  dataEntries: function () {
    return this.data
      ? Object.entries(this.data)
      : []
  },

  // siOtherList: function () {
  //   return this.dataEntries.reduce((items, [ id, data ]) => {
  //     if (id.startsWith('SI_OTHER')) items.push(data)

  //     return items
  //   }, [])
  // },

  siOtherPaidableList: function () {
    return this.dataEntries.reduce((items, [ id, data ]) => {
      if (id.startsWith('SI_OTHER') && !data.account_id) items.push(data)

      return items
    }, [])
  },

  siOtherMutateableList: function () {
    return this.dataEntries.reduce((items, [ id, data ]) => {
      if (id.startsWith('SI_OTHER') && data.account_id) items.push(data)

      return items
    }, [])
  },

  soOtherList: function () {
    return this.dataEntries.reduce((items, [ id, data ]) => {
      if (id.startsWith('SO_OTHER')) items.push(data)

      return items
    }, [])
  },
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Employee/Sallary/fetch`,
  setSallary: `Employee/Sallary/setSallary`,
})

methods.set = async function (type, amount) {
  const id = this.$route.params.id

  await this.setSallary({ id, data: { type, amount } })
  await this.fetch(id)
}

/**
 * Watchers
 *
 */
const watch = {}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  //
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  //
}

export default {
  components,
  props,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <Col6>
      <TableList>
        <template #head>
          <td><label>Penerimaan</label></td>
          <td width="10"></td>
          <td width="120"></td>
        </template>

        <template #body>
          <RowData key="monthly-sallary" :data="data.SI_MONTHLY_SALLARY" />
          <RowData key="postional-allowance" :data="data.SI_POSITIONAL_ALLOWANCE" />
          <RowData key="service-cost" :data="data.SI_SERVICE_COST" />
          <RowData key="delivery" :data="data.SI_DELIVERY" />
          <RowData key="daily-sallary" :data="data.SI_DAILY_SALLARY" />
          <RowData key="overtime" :data="data.SI_OVERTIME" />
          <RowData key="reseller" :data="data.SI_RESELL" />
          <RowData v-for="(data) in siOtherPaidableList" :key="data.id" :data="data" />

        </template>
      </TableList>

      <TableList>
        <template #head>
          <td><label>Penerimaan lain (tidak dibayarkan)</label></td>
          <td width="10"></td>
          <td width="120"></td>
        </template>

        <template #body>
          <RowData v-for="(data) in siOtherMutateableList" :key="data.id" :data="data" />
        </template>

        <template #foot>
          <tr><td><br></td></tr>

          <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Total Penerimaan</label></td>
            <td class="text-left">:</td>
            <td class="text-right"><label>{{ totalincome.readable(0) }}</label></td>
          </tr>
        </template>
      </TableList>
    </Col6>

    <Col6>
      <TableList>
        <template #head>
          <td class=""><label>Potongan</label></td>
          <td width="10"></td>
          <td width="120"></td>
        </template>

        <template #body>
          <RowData key="absent" :data="data.SO_ABSENT" />
          <RowData v-for="(data, index) in soOtherList" :key="index" :data="data" :editable="editable" @submit="set(data.id, $event)" />

          <!--
          <RowData key="angsuran" :data="data.SO_ANGSURAN" :editable="editable" @submit="set(data.SO_ANGSURAN.id, $event)" />
          <RowData key="piutang" :data="data.SO_PIUTANG" :editable="editable" @submit="set(data.SO_PIUTANG.id, $event)" />
          <RowData key="kasbon" :data="data.SO_KASBON" :editable="editable" @submit="set(data.SO_KASBON.id, $event)" />
          -->
        </template>

        <template #foot>
          <tr><td><br></td></tr>

          <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Total Potongan</label></td>
            <td class="text-left">:</td>
            <td class="text-right"><label>{{ totaldeduction.readable(0) }}</label></td>
          </tr>
        </template>
      </TableList>
    </Col6>

    <Col6>
      <TableList>
        <template #head>
          <td class=""><label>Pajak</label></td>
          <td width="10"></td>
          <td width="120"></td>
        </template>

        <template #body>
          <RowData key="pph21" :data="data.SP_PPH21" />
        </template>
      </TableList>
    </Col6>

    <Col12>
      <TableList>
        <template #head>
          <td class=""></td>
          <td width="10"></td>
          <td width="120"></td>
        </template>

        <template #foot>
          <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Jumlah Dibayarkan</label></td>
            <td class="text-left">:</td>
            <td class="text-right"><label>{{ (totalpayable).readable(0) }}</label></td>
          </tr>
        </template>
      </TableList>
    </Col12>
  </Row>
</template>