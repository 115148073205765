
export default () => ({
  routes: [],
  set(path, component, args) {
    if (args && args.components) {
      args.components.default = component
      this.routes.push({ path, ...args })
    } else {
      this.routes.push({ path, component, ...args })
    }
  },
  get() {
    return this.routes
  },
})