
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import DescriptionDialog from './ListTable-Detail'
const components = {
  DeleteDialog,
  DescriptionDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    description: {},
    filter_month: moment().format(`MM`),
    filter_year: moment().format(`YYYY`),
    ENUM_BACKGROUND: {
      STOR_ACC: `custom-bg-blue-light`,
      OPRA_ACC: `custom-bg-orange-light`,
      DEPT_ACC: `custom-bg-red-light`,
    },
    ENUM_BACKGROUND_HEADER: {
      STOR_ACC: `custom-bg-blue-darker`,
      OPRA_ACC: `custom-bg-orange-darker`,
      DEPT_ACC: `custom-bg-red-darker`,
    }
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `OpPurchase/Summarize/list`,
  meta: `OpPurchase/Summarize/listMeta`,
  summary: `OpPurchase/Summarize/summary`,
  dateMonth: `dateMonth`,
  dateYear: `dateYear`,
})

computed.datatable = function () {
  const records = []

  if (this.list.records) {
    this.list.records.forEach(group => {
      for (let row = 0; row < group.span; row += 1) {
        let rowdata = [ moment(group.date, `YYYYMMDD`).format(`DD/MM/YYYY`) ]

        group.rec.forEach((recdata, col_id) => {
          const data = recdata[row] || { amount: 0 }
          data.background = this.ENUM_BACKGROUND[this.list.headers[col_id].category]

          rowdata.push(data)
        })

        records.push(rowdata)
      }
    })
  }

  return records
}

computed.datatableTotal = function () {
  const total = []

  this.datatable.forEach((row) => {
    row.forEach((rowdata, index) => {
      if (index === 0) return false
      if (!total[index - 1]) total[index - 1] = 0

      total[index - 1] += rowdata.amount
    })
  })

  return total
}

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `OpPurchase/Summarize/setListOption`,
  reload: `OpPurchase/Summarize/fetchData`,
  deleteItem: `OpPurchase/Account/remove`,
})

methods.edit = function (id) {
  this.$emit(`editrequest`, id)
}

methods.showDescription = function (id) {
  this.$refs.DescriptionDialog.show(id)
}

methods.dismissDescription = function () {
  this.$refs.DescriptionDialog.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_month: function (n, o) {
    n !== o && this.setListOption({ month: n })
  },
  filter_year: function (n, o) {
    n !== o && this.setListOption({ year: n })
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })

  if (this.meta.filter.month) {
    this.filter_month = this.meta.filter.month
  }

  if (this.meta.filter.year) {
    this.filter_year = this.meta.filter.year
  }
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
          <td width="140"></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td colspan="2">
            <ul class="list-inline-style">
              <li>
                <SelectFieldChangable
                  :defaultValue="false"
                  :value="dateMonth.labelFromCode[filter_month]"
                  :formValue="filter_month"
                  :options="dateMonth.options"
                  @submit="filter_month = $event"
                />
              </li>
              <li>
                <SelectFieldChangable
                  :defaultValue="false"
                  :value="filter_year"
                  :formValue="filter_year"
                  :options="dateYear.options"
                  @submit="filter_year = $event"
                />
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </Col6>

    <Col6>
      <table class="no-style pull-right">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
          <td width="140"></td>
        </tr>
        <tr>
          <td><label>TOTAL PENDAPATAN BRUTO</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.total_sales_income.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL PEMASUKAN LAIN</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.total_other_income.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL BELANJA TOKO (-)</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.total_store_purchase.readable() }}</td>
        </tr>
        <tr class="border-top">
          <td><label>TOTAL LABA KOTOR</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.omzet.readable() }}</td>
        </tr>
      </table>
    </Col6>

    <Col12>
      <br style="clear: both">
    </Col12>
    <TableList style="overflow-x: scroll">
      <template #head>
        <th width="100" class="text-left">Riwayat Transaksi</th>
        <th v-for="item in list.headers" :key="`header-${item.id}`" width="200" class="text-right">
          {{ item.name }}
        </th>
      </template>

      <template #body>
        <tr>
          <td><label>Saldo Awal</label></td>
          <td v-for="item in list.headers" :key="`prev-credit-total-${item.id}`"
            :class="['text-right', ENUM_BACKGROUND_HEADER[item.category]]"
          >
            <span v-html="item.last_balance.readable()" />
          </td>
        </tr>
        <tr v-for="(record, dtIndex) in datatable" :key="`rec-date-${dtIndex}`">
          <td v-for="(data, dataIndex) in record" :key="`rec-data-${dataIndex}`"
            :class="['text-left', data.background]"
          >
            <div v-if="dataIndex === 0" class="pull-right">
              {{ data }}
            </div>

            <div v-else class="pull-right link underlined" @click="showDescription(data.id)">
              <span v-if="!data.amount">-</span>
              <span v-else-if="data.amount > 0" class="text-success" v-html="data.amount.readable()" />
              <span v-else class="text-danger" v-html="data.amount.readable()" />
            </div>
          </td>
        </tr>
        <tr class="custom-bg-primary-light">
          <td><label>Saldo Akhir</label></td>
          <td v-for="item in list.headers" :key="`credit-${item.id}`"
            :class="['text-right', ENUM_BACKGROUND_HEADER[item.category]]"
          >
            <strong v-html="(item.last_balance + item.onperiod_credit).readable()" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
    <DescriptionDialog ref="DescriptionDialog" />

  </Row>
</template>