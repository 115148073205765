
import axios from 'axios'

const defaultParams = {
  baseurl: process.env.VUE_APP_API_BASEURL,
}

class Api {
  constructor(data) {
    this.baseurl = defaultParams.baseurl

    if (data) {
      this.baseurl = data.baseurl
    }
  }

  set(prop, value) {
    this[prop] = value
  }

  async exec(method, url, data) {
    const params = {
      method,
      url: `${this.baseurl}${url}`,
    }

    if (data) params.data = data

    return axios(params)
      .then(result => result.data)
      .catch(({ data: err }) => Promise.reject(err))
  }

  get(url) {
    return this.exec(`GET`, url)
  }

  post(url, data) {
    return this.exec(`POST`, url, data)
  }

  put(url, data) {
    return this.exec(`PUT`, url, data)
  }

  delete(url, data) {
    return this.exec(`DELETE`, url, data)
  }
}

export default Api