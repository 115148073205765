
import BaseModel from '../../../helpers/basemodel'
import Api from '../../../helpers/api'
import moment from 'moment'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()
const defaultDate = moment()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}

state.listOptions.month = defaultDate.format(`MM`)
state.listOptions.year = defaultDate.format(`YYYY`)


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([])

    if (opt.month && opt.year) {
      query.push(`period=${opt.year}${opt.month}`)
    }

    return query.string
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get date_readable() { return moment(this.date).format(`DD/MM/YYYY`) },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/finreport/journal?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}