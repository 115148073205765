<script>
export default {
  props: {
    searchValue: {
      type: String,
      default: ``,
    },
    width: {
      type: Number,
      default: 12,
    },
    nrecord: {
      type: Number,
      default: 0,
    },
    curpage: {
      type: Number,
      default: 0,
    },
    npage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchingState: 0,
    }
  },
  computed: {
    panelWidth() {
      return `col-md-${this.width}`
    },
    searching() {
      return this.searchingState
    },
  },
}
</script>

<style scoped>
  table {
    margin-bottom: 0px;
  }
  .pad5l {
    padding-left: 5px;
  }
  .pad5r {
    padding-right: 5px;
  }
  div.small {
    font-size: 80%;
    font-style: italic;
    margin-top: -10px;
    margin-bottom: 10px;
    color: #999999;
  }
</style>

<template>
  <div :class="panelWidth">
    <table class="table table-condensed table-borderless">
      <tr>
        <td class="pad5r">
          <Row>
            <TextField
              :laddon=true
              :block=true
              :delay=300
              :forceupper=true
              :value="searchValue"
              placeholder="Filter berdasarkan kata, tag atau keyword"
              @enter="$emit(`search`, $event)"
            >
              <template #before><fa-search /></template>
            </TextField>
          </Row>
        </td>
        <td width="1px" class="text-right pad5l pad5r">
          <Button icon="chevron-left" @click="$emit(`prevpage`)" color="primary" />
        </td>
        <td width="1px" class="text-right pad5l pad5r">
          <Button icon="chevron-right" @click="$emit(`nextpage`)" color="primary" />
        </td>
        <td width="1px" class="text-right pad5l">
          <Button icon="tasks" @click="$emit(`showopt`)" color="default" />
        </td>
      </tr>
    </table>
    <div class="small">
      Jumlah total record: {{ nrecord }}, Halaman {{ curpage }} / {{ npage }}
    </div>
  </div>
</template>