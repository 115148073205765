<template>
  <div :class="[ 'select-field', (width) ? `col-sm-${width}` : `col-sm-12` ]">
    <label for="component">{{ label }}</label>
    <div :class="[isInputGroup]">
      <span class="input-group-addon" v-if="laddon">
        <slot name="before"></slot>
      </span>
      <select class="form-control"
        id="component"
        ref="input"
        :disabled="disabled"
        :value="value"
        @input="handle"
      >
        <option :value="defaultValue" v-if="defaultLabel !== undefined">{{ defaultLabel }}</option>
        <option
          v-for="(option, index) in selectOption"
          :key="index"
          :value="option.value"
          :selected="option.selected"
        >
          {{ option.label }}
        </option>
      </select>
      <span class="input-group-addon" v-if="raddon">
        <slot name="after"></slot>
      </span>
    </div>
    <p class="sublabel">{{ sublabel }}</p>
  </div>
</template>

<script>
// @ is an alias to /src
import reducer from 'lodash/reduce'

export default {
  props: {
    label: String,
    sublabel: String,
    value: {},
    width: String,
    laddon: { type: Boolean, default: false },
    raddon: { type: Boolean, default: false },
    disabled: Boolean,
    defaultValue: { type: String, default: `` },
    defaultLabel: String,
    optionValue: { type: String, default: `value` },
    optionLabel: { type: String, default: `label` },
    options: { type: Array, default: () => ([]) },
    reducer: {}
  },
  computed: {
    selectOption() {
      const reduce = this.reducer || this.defaultReducer

      return reducer(this.options, (ac, i) => {
        const opt = reduce(i)

        if (opt) ac.push(opt)

        return ac
      }, [])
    }
  },
  methods: {
    handle(event) {
      this.$emit(`input`, event.target.value || null)
    },
    defaultReducer(opt) {
      return ({
        value: opt[this.optionValue],
        label: opt[this.optionLabel],
        get selected() { return this.value === parent.value }
      })
    },
    isInputGroup() {
      return (this.after || this.before) ? `input-group` : ``
    },
  },
  watch: {
    options(d) {
      if (!this.defaultValue) {
        this.$emit(`input`, this.selectOption && this.selectOption[0].value)
      }
    }
  }
}
</script>

<style scoped>
  .sublabel {
    font-size: 80%;
    font-style: italic;
    margin-top: 5px;
    color: #999999;
  }
</style>