
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/loadingState`,
    data: `Employee/profile`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  updateData: `Employee/update`,
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.isProfileLocked = true
  this.profile = { ...this.data }
}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.data.id,
    data: this.profile,
  })

  this.reset()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="SETTING KOMISI"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)" >
    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <label>Resell Penjualan Barang</label>
          <p>{{ profile.comm_item || 0 }} %</p>
        </Col12>

        <Col12>
          <label>Resell Penjualan Servis</label>
          <p>{{ profile.comm_serv || 0 }} %</p>
        </Col12>

        <Col12>
          <label>Resell Penjualan Paket Produk</label>
          <p>{{ profile.comm_pack || 0 }} %</p>
        </Col12>
      </Row>
      <FormGroup v-else>
        <TextField
          id="commitem"
          label="Resell Penjualan Barang"
          width="8"
          :forceupper=true
          :raddon=true
          v-model="profile.comm_item">
          <template #after>%</template>
        </TextField>

        <TextField
          id="commserv"
          label="Resell Penjualan Servis"
          width="8"
          :forceupper=true
          :raddon=true
          v-model="profile.comm_serv">
          <template #after>%</template>
        </TextField>

        <TextField
          id="commpack"
          label="Resell Penjualan Paket Produk"
          width="8"
          :forceupper=true
          :raddon=true
          v-model="profile.comm_pack">
          <template #after>%</template>
        </TextField>
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="updateProfile"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>