
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    confirmation: false,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    cashAccountOptions: `CashAccount/options`,
  }),
  validate() {
    return !!this.input.cashaccount_id && !!this.input.approval_code
  }
}

/**
 * Methods
 *
 */
const methods = {}

methods.reset = function() {
  this.input = {}
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.addrecord = async function() {
  this.$emit(`submit`, this.input)
}

export default {
  components,
  data,
  computed,
  methods,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Rekening *"
        defaultLabel="-- Pilih Rekening --"
        :options="cashAccountOptions"
        v-model="input.cashaccount_id" />

      <TextField
        label="Ref. Pembayaran *"
        v-model="input.approval_code" />
    </FormGroup>
  </EntryDialog>
</template>