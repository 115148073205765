
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/TransactionReport/list`,
  meta: `Product/TransactionReport/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/TransactionReport/setListOption`,
  reload: `Product/TransactionReport/fetchList`,
  pagePrev: `Product/TransactionReport/pagePrev`,
  pageNext: `Product/TransactionReport/pageNext`,
  deleteItem: `Product/TransactionReport/remove`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="20" class="text-center">#</th>
        <th width="160" class="text-left">Kategori</th>
        <th class="text-left">Nama Barang</th>
        <th width="160" class="text-left">Supplier</th>
        <th width="40" class="text-center">Stok Awal</th>
        <th width="40" class="text-center">Stok Masuk</th>
        <th width="40" class="text-center">Stok Keluar</th>
        <th width="40" class="text-center">Stok Akhir</th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center">
            {{ index + meta.curstart }}
          </td>

          <td class="text-left">
            {{ item.category_name }}
          </td>

          <td class="text-left">
            <p>{{ item.name }}</p>
            <!-- <small class="subdata">{{ item.brand_name }}</small> -->
          </td>

          <td class="text-left">
            {{ item.supplier_name }}
          </td>

          <td class="text-center">
            {{ item.last_stock ? item.last_stock : '-' }}
          </td>

          <td class="text-center">
            {{ item.current_in ? item.current_in : '-' }}
          </td>

          <td class="text-center">
            {{ item.current_out ? item.current_out : '-' }}
          </td>

          <td class="text-center">
            {{ item.current_stock ? item.current_stock : '-' }}
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>