
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Sales/data`,
  paymentMethodOptions: `PayMethod/options`,
})

computed.total_paid = function() {
  return this.data.total_paid
    ? this.data.total_paid.readable()
    : 0
}

computed.validate = function() {
  return !!this.input.method_id
}

/**
 * Methods
 *
 */
const methods = {}

methods.reset = function() {
  this.input = {
    amount: this.data.total_paid,
    method_id: null,
    approval_code: null,
  }
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.submit = async function() {
  const data = this.input

  this.dismiss()
  this.$emit(`submit`, data)
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog"
    title="MEMBATALKAN TRANSAKSI"
    labelsave="Submit"
    :valid="validate"
    @submit="submit">

    <FormGroup>
      <Col12>
        <p>
          Jumlah dana yang dikembalikan
        </p>

        <p class="font-w700">
          Rp. {{ total_paid }}
        </p>

        <hr />
      </Col12>

      <SelectField
        label="Pilih metode pengembalian dana"
        width="12"
        defaultLabel="-- Pilih Metode Bayar --"
        defaultValue=""
        :options=paymentMethodOptions
        v-model="input.method_id" />

      <TextField
        label="Referensi Pembayaran"
        :forceupper=true
        v-model="input.approval_code" />
    </FormGroup>
  </EntryDialog>
</template>