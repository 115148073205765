
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/Transaction/data`,
  itemlist: `Product/Transaction/itemlist`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchListItem: `Product/Transaction/fetchListItem`,
  updateListItem: `Product/Transaction/updateListItem`,
  removeListItem: `Product/Transaction/removeListItem`,
})

methods.reload = async function () {
  this.fetchListItem()
}

methods.updateQuantity = async function (product_id, quantity) {
  return this.updateListItem({
    product_id,
    quantity,
  })
}

methods.confirmDelete = async function (id, name) {
  this.$refs.DeleteDialog.confirm(id, name)
}

methods.deleteItem = async function(product_id) {
  await this.removeListItem({ product_id })
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      n.id !== o.id && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style>
  .table>tfoot>tr>td {
    border-top: none;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th class="text-center" width="48">#</th>
        <th class="text-left">Nama Produk</th>
        <th width="60" class="text-right"></th>
        <th width="60" class="text-right">Jumlah</th>
        <th width="40" v-if="!data.locked"></th>
      </template>

      <template #body>
        <tr v-for="(item, index) in itemlist" :key="item.id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-center">
            <label class="label label-success push-10-l" v-if="item.type === `I`">IN</label>
            <label class="label label-danger push-10-l" v-else>OUT</label>
          </td>
          <td class="text-right">
            <span v-if="data.locked">{{ item.quantity }}</span>
            <TextFieldChangable v-else
              :value="item.quantity"
              @submit="updateQuantity(item.product_id, $event)"
            />
          </td>
          <td class="text-right" v-if="!data.locked">
            <TButtonDelete @click="confirmDelete(item.product_id, item.product_name)" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>