<script>
export default {
  props: {
    labelsave: {
      type: String,
      default: `Save`,
    },
    labelcancel: {
      type: String,
      default: `Cancel`,
    },
    buttoncancel: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
  },
}
</script>

<template>
  <div>
    <Button class="pull-right push-10-l"
      :text="labelsave"
      :disabled="disabled"
      color="primary"
      @click="$emit(`submit`)"
    />

    <Button class="pull-right" v-if="buttoncancel"
      :text="labelcancel"
      color="none"
      @click="$emit(`cancel`)"
    />
  </div>
</template>