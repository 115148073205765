<template>
  <div :class="[ 'text-field', (width) ? `col-sm-${width}` : `col-sm-12` ]">
    <label class="css-input css-checkbox css-checkbox-rounded css-checkbox-md css-checkbox-primary">
      <input type="checkbox"
        :disabled="disabled"
        :checked="value"
        @input="handle" />
      <span></span>
      {{ label }}
    </label>
    <p class="sublabel" v-html="sublabel"></p>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: {},

    label: String,
    sublabel: String,

    width: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isChecked() {
      return false
    },
  },
  methods: {
    handle(event) {
      this.data = event.target.checked
      return this.$emit(`input`, this.data)
    },
  }
}
</script>

<style scoped>
  .sublabel {
    font-size: 80%;
    font-style: italic;
    margin-top: 5px;
    color: #999999;
  }

  .borderless input {
    background: none;
    border: none;
  }
</style>