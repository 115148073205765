
import DailyJournal from './daily-journal'
import OtherIncome from './other-income'
import CashAccountSummarize from './cash-account-summarize'
import CashAccountBalanceSummarize from './cash-account-balance-summarize'

export default {
  namespaced: true,
  modules: {
    OtherIncome,
    DailyJournal,
    CashAccountSummarize,
    CashAccountBalanceSummarize,
  }
}