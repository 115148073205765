<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
import SelectListDialog from '@/components/uicomp/ModalDialog-Form-List'
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import ConfirmDialog from '@/components/uicomp/ModalDialog-Confirm'
import EntryCargoDialog from './EntryCargoDialog'
import CancelTransactionDialog from './CancelTransactionDialog'
const components = {
  layout,
  ItemList,
  SelectListDialog,
  DeleteDialog,
  ConfirmDialog,
  EntryCargoDialog,
  CancelTransactionDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Expenditure/loadingState`,
    data: `Product/Expenditure/data`,
    supplierOptions: `Product/Supplier/options`,
    itemOptions: `Product/Item/options`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Expenditure/fetchData`,
  fetchListItem: `Product/Expenditure/fetchListItem`,
  fetchListPayment: `Product/Expenditure/fetchListPayment`,
  updateData: `Product/Expenditure/update`,
  removeData: `Product/Expenditure/remove`,
  updateStatusOrdered: `Product/Expenditure/tagtoOrdered`,
  updateStatusReceived: `Product/Expenditure/tagtoReceived`,
  updateStatusCanceled: `Product/Expenditure/tagtoCanceled`,
  lockData: `Product/Expenditure/lock`,
  createCargoAction: `Product/Expenditure/createCargoRecord`,
  updateListItem: `Product/Expenditure/updateListItem`,
  modelPay: `Product/Expenditure/pay`,
})

methods.goback = function() {
  this.$router.push(`/expenditure`)
}

methods.dopayment = function() {
  this.$router.push(`/expenditure/${this.data.id}/pay`)
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateRecordSupplier = async function(supplier_id) {
  return this.updateData({
    id: this.data.id,
    data: { supplier_id },
  })
}

methods.updateRecordAccountBankNo = async function(account_bank_no) {
  return this.updateData({
    id: this.data.id,
    data: { account_bank_no },
  })
}

methods.updateRecordAccountBank = async function(account_bank) {
  return this.updateData({
    id: this.data.id,
    data: { account_bank },
  })
}

methods.updateRecordInvoiceID = async function(invoice_id) {
  return this.updateData({
    id: this.data.id,
    data: { invoice_id },
  })
}

methods.updateRecordNote = async function(note) {
  return this.updateData({
    id: this.data.id,
    data: { note },
  })
}

methods.addProductItem = function() {
  this.$refs.AddItemDialog.show()
}

methods.addProduct = async function(product_id, quantity) {
  await this.updateListItem({ product_id, quantity })
}

methods.tagtoOrder = async function() {
  this.$refs.ConfirmDialog.confirm(`ORDER`, {
    confmsg: `Apakah anda yakin ingin mengubah status menjadi`,
    title: `ORDERED`,
  })
}

methods.tagtoReceive = async function() {
  this.$refs.ConfirmDialog.confirm(`RECEIVE`, {
    confmsg: `Apakah anda yakin ingin mengubah status menjadi`,
    title: `RECEIVED`,
  })
}

methods.tagtoCanceled = async function() {
  this.$refs.ConfirmDialog.confirm(`CANCEL`, {
    confmsg: `Apakah anda yakin ingin mengubah status menjadi`,
    title: `CANCELED`,
  })
}

methods.tagtoLock = async function() {
  this.$refs.ConfirmDialog.confirm(`LOCK`, {
    confmsg: `Apakah anda yakin ingin mengunci record ini`,
  })
}

methods.makeCargoRecord = async function() {
  this.$refs.EntryCargoDialog.show()
}

methods.createCargoRecord = async function(params) {
  const cargo_id = await this.createCargoAction(params)
  this.fetchData(cargo_id)
  this.$router.push(`/expenditure/${cargo_id}`)
  this.$refs.EntryCargoDialog.dismiss()
}

methods.confirmAction = async function(action) {
  if (action === `ORDER`) return this.updateStatusOrdered()
  if (action === `RECEIVE`) return this.updateStatusReceived()
  if (action === `LOCK`) return this.lockData()
  if (action === `CANCEL`) {
    if (!this.data.total_paid) {
      return this.confirmCancelTransaction({ amount: 0 })
    }

    return this.$refs.CancelTransactionDialog.show()
  }
}

methods.confirmCancelTransaction = async function(params) {
  if (!params.amount) return this.updateStatusCanceled()

  params.amount = params.amount * -1
  const isRefundSuccess = await this.modelPay(params)

  if (isRefundSuccess) return this.updateStatusCanceled()
}

methods.confirmDelete = async function() {
  this.$refs.DeleteDialog.confirm(this.data.id, this.data.record_id)
}

methods.deleteData = async function() {
  await this.removeData(this.data.id)
  this.goback()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      if (n.id !== o.id) {
        this.fetchListItem()
        this.fetchListPayment()
      }
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
  this.$store.commit(`add_breadcrumb`, this.data.record_id)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const id = this.$route.params.id

  if (!this.data.id || this.data.id !== id) await this.fetchData(id)

  this.$store.commit(`set_page_title`, `Kasir`)
  this.$store.commit(`set_breadcrumb`, [ `Data Pembelanjaan`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          DETAIL RECORD

          <ButtonR text="Hapus Record" color="danger"
            v-if="!data.locked && data.isDraft"
            @click="confirmDelete"
          />
          <ButtonR text="Kunci Record" color="success"
            v-if="((data.isReceived && data.isPaid) || data.isCanceled) && !data.locked"
            @click="tagtoLock"
          />
          <ButtonR text="Batalkan" color="danger"
            v-if="(data.isOrdered || data.isReceived) && (data.status_order !== 'CANCELED')"
            @click="tagtoCanceled"
          />
          <ButtonR text="Order" color="primary"
            v-if="!data.locked && data.isDraft"
            @click="tagtoOrder"
          />
          <ButtonR text="Buat Nota Cargo" color="primary"
            v-if="!data.isDraft && !data.locked && data.allowCargo"
            @click="makeCargoRecord"
          />
          <ButtonR text="Terima Barang" color="primary"
            v-if="!data.isDraft && !data.isReceived && !data.isCanceled"
            @click="tagtoReceive"
          />
          <ButtonR text="Pembayaran" color="primary"
            v-if="!data.isDraft"
            @click="dopayment"
          />
        </template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Record ID</label>
                  <p>
                    {{ data.record_id }}
                    <label class="label label-info push-10-l" v-if="data.record_type === `CARGO`">Cargo</label>
                  </p>
                </Col12>
                <Col12>
                  <label>Supplier</label>
                  <p>
                    <span v-if="data.locked">{{ data.supplier_name }}</span>
                    <SelectFieldChangable
                      v-else
                      placeholder="Tidak ada supplier"
                      :options="supplierOptions"
                      :value="data.supplier_name"
                      @submit="updateRecordSupplier($event)"/>
                  </p>
                </Col12>
                <Col6>
                  <label>Pembayaran ke No Rekening</label>
                  <p>
                    <span v-if="data.locked">{{ data.account_bank_no }}</span>
                    <TextFieldChangable
                      v-else
                      placeholder="-"
                      :forceupper=true
                      :value="data.account_bank_no"
                      @submit="updateRecordAccountBankNo($event)"/>
                  </p>
                </Col6>
                <Col6>
                  <label>Bank</label>
                  <p>
                    <span v-if="data.locked">{{ data.account_bank }}</span>
                    <TextFieldChangable
                      v-else
                      placeholder="-"
                      :forceupper=true
                      :value="data.account_bank"
                      @submit="updateRecordAccountBank($event)"/>
                  </p>
                </Col6>
                <Col12>
                  <label>No Invoice</label>
                  <p>
                    <span v-if="data.locked">{{ data.invoice_id }}</span>
                    <TextFieldChangable
                      v-else
                      placeholder="Tidak ada no invoice"
                      :forceupper=true
                      :value="data.invoice_id"
                      @submit="updateRecordInvoiceID($event)"/>
                  </p>
                </Col12>
                <Col12>
                  <label>Note</label>
                  <p>
                    <span v-if="data.locked">{{ data.note || `-` }}</span>
                    <TextAreaChangable
                      v-else
                      placeholder="Tidak ada keterangan"
                      :rows=3
                      :forceupper=true
                      :value="data.note"
                      @submit="updateRecordNote($event)"/>
                  </p>
                </Col12>
              </Row>
            </div>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Status Order</label>
                  <p>{{ data.status_order }}</p>
                </Col12>
                <Col12>
                  <label>Petugas</label>
                  <p>{{ data.employee_name || `-` }}</p>
                </Col12>
                <div class="col-md-6">
                  <label>Tanggal Order</label>
                  <p v-if="data.status_order === `DRAFT`">-</p>
                  <p v-else>{{ data.date_ordered_readable }}</p>
                </div>
                <div class="col-md-6">
                  <label>Tanggal Diterima</label>
                  <p>{{ data.date_received_readable || `-` }}</p>
                </div>
                <div class="col-md-6">
                  <label>Status Pembayaran</label>
                  <p>
                    <label class="label label-danger" v-if="data.status_paid">Lunas</label>
                    <label class="label label-warning" v-else>Belum Lunas</label>
                    <span> {{ data.date_paid_readable }}</span>
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Sisa Pembayaran</label>
                  <p>Rp. {{ data.total_unpaid_readable }}</p>
                </div>
              </Row>
            </div>
          </Row>
        </template>

        <template #footer>
          <ButtonFormSet v-if="!isProfileLocked"
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="reset"
          />
        </template>
      </BlockTable>

      <BlockTable>
        <template #header>
          DAFTAR ITEM

          <ButtonR v-if="!data.isReceived"
            text="Tambah Barang"
            color="primary"
            @click="addProductItem" />
        </template>

        <template #content>
          <ItemList />
        </template>
      </BlockTable>
    </Row>

    <DeleteDialog ref="DeleteDialog" @submit="deleteData($event)" />
    <ConfirmDialog ref="ConfirmDialog" @submit="confirmAction($event)" />
    <EntryCargoDialog ref="EntryCargoDialog" @submit="createCargoRecord($event)" />
    <CancelTransactionDialog ref="CancelTransactionDialog" @submit="confirmCancelTransaction" />

    <SelectListDialog
      id="AddItemDialog"
      ref="AddItemDialog"
      :options="itemOptions"
      @select="addProduct($event, 1)" />
  </layout>
</template>