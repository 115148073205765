
<script>
import { mapGetters } from 'vuex'

import BreadCrumb from '@/components/dashboard/Breadcrumb'
const components = {
  BreadCrumb,
}

const props = {
  type: {
    type: String,
    default: ``
  }
}

const computed = mapGetters({
  title: `pageTitle`,
  subtitle: `pageSubtitle`,
})

computed.layoutClass = function() {
  return [
    `content`,
    (this.type === `narrow`) && `content-narrow`,
  ]
}

export default {
  props,
  components,
  computed,
}
</script>

<template>
  <div>
    <div class="content bg-gray-lighter">
      <div class="row items-push">
        <div class="col-sm-6">
          <h1 class="page-heading">
            {{ title }} <small>{{ subtitle }}</small>
          </h1>
        </div>
        <div class="col-sm-6 text-right hidden-xs">
          <BreadCrumb />
        </div>
      </div>

      <slot name="header" />
    </div>

    <div :class="layoutClass">
      <slot />
    </div>
  </div>
</template>