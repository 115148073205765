<script>
import forceucase from '@/helpers/forminput-forceupper'

export default {
  props: {
    value: null,
    placeholder: null,
    rows: {
      type: Number,
      default: 3,
    },
    forceupper: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editmode: 0,
    inputValue: ``,
  }),
  methods: {
    submit(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        this.$emit(`submit`, this.inputValue)
        this.editmode = 0
      }
    },
    keypress(event) {
      if (this.forceupper) forceucase(event)
    },
    paste(event) {
      if (this.forceupper) forceucase(event)
    },
  },
}
</script>

<style scoped>
  textarea {
    resize: none;
  }
  a > span {
    color: #646464;
    border-bottom: 1px dashed #aaaaaa;
  }
</style>

<template>
  <div>
    <a v-if="!editmode" href="#" @click.prevent="editmode = 1">
      <span v-if="value">{{ value }}</span>
      <span v-if="!value && placeholder"><i>{{ placeholder }}</i></span>
    </a>

    <textarea v-else v-focus class="form-control"
      :rows="rows"
      @blur="editmode = 0"
      @input="inputValue = $event.target.value"
      @keyup="submit"
      @keypress="keypress"
      @paste="paste"
    />
  </div>
</template>