<script>
import { mapGetters, mapActions } from 'vuex'
const jQuery = window.$

const data = function() {
  return {
    value: ``,
    title: ``,
  }
}

/**
 * Methods
 *
 */
const methods = {}

methods.confirm = function (value, title) {
  this.value = value
  this.title = title
  jQuery(this.$refs.modal.$el).modal(`show`)
}

methods.dismiss = function () {
  jQuery(this.$refs.modal.$el).modal(`hide`)
}

methods.submit = function () {
  this.dismiss()
  this.$emit(`submit`, this.value)
}

export default {
  data,
  methods,
}
</script>

<template>
  <ModalDialog ref="modal" title="Hapus Record" size="sm">
    <p>
      Apakah anda yakin ingin menghapus data ini?
    </p>

    <p class="font-w700">
      {{ title }}
    </p>

    <small class="text-danger">
      Data yang sudah dihapus tidak dapat dikembalikan.
    </small>

    <template #footer>
      <Button class="pull-right push-10-l"
        text="Hapus"
        color="danger"
        @click="submit"
      />

      <Button class="pull-right"
        text="Cancel"
        color="none"
        @click="dismiss"
      />
    </template>
  </ModalDialog>
</template>
