<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Data
 *
 * */
const data = () => ({
  name: ``,
  email: ``,
  password: ``,
  storename: ``,
  cs_contact: ``,
  no_npwp: ``,
  stepCurrent: 1,
  stepAll: 3,
})


/**
 * Computed
 *
 * */
const computed = mapGetters({
  isloading: `Auth/loadingState`,
  iserror: `Auth/errorState`,
  errmsg: `Auth/errorMessage`,
})

/**
 * Methods
 *
 * */
const methods = mapActions({
  checkemail: `Auth/checkemail`,
  register: `Auth/register`,
  showAlert: `Auth/showAlert`,
  dismissAlert: `Auth/dismissAlert`,
})

methods.registerAttempt1 = async function(e) {
  if (!this.name) return this.showAlert(`Nama tidak boleh kosong.`)
  if (!this.email) return this.showAlert(`Email tidak boleh kosong.`)
  if (!this.password) return this.showAlert(`Password tidak boleh kosong.`)

  const exists = await this.checkemail(this.email)

  if (exists) {
    return this.showAlert(`Email sudah terdaftar.`)
  } else {
    this.stepCurrent = 2
  }
}

methods.registerAttempt2 = async function() {
  if (!this.storename) return this.showAlert(`Nama toko tidak boleh kosong.`)

  const success = await this.register({
      name: this.name,
      email: this.email,
      password: this.password,
      storename: this.storename,
      cs_contact: this.cs_contact,
      no_npwp: this.no_npwp,
  })

  if (success) {
    this.stepCurrent = 3
  } else {
    this.showAlert(`Tidak dapat memproses permintaan.`)
  }
}

methods.goToStep = async function(step) {
  this.stepCurrent = step
}

export default {
  data,
  computed,
  methods,
}
</script>

<template>
  <Block :header=true :loading="isloading" title="KASWARUNG DASHBOARD" color="primary">
    <Row>
      <Col10Center>
        <div v-if="stepCurrent === 1">
          <p class="text-left">
              <i>Selamat datang di <b>KASWARUNG</b></i>
          </p>

          <p class="text-left">
              <i>Lengkapi data berikut untuk melanjutkan
              proses register data pengguna baru</i>
          </p>

          <hr />
        </div>

        <p class="text-right">
            <small>Step {{ stepCurrent }} / {{ stepAll }}</small>
        </p>

        <div v-if="stepCurrent === 1"
          class="form-horizontal push-30-t push-50">

          <div class="form-group">
            <TextField
              id="login-fullname"
              name="login-fullname"
              label="Nama Lengkap"
              :forceupper=true
              allow="a-zA-Z0-9 "
              v-model="name">
            </TextField>

            <TextField
              :styleupper="true"
              id="login-username"
              name="login-username"
              label="Email"
              allow="a-zA-Z0-9@."
              v-model="email">
            </TextField>

            <div class="col-xs-12">
                <label for="login-password">Password</label>
                <input class="form-control" type="password" id="login-password" name="login-password" v-model="password">
            </div>
          </div>

          <p v-if="errmsg" class="text-danger">* {{ errmsg }}</p>

          <div class="form-group pull-right">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <Button color="primary" icon="hand-o-right" iconpos="right" text="Lanjut" @click="registerAttempt1" />
            </div>
          </div>
        </div>

        <div v-else-if="stepCurrent === 2"
          class="form-horizontal push-30-t push-50"
          @submit.prevent="registerAttempt2">

          <div class="form-group">
            <TextField
              id="login-storename"
              name="login-storename"
              label="Nama Toko"
              :forceupper=true
              allow="a-zA-Z0-9 "
              v-model="storename">
            </TextField>

            <TextField
              id="login-npwp"
              name="login-npwp"
              label="Nomor NPWP"
              :forceupper=true
              allow="a-zA-Z0-9."
              v-model="no_npwp">
            </TextField>

            <TextField
              id="login-contact"
              name="login-contact"
              label="Kontak Person"
              :forceupper=true
              allow="\+0-9"
              v-model="cs_contact">
            </TextField>
          </div>

          <p v-if="errmsg" class="text-danger">* {{ errmsg }}</p>

          <div class="form-group pull-left">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <Button color="default" text="Sebelumnya" @click="goToStep(1)" />
            </div>
          </div>

          <div class="form-group pull-right">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <Button color="primary" icon="hand-o-right" iconpos="right" text="Lanjut" @click="registerAttempt2" />
            </div>
          </div>
        </div>

        <div v-else-if="stepCurrent === 3">
          <p class="text-left">
              <i><b>Selamat!</b></i>
          </p>
          <p class="text-left">
              <i>Akun anda telah berhasil dibuat. Silahkan login ke halaman dasboard.</i>
          </p>
          <p class="text-left">
              <a href="/auth/signin">
                <Button color="primary" icon="hand-o-right" iconpos="right" text="Login" />
              </a>
          </p>
        </div>

        <div style="clear: both;"></div>
        <div v-if="stepCurrent !== 3">
          <hr>

          <p class="text-center">
            Sudah punya akun?
            <a href="/auth/signin">Login ke Dashboard</a>
          </p>
        </div>
      </Col10Center>
    </Row>
  </Block>
</template>
