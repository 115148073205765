
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    dialogTitle: `Tambah Record`,
    input: {},
    accountCategory: [
      { label: `PIUTANG`, value: `DEPT_ACC` },
      { label: `KASBON`, value: `CBON_ACC` },
    ],
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  processing: `OpPurchase/Account/loadingState`,
  employeeid: `Employee/id`,
})

computed.validate = function() {
  return !!this.input.name && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `OpPurchase/Account/fetchData`,
  entry: `OpPurchase/Account/update`,
  refetch: `Employee/refetchData`,
})

methods.reset = function() {
  this.input = {
    employee_id: this.employeeid,
    editable: true,
    category: `DEPT_ACC`,
    sort_index: 100,
  }
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.reset()
  this.$refs.EntryDialog.show()
}

methods.addrecord = async function() {
  await this.entry({ data: this.input })
  await this.refetch()
  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Kategori Akun"
        :options="accountCategory"
        v-model="input.category" />

      <TextField
        label="Nama Akun Piutang"
        :forceupper=true
        v-model="input.name" />
    </FormGroup>
  </EntryDialog>
</template>