
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    typeOptions: [
      { label: `STOK MASUK`, value: `STOCK_IN` },
      { label: `STOK KELUAR`, value: `STOCK_OUT` },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/Transaction/data`,
  processing: `Product/Transaction/loadingState`,
})
computed.validate = function() {
  return !!this.input.type && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Product/Transaction/fetchData`,
  entry: `Product/Transaction/update`,
})

methods.reset = function() {
  this.input = { type: this.typeOptions[0].value }
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.addrecord = async function() {
  const data = this.input
  const successid = await this.entry({ data })

  if (successid) {
    this.dismiss()
    this.$router.push(`/product/transaction/${successid}`)
  }
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Jenis Record"
        :options="typeOptions"
        v-model="input.type" />
    </FormGroup>
  </EntryDialog>
</template>