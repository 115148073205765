<script>
import { mapGetters, mapActions } from 'vuex'
const jQuery = window.$

const data = function() {
  return {
    value: ``,
    title: ``,
    confmsg: ``,
    alertmsg: ``,
  }
}

/**
 * Methods
 *
 */
const methods = {}

methods.confirm = function (value, opt) {
  this.value = value
  this.title = opt.title
  this.confmsg = opt.confmsg
  this.alertmsg = opt.alertmsg
  jQuery(this.$refs.modal.$el).modal(`show`)
}

methods.dismiss = function () {
  jQuery(this.$refs.modal.$el).modal(`hide`)
}

methods.submit = function () {
  this.dismiss()
  this.$emit(`submit`, this.value)
}

export default {
  data,
  methods,
}
</script>

<template>
  <ModalDialog ref="modal" title="Confirmation" size="sm">
    <p>
      {{ confmsg }}
    </p>

    <p class="font-w700">
      {{ title }}
    </p>

    <small class="text-danger">
      {{ alertmsg }}
    </small>

    <template #footer>
      <Button class="pull-right push-10-l"
        text="Confirm"
        color="danger"
        @click="submit"
      />

      <Button class="pull-right"
        text="Cancel"
        color="none"
        @click="dismiss"
      />
    </template>
  </ModalDialog>
</template>
