
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    email: ``,
    confirmation: false,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    storeCode: `Auth/storecode`,
    account: `Employee/account`,
  }),
  validate() {
    return this.email && this.confirmation
  }
}

/**
 * Methods
 *
 */
const methods = {}

methods.reset = function() {
  this.confirmation = false
  this.email = this.account.email
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.addrecord = async function() {
  this.$emit(`submit`, this.email)
}

export default {
  components,
  data,
  computed,
  methods,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <TextField
        label="Email Baru"
        :forceupper=true
        allow="a-zA-Z0-9@.-"
        v-model="email">
      </TextField>
    </FormGroup>

    <Row>
      <Col12>
        <p class="text-danger">
          <i>Mengganti email akan membuat pemilik akun
          kehilangan akses atas email yang lama.</i>
        </p>
      </Col12>
    </Row>

    <FormGroup>
      <CheckButton
        label="Ya, Saya mengerti"
        v-model="confirmation" />
    </FormGroup>
  </EntryDialog>
</template>