
/**
 * Actions
 *
 */
const actions = {
  async socket_masterDepartmentListUpdated({ dispatch }) {
    await dispatch(`Master/Department/fetchOptions`)
  },
  async socket_productBrandListUpdated({ dispatch }) {
    await dispatch(`Product/Brand/fetchList`)
    await dispatch(`Product/Brand/fetchOptions`)
  },
  async socket_productCategoryListUpdated({ dispatch }) {
    await dispatch(`Product/Category/fetchList`)
    await dispatch(`Product/Category/fetchOptions`)
  },
  async socket_productSupplierListUpdated({ dispatch }) {
    await dispatch(`Product/Supplier/fetchList`)
    await dispatch(`Product/Supplier/fetchOptions`)
  },
  async socket_productItemListUpdated({ dispatch }) {
    await dispatch(`Product/Item/fetchList`)
    await dispatch(`Product/Item/fetchOptions`)
  },
  async socket_productServiceListUpdated({ dispatch }) {
    await dispatch(`Product/Service/fetchList`)
    await dispatch(`Product/Service/fetchOptions`)
  },
  async socket_productPackageListUpdated({ dispatch }) {
    await dispatch(`Product/Package/fetchList`)
    await dispatch(`Product/Package/fetchOptions`)
  },
  async socket_courierListUpdated({ dispatch }) {
    await dispatch(`Courier/fetchList`)
    await dispatch(`Courier/fetchOptions`)
  },

  /* Employee */
  async socket_employeeListUpdated({ dispatch }) {
    await dispatch(`Employee/fetchList`)
    await dispatch(`Employee/fetchOptions`)
  },
  async socket_employeeDataUpdated({ dispatch, rootState }, { employee_id }) {
    await dispatch(`Employee/fetchOptions`)
    if (rootState.Employee.selectedObject.id === employee_id) {
      await dispatch(`Employee/refetchData`)
    }

    if (rootState.Employee.list.find(x => x.id === employee_id)) {
      await dispatch(`Employee/fetchList`)
    }
  },
  async socket_employeeDataRemoved({ dispatch, rootState }, { employee_id }) {
    await dispatch(`Employee/fetchOptions`)

    if (rootState.Employee.list.find(x => x.id === employee_id)) {
      await dispatch(`Employee/fetchList`)
    }
  },
  async socket_employeePermissionUpdated({ dispatch, rootState }, { employee_id }) {
    if (rootState.Auth.user.employee_id === employee_id) {
      await dispatch(`Auth/fetchPermission`)
    }

    if (rootState.Employee.list.find(x => x.id === employee_id)) {
      await dispatch(`Employee/fetchPermission`)
    }
  },

  /* Customer */
  async socket_customerListUpdated({ dispatch }) {
    await dispatch(`Customer/fetchList`)
    await dispatch(`Customer/fetchOptions`)
  },
  async socket_customerDataUpdated({ dispatch, rootState }, data) {
    if (rootState.Customer.selectedObject.id === data.customer_id) {
      await dispatch(`Customer/refetchData`)
    }
  },

  /* Customer Group */
  async socket_customerGroupListUpdated({ dispatch }) {
    await dispatch(`Customer/Group/fetchList`)
    await dispatch(`Customer/Group/fetchOptions`)
  },
  async socket_customerGroupDataUpdated({ dispatch, rootState }, { group_id }) {
    await dispatch(`Customer/Group/fetchOptions`)
    if (rootState.Customer.Group.selectedObject.id === group_id) {
      await dispatch(`Customer/Group/refetchData`)
    }

    if (rootState.Customer.Group.list.find(x => x.id === group_id)) {
      await dispatch(`Customer/Group/fetchList`)
    }
  },
  async socket_customerGroupDataRemoved({ dispatch, rootState }, { group_id }) {
    await dispatch(`Customer/Group/fetchOptions`)

    if (rootState.Customer.Group.list.find(x => x.id === group_id)) {
      await dispatch(`Customer/Group/fetchList`)
    }
  },

  /* Cash Account */
  async socket_cashaccountListUpdated({ dispatch }) {
    await dispatch(`CashAccount/fetchList`)
    await dispatch(`CashAccount/fetchOptions`)
  },
  async socket_cashaccountDataUpdated({ dispatch, rootState }, { method_id }) {
    await dispatch(`CashAccount/fetchOptions`)
    if (rootState.CashAccount.selectedObject.id === method_id) {
      await dispatch(`CashAccount/refetchData`)
    }

    if (rootState.CashAccount.list.find(x => x.id === method_id)) {
      await dispatch(`CashAccount/fetchList`)
    }
  },
  async socket_cashaccountDataRemoved({ dispatch, rootState }, { method_id }) {
    await dispatch(`CashAccount/fetchOptions`)

    if (rootState.CashAccount.list.find(x => x.id === method_id)) {
      await dispatch(`CashAccount/fetchList`)
    }
  },

  /* Payment Method */
  async socket_paymethodListUpdated({ dispatch }) {
    await dispatch(`PayMethod/fetchList`)
    await dispatch(`PayMethod/fetchOptions`)
  },
  async socket_paymethodDataUpdated({ dispatch, rootState }, { method_id }) {
    await dispatch(`PayMethod/fetchOptions`)
    if (rootState.PayMethod.selectedObject.id === method_id) {
      await dispatch(`PayMethod/refetchData`)
    }

    if (rootState.PayMethod.list.find(x => x.id === method_id)) {
      await dispatch(`PayMethod/fetchList`)
    }
  },
  async socket_paymethodDataRemoved({ dispatch, rootState }, { method_id }) {
    await dispatch(`PayMethod/fetchOptions`)

    if (rootState.PayMethod.list.find(x => x.id === method_id)) {
      await dispatch(`PayMethod/fetchList`)
    }
  },

  /* Sales */
  async socket_salesListUpdated({ dispatch }) {
    await dispatch(`Sales/fetchList`)
  },
  async socket_salesDataUpdated({ dispatch, rootState }, data) {
    if (rootState.Sales.selectedObject.id === data.sales_id) {
      await dispatch(`Sales/refetchData`)
    }
  },
  async socket_salesDataitemUpdated({ dispatch, rootState }, data) {
    if (rootState.Sales.selectedObject.id === data.sales_id) {
      await dispatch(`Sales/fetchListItem`)
    }
  },
  async socket_salesDatapaymentUpdated({ dispatch, rootState }, data) {
    if (rootState.Sales.selectedObject.id === data.sales_id) {
      await dispatch(`Sales/fetchListPayment`)
    }
  },

  /* Expenditure */
  async socket_expenditureListUpdated({ dispatch }) {
    await dispatch(`Product/Expenditure/fetchList`)
  },
  async socket_expenditureDataUpdated({ dispatch, rootState }, data) {
    if (rootState.Product.Expenditure.selectedObject.id === data.expenditure_id) {
      await dispatch(`Product/Expenditure/refetchData`)
    }
  },
  async socket_expenditureDataitemUpdated({ dispatch, rootState }, data) {
    if (rootState.Product.Expenditure.selectedObject.id === data.expenditure_id) {
      await dispatch(`Product/Expenditure/fetchListItem`)
    }
  },
  async socket_expenditureDatapaymentUpdated({ dispatch, rootState }, data) {
    if (rootState.Product.Expenditure.selectedObject.id === data.expenditure_id) {
      await dispatch(`Product/Expenditure/fetchListPayment`)
    }
  },

  /* SO Record */
  async socket_stockopnameListUpdated({ dispatch }) {
    await dispatch(`Product/SOpname/fetchList`)
  },
  async socket_stockopnameDataUpdated({ dispatch, rootState }, data) {
    if (rootState.Product.SOpname.selectedObject.id === data.so_id) {
      await dispatch(`Product/SOpname/refetchData`)
    }
  },
  async socket_stockopnameDataitemUpdated({ dispatch, rootState }, data) {
    if (rootState.Product.SOpname.selectedObject.id === data.so_id) {
      await dispatch(`Product/SOpname/fetchListItem`)
    }
  },

  /* StorePurchase Category */
  async socket_storePurchaseCategoryListUpdated({ dispatch }) {
    await dispatch(`StPurchase/Category/fetchList`)
    await dispatch(`StPurchase/Category/fetchOptions`)
  },
  async socket_storePurchaseCategoryDataUpdated({ dispatch, rootState }, data) {
    if (rootState.StPurchase.Category.selectedObject.id === data.record_id) {
      await dispatch(`StPurchase/Category/refetchData`)
    }
  },

  /* StorePurchase Record */
  async socket_storePurchaseListUpdated({ dispatch }) {
    await dispatch(`StPurchase/fetchList`)
  },
  async socket_storePurchaseDataUpdated({ dispatch, rootState }, data) {
    if (rootState.StPurchase.selectedObject.id === data.record_id) {
      await dispatch(`StPurchase/refetchData`)
    }
  },

  /* OperationalPurchase Account */
  async socket_operationalPurchaseAccountListUpdated({ dispatch }) {
    await dispatch(`OpPurchase/Account/fetchList`)
    await dispatch(`OpPurchase/Account/fetchOptions`)
  },
  async socket_operationalPurchaseAccountDataUpdated({ dispatch, rootState }, data) {
    if (rootState.OpPurchase.Account.selectedObject.id === data.record_id) {
      await dispatch(`OpPurchase/Account/refetchData`)
    }
  },

  /* Other Income */
  async socket_otherIncomeListUpdated({ dispatch }) {
    await dispatch(`Finance/OtherIncome/fetchList`)
  },
  async socket_otherIncomeDataUpdated({ dispatch, rootState }, data) {
    if (rootState.Finance.OtherIncome.selectedObject.id === data.record_id) {
      await dispatch(`Finance/OtherIncome/refetchData`)
    }
  },
}

/**
 * Mutations
 *
 */
const mutations = {
}

export default {
  actions,
  mutations
}
