
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/Expenditure/data`,
  itemlist: `Product/Expenditure/itemlist`,
  paymentlist: `Product/Expenditure/paymentlist`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  updateListItem: `Product/Expenditure/updateListItem`,
  removeListItem: `Product/Expenditure/removeListItem`,
})

methods.updateQuantity = async function (product_id, quantity) {
  return this.updateListItem({
    product_id,
    quantity,
  })
}

methods.updatePriceBought = async function (product_id, price_bought) {
  return this.updateListItem({
    product_id,
    price_bought,
  })
}

methods.confirmDelete = async function (id, name) {
  this.$refs.DeleteDialog.confirm(id, name)
}

methods.deleteItem = async function(product_id) {
  await this.removeListItem({ product_id })
}

/**
 * Watch
 *
 */
const watch = {
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style>
  .table>tfoot>tr>td {
    border-top: none;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th class="text-center" width="48">#</th>
        <th class="text-left">Nama Produk</th>
        <th width="60" class="text-right">Quantity</th>
        <th width="120" class="text-right">Harga</th>
        <th width="120" class="text-right">Subtotal</th>
        <th width="40" v-if="!data.isReceived"></th>
      </template>

      <template #body>
        <tr v-for="(item, index) in itemlist" :key="item.id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-right">
            <span v-if="data.isReceived">{{ item.quantity }}</span>
            <TextFieldChangable v-else
              :value="item.quantity"
              @submit="updateQuantity(item.product_id, $event)"
            />
          </td>
          <td class="text-right">
            <span v-if="data.isReceived">{{ item.price_bought_readable }}</span>
            <TextFieldChangable v-else
              :value="item.price_bought_readable"
              @submit="updatePriceBought(item.product_id, $event)"
            />
          </td>
          <td class="text-right">{{ item.subtotal_readable }}</td>
          <td class="text-right" v-if="!data.isReceived">
            <TButtonDelete @click="confirmDelete(item.product_id, item.product_name)" />
          </td>
        </tr>
      </template>

      <template #foot>
        <tr class="spacer">
          <td colspan="4" class="text-right"><label>TOTAL BELANJA</label></td>
          <td class="text-right">{{ data.total_expenditure_readable }}</td>
          <td v-if="!data.isReceived"></td>
        </tr>
        <tr v-for="(item, index) in paymentlist" :key="index">
          <td colspan="4" class="text-right">
            {{ item.date_readable }} - {{ item.method_name }}
          </td>
          <td class="text-right">{{ item.amount_readable }}</td>
          <td v-if="!data.isReceived"></td>
        </tr>
        <tr class="spacer">
          <td colspan="4" class="text-right"><label>TOTAL DIBAYAR</label></td>
          <td class="text-right">{{ data.total_paid_readable }}</td>
          <td v-if="!data.isReceived"></td>
        </tr>
        <tr>
          <td colspan="4" class="text-right"><label>SISA</label></td>
          <td class="text-right">{{ data.total_unpaid_readable }}</td>
          <td v-if="!data.isReceived"></td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>