
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/Package/data`,
  processing: `Product/Package/loadingState`
})
computed.validate = function() {
  return !!this.input.name && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Product/Package/fetchData`,
  entry: `Product/Package/update`,
})

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  return this.fetch(null).then(this.show)
}

methods.edit = async function(id) {
  this.id = id
  return this.fetch(id).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id
  const successid = await this.entry({ id, data })

  this.dismiss()

  if (successid) {
    this.$router.push(`/product/package/${successid}`)
  }
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <TextField
        label="Nama Paket"
        :forceupper=true
        v-model="input.name" />
    </FormGroup>
  </EntryDialog>
</template>