
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/loadingState`,
    data: `Employee/profile`,
    departmentOptions: `Master/Department/options`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  updateData: `Employee/update`,
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.isProfileLocked = true
  this.profile = { ...this.data }
}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.data.id,
    data: this.profile,
  })

  this.reset()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="PROFIL KARYAWAN"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)" >
    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <label>Nama Karyawan</label>
          <p>{{ profile.name }}</p>
        </Col12>

        <Col12>
          <label>Status Karyawan</label>
          <p v-if="profile.department_name">{{ profile.department_name }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Alamat Rumah</label>
          <p v-if="profile.address">{{ profile.address }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Nomor Kontak</label>
          <p v-if="profile.phone">{{ profile.phone }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Nomor Pokok Wajib Pajak</label>
          <p>
            <span v-if="profile.no_npwp">{{ profile.no_npwp }}</span>
            <span v-else class="nodata">(none)</span>
          </p>
        </Col12>

        <Col12>
          <label>Nomor BPJS Kesehatan</label>
          <p>
            <span v-if="profile.no_bpjs_kes">{{ profile.no_bpjs_kes }}</span>
            <span v-else class="nodata">(none)</span>
          </p>
        </Col12>

        <Col12>
          <label>Nomor BPJS Naker</label>
          <p>
            <span v-if="profile.no_bpjs_naker">{{ profile.no_bpjs_naker }}</span>
            <span v-else class="nodata">(none)</span>
          </p>
        </Col12>
      </Row>
      <FormGroup v-else>
        <TextField
          label="Nama Karyawan"
          width="8"
          :forceupper=true
          v-model="profile.name" />

        <SelectField
          label="Status Karyawan"
          :options="departmentOptions"
          v-model="profile.department_id" />

        <TextField
          label="Alamat Rumah"
          width="12"
          :forceupper=true
          v-model="profile.address" />

        <TextField
          label="Nomor Kontak"
          sublabel="Cth. 02262xxxxx"
          width="8"
          v-model="profile.phone" />

        <TextField
          label="Nomor Pokok Wajib Pajak"
          width="8"
          before="Rp."
          :disabled=isProfileLocked
          v-model="profile.no_npwp" />

        <TextField
          label="Nomor BPJS Kesehatan"
          width="8"
          before="Rp."
          :disabled=isProfileLocked
          v-model="profile.no_bpjs_kes" />

        <TextField
          label="Nomor BPJS Naker"
          width="8"
          before="Rp."
          :disabled=isProfileLocked
          v-model="profile.no_bpjs_naker" />
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="updateProfile"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>