
import axios from 'axios'
import Api from '../../helpers/api'
import alert from '../../helpers/alert'

const api = new Api()

let user = {}
let store = {}

try {
  user = JSON.parse(localStorage.getItem('user') || `{}`)
} catch (error) {
  //
}

/**
 * State
 *
 */
const state = {
  message: ``,
  isError: false,
  isLoading: false,

  token: localStorage.getItem('token') || '',
  user,
  permission: [],
  contentState: true,
}


/**
 * Getters
 *
 */
const getters = {
  loadingState: (state) => state.isLoading,
  errorState: (state) => state.isError,
  errorMessage: (state) => state.message,

  isLoggedIn: (state) => !!state.token && !!state.user,
  storename: ({ user }) => user.storename || ``,
  storeid: ({ user }) => user.storeid || ``,
  storecode: ({ user }) => user.storecode || ``,
  userfullname: ({ user }) => user.fullname || ``,
  permission: (state) => state.permission || [],
  contentState: (state) => state.contentState,

  model: (state) => ({
    hasPermission: (perm) => state.permission.includes(perm),
  })
}


/**
 * Actions
 *
 */
const actions = {
  showAlert({ commit }, message) {
    // commit(`set_message`, message)
    return alert.danger(message)
  },

  dismissAlert({ commit }) {
    commit(`clear_message`)
  },

  async login({ commit }, user) {
    commit(`loading_mode`)

    const onsuccess = ({ data }) => {
      localStorage.removeItem('token')
      axios.defaults.headers.common['Authorization'] = data.token

      commit(`set_userdata`, data)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      commit(`set_message`, err.message)
      return 0
    }

    return api.post(`/auth`, user)
      .then(onsuccess)
      .catch(onerror)
  },

  async fetchPermission({ commit }) {
    commit(`loading_mode`, 1)
    commit(`set_permission`, [])

    const onsuccess = ({ data }) => {
      commit(`loading_mode`, 0)
      commit(`set_permission`, data)
      return 1
    }

    const onerror = () => {
      commit(`loading_mode`, 0)
      return 0
    }

    return api.get(`/me/permission`, user)
      .then(onsuccess)
      .catch(onerror)
  },

  async logout({ commit }) {
    commit('clear_userdata')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    delete axios.defaults.headers.common['Authorization']
  },

  async checkemail({ commit }, email) {
    commit(`loading_mode`)

    const onsuccess = ({ data }) => {
      commit(`loading_mode`, 0)
      return data.status === `ISUSED`
    }

    const onerror = () => {
      commit(`loading_mode`, 0)
      return 0
    }

    return api.post(`/checkemail`, { email })
      .then(onsuccess)
      .catch(onerror)
  },

  async register({ commit }, params) {
    commit('clear_userdata')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    delete axios.defaults.headers.common['Authorization']

    commit(`loading_mode`)

    const onsuccess = ({ data }) => {
      commit(`loading_mode`, 0)
      return 1
    }

    const onerror = () => {
      commit(`loading_mode`, 0)
      return 0
    }

    return api.post(`/register`, params)
      .then(onsuccess)
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  loading_mode(state, mode) {
    mode = (mode === undefined) ? 1 : mode
    state.isLoading = !!mode
  },

  clear_message(state) {
    state.message = ``
    state.isError = 0
  },

  set_message(state, message) {
    state.message = message
    state.isError = 1
  },

  set_store(state, store) {
    state.user.store = store.name
    localStorage.setItem('user', JSON.stringify(state.user))
  },

  set_userdata(state, { token, user }) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(state.user))

    if (token) {
      state.token = token
      localStorage.setItem('token', token)
    }
  },

  clear_userdata(state) {
    state.token = ``
    state.user = ``
  },

  set_permission(state, permission) {
    state.permission = permission
  },

  content_state(state, value) {
    state.contentState = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}