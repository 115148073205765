
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/ExpenditureReport/data`,
  itemlist: `Product/ExpenditureReport/itemlist`,
  paymentlist: `Product/ExpenditureReport/paymentlist`,
  employeeOptions: `Employee/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
})

/**
 * Watch
 *
 */
const watch = {
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style>
  .table>tfoot>tr>td {
    border-top: none;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th class="text-center" width="48">#</th>
        <th width="80" class="text-left">Tipe</th>
        <th class="text-left">Nama Item</th>
        <th width="60" class="text-right">Quantity</th>
        <th width="100" class="text-right">Harga</th>
        <th width="100" class="text-right">Subtotal</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in itemlist" :key="item.id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ item.type }}</td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-right">
            <span>{{ item.quantity }}</span>
          </td>
          <td class="text-right">
            <span>{{ item.price_bought_readable }}</span>
          </td>
          <td class="text-right">{{ item.subtotal_readable }}</td>
        </tr>
      </template>

      <template #foot>
        <tr class="spacer">
          <td colspan="5" class="text-right"><label>TOTAL BELANJA</label></td>
          <td class="text-right">{{ data.total_sales_readable }}</td>
        </tr>
        <tr v-for="(item, index) in paymentlist" :key="index">
          <td colspan="5" class="text-right">
            {{ item.date_readable }} - {{ item.method_name }}
          </td>
          <td class="text-right">{{ item.amount_readable }}</td>
        </tr>
        <tr class="spacer">
          <td colspan="5" class="text-right"><label>TOTAL DIBAYAR</label></td>
          <td class="text-right">{{ data.total_paid_readable }}</td>
        </tr>
        <tr>
          <td colspan="5" class="text-right"><label>SISA</label></td>
          <td class="text-right">{{ data.total_unpaid_readable }}</td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>