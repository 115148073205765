<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'

const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Store/loadingState`,
    profile: `Store/profile`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchStoreData: `Store/fetch`,
  updateStoreData: `Store/update`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) {
    this.isProfileLocked = false
  }

  if (mode === `revert`) {
    this.reset()
  }
}

methods.reset = function() {
  this.input = {
    name: this.profile.name,
    address: this.profile.address,
    phone: this.profile.phone,
    cs_contact: this.profile.cs_contact,
    no_npwp: this.profile.no_npwp,
  }
  this.isProfileLocked = true
}

methods.submit = async function() {
  await this.updateStoreData({ data: this.input })
  this.reset()
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="PROFIL TOKO"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)"
  >
    <template #header>Setting Profil Toko</template>

    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <label>Nama Toko</label>
          <p>{{ profile.name }}</p>
        </Col12>

        <Col12>
          <label>Alamat Toko</label>
          <p v-if="profile.address">{{ profile.address }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Nomor Telepon Toko</label>
          <p v-if="profile.phone">{{ profile.phone }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Nomor Kontak CS</label>
          <p v-if="profile.cs_contact">{{ profile.cs_contact }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>

        <Col12>
          <label>Nomor Pokok Wajib Pajak</label>
          <p v-if="profile.no_npwp">{{ profile.no_npwp }}</p>
          <p v-else class="nodata">(none)</p>
        </Col12>
      </Row>
      <FormGroup v-else>
        <TextField
          label="Nama Toko"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          v-model="input.name" />

        <TextField
          label="Alamat Toko"
          width="12"
          :disabled=isProfileLocked
          :forceupper=true
          v-model="input.address" />

        <TextField
          label="Nomor Telepon Toko"
          sublabel="Cth. 02262xxxxx"
          width="8"
          :disabled=isProfileLocked
          v-model="input.phone" />

        <TextField
          label="Nomor Kontak CS"
          sublabel="Cth. 02262xxxxx"
          width="8"
          :disabled=isProfileLocked
          v-model="input.cs_contact" />

        <TextField
          label="Nomor Pokok Wajib Pajak"
          width="8"
          :disabled=isProfileLocked
          v-model="input.no_npwp" />
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="submit"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>