
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Sales/data`,
  itemlist: `Sales/itemlist`,
  paymentlist: `Sales/paymentlist`,
  employeeOptions: `Employee/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  updateData: `Sales/update`,
  updateListItem: `Sales/updateListItem`,
  removeListItem: `Sales/removeListItem`,
})

methods.updateDeliveryCost = async function (value) {
  return this.updateData({
    id: this.data.id,
    data: {
      cost_delivery: value,
    },
  })
}

methods.updateQuantity = async function (product_id, quantity) {
  return this.updateListItem({
    product_id,
    quantity,
  })
}

methods.updatePriceUnit = async function (product_id, price_unit) {
  return this.updateListItem({
    product_id,
    price_unit,
  })
}

methods.updateWorker = async function (product_id, worker_id) {
  return this.updateListItem({
    product_id,
    worker_id,
  })
}

methods.confirmDelete = async function (id, name) {
  this.$refs.DeleteDialog.confirm(id, name)
}

methods.deleteItem = async function(product_id) {
  await this.removeListItem({ product_id })
}

/**
 * Watch
 *
 */
const watch = {
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style>
  .table>tfoot>tr>td {
    border-top: none;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th class="text-center" width="48">#</th>
        <th width="80" class="text-left">Tipe</th>
        <th class="text-left">Nama Item</th>
        <th width="180" class="text-left">Teknisi</th>
        <th width="60" class="text-right">Quantity</th>
        <th width="100" class="text-right">Harga</th>
        <th width="100" class="text-right">Subtotal</th>
        <th width="40" v-if="!data.isSent"></th>
      </template>

      <template #body>
        <tr v-for="(item, index) in itemlist" :key="item.id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left">{{ item.type }}</td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-left">
            <div v-if="item.cost_worker_commission">
              <span v-if="data.isSent">{{ item.worker_name }}</span>
              <SelectFieldChangable v-else
                placeholder="Pilih Teknisi"
                :value="item.worker_name"
                :formValue="item.worker_id"
                :options="employeeOptions"
                @submit="updateWorker(item.product_id, $event)"
              />
            </div>
          </td>
          <td class="text-right">
            <span v-if="data.isSent">{{ item.quantity }}</span>
            <TextFieldChangable v-else
              :value="item.quantity"
              @submit="updateQuantity(item.product_id, $event)"
            />
          </td>
          <td class="text-right">
            <span v-if="data.isSent">{{ item.price_unit_readable }}</span>
            <TextFieldChangable v-else
              :value="item.price_unit_readable"
              @submit="updatePriceUnit(item.product_id, $event)"
            />
          </td>
          <td class="text-right">{{ item.subtotal_readable }}</td>
          <td class="text-right" v-if="!data.isSent">
            <TButtonDelete @click="confirmDelete(item.product_id, item.product_name)" />
          </td>
        </tr>

        <tr v-if="data.cost_delivery">
          <td class="text-center">{{ itemlist.length + 1 }}</td>
          <td class="text-left">DELIVERY</td>
          <td class="text-left">ONGKOS PENGIRIMAN</td>
          <td class="text-left">  </td>
          <td class="text-right">1</td>
          <td class="text-right">
            <span v-if="data.isSent">{{ data.cost_delivery_readable }}</span>
            <TextFieldChangable v-else
              :value="data.cost_delivery_readable"
              @submit="updateDeliveryCost($event)"
            />
          </td>
          <td class="text-right">{{ data.cost_delivery_readable }}</td>
          <td class="text-right" v-if="!data.isSent"></td>
        </tr>
      </template>

      <template #foot>
        <tr class="spacer">
          <td colspan="6" class="text-right"><label>TOTAL BELANJA</label></td>
          <td class="text-right">{{ data.total_sales_readable }}</td>
          <td v-if="!data.isSent"></td>
        </tr>
        <tr v-for="(item, index) in paymentlist" :key="index">
          <td colspan="6" class="text-right">
            {{ item.date_readable }} - {{ item.method_name }}
          </td>
          <td class="text-right">{{ item.amount_readable }}</td>
          <td v-if="!data.isSent"></td>
        </tr>
        <tr class="spacer">
          <td colspan="6" class="text-right"><label>TOTAL DIBAYAR</label></td>
          <td class="text-right">{{ data.total_paid_readable }}</td>
          <td v-if="!data.isSent"></td>
        </tr>
        <tr>
          <td colspan="6" class="text-right"><label>SISA</label></td>
          <td class="text-right">{{ data.total_unpaid_readable }}</td>
          <td v-if="!data.isSent"></td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>