
import BaseModel from '../../helpers/basemodel'
import alert from '../../helpers/alert'
import Api from '../../helpers/api'
import moment from 'moment'
import QueryString from '../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
    stats_loading_mode: 0,
    stats_data: {
        today_trx_count: 0,
        monthly_trx_count: 0,
        monthly_total_sales: 0,
        monthly_total_income: 0,
    },
}


/**
 * Getters
 *
 */
const getters = {
    stats_is_loading(state) { return state.stats_loading_mode === 1 },
    stats_data(state) { return state.stats_data },

    stats_field1(state) {
        return {
            title: `Transaksi Penjualan`,
            detail: `Hari Ini`,
            value: state.stats_data.today_trx_count !== undefined
              ? state.stats_data.today_trx_count.readable()
              : `...`,
            after: `trx`,
        }
    },

    stats_field2(state) {
        return {
            title: `Transaksi Penjualan`,
            detail: `Bulan Ini`,
            value: state.stats_data.monthly_trx_count !== undefined
              ? state.stats_data.monthly_trx_count.readable()
              : `...`,
            after: `trx`,
        }
    },

    stats_field3(state) {
        return {
            title: `Total Penjualan`,
            detail: `Bulan Ini`,
            value: state.stats_data.monthly_total_sales !== undefined
              ? state.stats_data.monthly_total_sales.readable()
              : `...`,
            before: `Rp`,
        }
    },

    stats_field4(state) {
        return {
            title: `Total Pendapatan`,
            detail: `Bulan Ini`,
            value: state.stats_data.monthly_total_sales_income !== undefined
              ? state.stats_data.monthly_total_sales_income.readable()
              : `...`,
            before: `Rp`,
        }
    },
}


/**
 * Actions
 *
 */
const actions = {
  async getStats({ commit, getters }) {
    commit(`stats_loading_mode`, 1)

    return api.get(`/report/stats`)
      .then(({ data }) => {
        commit(`stats_loading_mode`, 0)
        commit(`stats_data`, data)
      })
      .catch(err => {
        commit(`stats_loading_mode`, 0)

        return 0
      })
  },

  async getWeeklyOverviewStatsData({ commit }) {
    commit(`stats_loading_mode`, 1)

    return api.get(`/report/stats-weekly-overview`)
      .then(({ data }) => {
        commit(`stats_loading_mode`, 0)

        return data
      })
      .catch(err => {
        commit(`stats_loading_mode`, 0)

        return 0
      })
  },

  async getWeeklyOverviewSalesData({ commit }) {
    commit(`stats_loading_mode`, 1)

    return api.get(`/report/sales-weekly-overview`)
      .then(({ data }) => {
        commit(`stats_loading_mode`, 0)

        return data
      })
      .catch(err => {
        commit(`stats_loading_mode`, 0)

        return 0
      })
  },

  async getLastTransactions({ commit }) {
    commit(`stats_loading_mode`, 1)

    return api.get(`/report/last-transactions`)
      .then(({ data }) => {
        commit(`stats_loading_mode`, 0)

        return data
      })
      .catch(err => {
        commit(`stats_loading_mode`, 0)

        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  stats_loading_mode(state, value) {
    state.stats_loading_mode = value
  },

  stats_data(state, value) {
    state.stats_data = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}