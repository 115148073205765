<script>
import forceucase from '@/helpers/forminput-forceupper'

export default {
  props: {
    value: null,
    allow: null,
    placeholder: null,
    forceupper: null,
  },
  data: () => ({
    editmodeValue: 0,
    inputValue: ``,
  }),
  computed: {
    filterExp() {
      if (!this.allow) return false
      return RegExp('[^' + this.allow + ']', `g`)
    },
  },
  methods: {
    editmode(value) {
      this.editmodeValue = value
    },
    submit(value) {
      this.$emit(`input`, value)
      this.editmodeValue = 0
    },
    handle(value) {
      this.inputValue = value
    },
    keypress(event) {
      if (this.filterExp && event.key.match(this.filterExp)) {
        event.preventDefault()
        return false
      }

      if (this.forceupper) forceucase(event)
    },
    paste(event) {
      const pastedText = event.clipboardData.getData('text')
      event.pastedText = pastedText

      if (this.filterExp && pastedText.match(this.filterExp)) {
        const filteredText = pastedText.replace(this.filterExp, ``)
        event.pastedText = filteredText
      }

      if (this.forceupper) forceucase(event)
    }
  },
}
</script>

<style scoped>
  a > span {
    color: #646464;
    border-bottom: 1px dashed #aaaaaa;
  }
</style>

<template>
  <div>
    <a v-if="!editmodeValue" href="#" @click.prevent="editmode(1)">
      <span v-if="value">{{ value }}</span>
      <span v-if="!value && placeholder"><i>{{ placeholder }}</i></span>
    </a>

    <DatePicker v-else @submit="submit($event)" />
  </div>
</template>