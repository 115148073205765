
const config = {
  element: 'body',
  type: `success`,
  allow_dismiss: true,
  newest_on_top: true,
  showProgressbar: false,
  placement: {
    from: `top`,
    align: `center`
  },
  offset: 20,
  spacing: 10,
  z_index: 1031,
  delay: 3000,
  timer: 1000,
  animate: {
    enter: 'animated fadeIn',
    exit: 'animated fadeOutDown'
  },
  template: `
  <div data-notify="container" class="col-xs-11 col-sm-4 alert alert-{0}" role="alert">
    <button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>
    <span data-notify="icon"class="fa fa-check"></span>
    <div class="text">
      <span data-notify="title"><strong>{1}</strong><br></span>
      <span data-notify="message">{2}</span>
    </div>
  </div>
  `
}

const icons = {
  success: `si si-check`,
  warning: `si si-shield`,
  danger: `si si-info`,
  info: `si si-pin`,
}

const titles = {
  success: `Success`,
  warning: `Attention`,
  danger: `Oops`,
  info: ``,
}

const jQuery = window.jQuery
const Notify = jQuery.notify

const emit = (type, message, title) => {
  const params = {
    message,
    title: title || titles[type],
    icon: icons[type],
  }

  Notify(params, { ...config, type })
}

exports.success = (message) => {
  return emit(`success`, message)
}

exports.warning = (message) => {
  return emit(`warning`, message)
}

exports.danger = (message) => {
  return emit(`danger`, message)
}

exports.info = (message) => {
  return emit(`info`, message)
}