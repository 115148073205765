
class QueryString {
  constructor(data) {
    this.params = data || []
  }

  push(value) {
    this.params.push(value)
  }

  get string() {
    return this.params.join('&')
  }
}

export default QueryString