
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'
import moment from 'moment'

/**
 * Const
 *
 */
const api = new Api()
const defaultDate = moment()

const categoryLabel = {
  SI_MONTHLY_SALLARY: `GAJI POKOK`,
  SI_POSITIONAL_ALLOWANCE: `TUNJANGAN JABATAN`,
  SI_DAILY_SALLARY: `UANG MAKAN TRANSPORT`,
  SI_OVERTIME: `LEMBURAN`,
  SI_SERVICE_COST: `PENDAPATAN SERVICE COST`,
  SI_DELIVERY: `PENDAPATAN DELIVERY`,
  SI_RESELL: `RESELL PRODUK`,
  SI_COST_BPJS_KES: `TUNJANGAN BPJS KESEHATAN`,
  SI_COST_BPJS_KER: `TUNJANGAN BPJS NAKER`,
  SI_THR: `TUNJANGAN HARI RAYA - DITAHAN`,
  SO_ABSENT: `POTONGAN ABSEN`,
  SO_KASBON: `KASBON`,
  SO_ANGSURAN: `ANGSURAN KREDIT BANK`,
  SO_PIUTANG: `PIUTANG KAS / BARANG HILANG`,
  SP_PPH21: `PPH21 (DIBAYAR SENDIRI)`,
}



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}

state.listOptions.month = defaultDate.format(`MM`)
state.listOptions.year = defaultDate.format(`YYYY`)


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  categoryLabel() { return categoryLabel },

  data({ selectedObject }) {
    return selectedObject
  },

  period({ listOptions: opt }) {
    return `${opt.year}${opt.month}`
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetch({ commit, getters }, employee_id) {
    commit(`loading_mode`, 1)

    return api.get(`/employee/${employee_id}/sallary/${getters.period}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async setSallary({ commit, getters }, { id, data }) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)

      alert.success(`Data berhasil diupdate`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)

      if (err.message) alert.danger(err.message)
      return 0
    }

    return api.put(`/employee/${id}/sallary/${getters.period}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async pay({ commit, getters }, { employee_id, params }) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)

      alert.success(`Data berhasil diupdate`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)

      if (err.message) alert.danger(err.message || err)
      return 0
    }

    return api.post(`/employee/${employee_id}/paysallary/${getters.period}`, params)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}