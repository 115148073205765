
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'
import moment from 'moment'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  dategroup({ listOptions: opt }) {
    return opt.dategroup.replace(/(\d{2})\/(\d{2})\/(\d{4})/, `$3$2$1`)
  }
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/employee/attendance/` + getters.dategroup)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async update({ commit, getters }, { id, data }) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)

      alert.success(`Data berhasil diupdate`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)

      if (err.message) alert.danger(err.message)
      return 0
    }

    return api.put(`/employee/attendance/` + getters.dategroup, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}