
import RouteHelper from '../helpers/routes'
const routes = RouteHelper();

/**
 * Base Route Path = `/auth`
 *
 */

import SignInForm from '@/views/Auth/SignInForm.vue'
routes.set(`signin`, SignInForm)

export default {
  children: routes.get(),
}