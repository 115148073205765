
import Promise from 'bluebird'
import moment from 'moment'

import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()
const defaultDate = moment()

/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchData({ commit, getters }) {
    commit(`loading_mode`, 1)

    const asyncTask = []

    asyncTask.push(async () => {
      return api.get(`/cashaccount/balance-summarize?` + getters.listOptions)
        .then(({ data }) => {
          commit(`set_list`, data)
        })
        .catch(err => {
          commit(`show_error`, err.message)
          return 0
        })
    })

    return Promise.map(asyncTask, t => t())
      .then(() => commit(`loading_mode`, 0))
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}