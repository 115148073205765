import moment from 'moment'

import Vue from 'vue'
Vue.config.productionTip = false

/**
 * Injecting Methods
 */
Number.prototype.abs = function() {
  if (!this) return this

  return Math.abs(this)
}

Number.prototype.readable = function(defaultValue = `0`) {
  if (!this) return defaultValue

  return this.toLocaleString()
}

String.prototype.readabledate = function(defaultValue = ``) {
  if (!this) return defaultValue

  return moment(this).format(`DD/MM/YYYY HH:mm`)
}

/**
 * Implement Axios (HTTP Request) and Token Authorization
 */
import Axios from 'axios'
Vue.prototype.$http = Axios

const token = localStorage.getItem(`token`)
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

/**
 * Implement socket.io
 */
import SocketIO from "socket.io-client"
import VueSocketIO from 'vue-socket.io-extended'
import store from './store'
Vue.use(VueSocketIO, SocketIO(process.env.VUE_APP_SOCKET_BASEURL), { store })

import router from './router'
import App from './App.vue'

new Vue({
  router,
  store,
  jquery: window.$,
  render: h => h(App)
}).$mount('#app')
