
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/Package/data`,
  list: `Product/Package/itemlist`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  reload: `Product/Package/fetchListItem`,
})

methods.edit = function (id) {
  this.$router.push(`/product/package/${id}`)
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      n.id !== o.id && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="80" class="text-left">Tipe</th>
        <th class="text-left">Nama Item</th>
        <th width="40" class="text-right">Jumlah</th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + 1"></td>
          <td class="text-left">{{ item.product_type }}</td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-right">{{ item.quantity }}</td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>