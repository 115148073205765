
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import moment from 'moment'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  itemlist: [],
  paymentlist: [],
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([
      // `limit=${opt.pagelimit}`,
      // `skip=` + (opt.pagenumber - 1) * opt.pagelimit,
      `type=report`,
    ])

    if (opt.format) query.push(`format=${opt.format}`)
    if (opt.search) query.push(`search=${opt.search}`)
    if (opt.datefrom) query.push(`datefrom=${opt.datefrom}`)
    if (opt.dateto) query.push(`dateto=${opt.dateto}`)

    return query.string
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get parent_date_ordered_readable() { return moment(this.parent_date_ordered).format(`DD/MM/YYYY`) },
      get price_bought_readable() { return this.price_bought.toLocaleString() },
      get subtotal() { return this.price_bought * this.quantity },
      get subtotal_readable() { return this.subtotal.toLocaleString() },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/expenditure/boughtitems?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}