
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ReportTable from './Report'
const components = {
  layout,
  ReportTable
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    //
  }
}

/**
 * Computed
 *
 */
const computed = {}

/**
 * Methods
 *
 */
const methods = {}

methods.goback = function () {
  return this.$router.go(-1)
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  //
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Karyawan`, `Gaji` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="$router.go(-1)" />
    </Row>

    <ReportTable />
  </layout>
</template>