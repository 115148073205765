
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import moment from 'moment'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()

const ENUM_CATEGORY = {
  STOR_ACC: `TOKO`,
  OPRA_ACC: `OPERASIONAL`,
  DEPT_ACC: `PIUTANG`,
  CBON_ACC: `KASBON`,
}

const ENUM_COUNTER_TYPE = {
  MN: `BY SISTEM`,
  ST: `STATIS`,
  DY: `DINAMIS`,
}

/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  list: ({ list }) => list.map(item => ({
    ...item,
    get category_readable() { return ENUM_CATEGORY[this.category] },
    get counter_type_readable() { return ENUM_COUNTER_TYPE[this.counter_type] },
    get counter_formula_readable() {
      if (this.counter_type === `MN`) return `-`;

      let suffix = ``;
      let formula = this.counter_formula
        ? Number(this.counter_formula).readable()
        : ``

      if (this.counter_type === `DY`) suffix = `% Laba Kotor`;

      return `${formula}${suffix}`
    },
  }))
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/oppurchase/account?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchOptions({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/oppurchase/account?format=options`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_options`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/oppurchase/account/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async update({ commit, dispatch, rootState }, { id, data }) {
    commit(`loading_mode`, 1)

    if (!id) id = ``

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      await dispatch(`fetchData`, result.id)

      if (id) alert.success(`Data berhasil diupdate`)
      else alert.success(`Data berhasil ditambahkan`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STPURCHASE_R`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/oppurchase/account/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async remove({ commit, dispatch, rootState }, id) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_options`)
      // await dispatch(`fetchList`)
      alert.success(`Data berhasil dihapus`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STPURCHASE_R`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.delete(`/oppurchase/account/${id}`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}