
<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * Components
 *
 */
import RowData from './ReportTableRowData'
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import SelectListDialog from '@/components/uicomp/ModalDialog-Form-List'
const components = {
  RowData,
  DeleteDialog,
  SelectListDialog,
}

/**
 * Static Data
 *
 */
const props = {
  //
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    selectAccountDialog: {},
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    meta: `Employee/SallarySetting/listMeta`,
    data: `Employee/SallarySetting/list`,
    operationalAccountOptions: `OpPurchase/Account/options`
  }),
}

computed.list = function () {
  const init = {
    siPaidableList: [],
    siOtherPaidableList: [],
    siOtherMutatableList: [],
    siOtherList: [],
    soPaidableList: [],
    soOtherList: [],
    totalincome: 0,
    totalmutatable: 0,
    totaldeduction: 0,
    totalpayable: 0,
    totalSiPaidable: 0,
    totalOtherSiPaidable: 0,
    totalOtherSiMutatable: 0,
    totalSoPaidable: 0,
  }

  const res = this.data.reduce((ac, data) => {
    if (data.type === 'SO_LATE') {
      data.isPerMinute = true
    }

    const isDaily = [
      'SI_DAILY_SALLARY',
      'SO_ABSENT',
      'SO_SICK',
      'SO_ABSENT_LEAVE',
    ]
    if (isDaily.includes(data.type)) {
      data.isDaily = true
    }

    if (data.type.startsWith('SI_') && !data.type.startsWith('SI_OTHER')) {
      ac.siPaidableList.push(data)
      ac.totalSiPaidable += data.amount
    }

    if (data.type.startsWith('SI_OTHER') && !data.account_id) {
      ac.siOtherList.push(data)
      ac.siOtherPaidableList.push(data)
      ac.totalOtherSiPaidable += data.amount
    }

    if (data.type.startsWith('SI_OTHER') && data.account_id) {
      ac.siOtherList.push(data)
      ac.siOtherMutatableList.push(data)
      ac.totalOtherSiMutatable += data.amount
    }

    if (data.type.startsWith('SO_') && !data.type.startsWith('SO_OTHER')) {
      ac.soPaidableList.push(data)
      ac.totalSoPaidable += data.amount
    }

    if (data.type.startsWith('SO_OTHER')) {
      ac.soOtherList.push(data)
      ac.totalSoPaidable += data.amount
    }

    return ac
  }, init)

  res.totalincome = res.totalSiPaidable + res.totalOtherSiPaidable + res.totalOtherSiMutatable
  res.totaldeduction = res.totalSoPaidable
  res.totalpayable = res.totalincome - res.totaldeduction - res.totalOtherSiMutatable

  return res
}

computed.paidableSallary = function () {
  return this.list.siPaidableList.reduce((ac, i) => {
    ac[i.type] = i
    return ac
  }, {})
}

computed.deductionSallary = function () {
  return this.list.soPaidableList.reduce((ac, i) => {
    ac[i.type] = i
    return ac
  }, {})
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Employee/SallarySetting/fetch`,
  addSallary: `Employee/SallarySetting/add`,
  setSallary: `Employee/SallarySetting/setSallary`,
  removeSallary: `Employee/SallarySetting/remove`,
})

methods.setData = async function (data) {
  const eid = this.$route.params.id

  await this.setSallary({ eid, data })
  await this.fetch(eid)
}

methods.setAccount = async function (data) {
  this.selectAccountDialog.sid = data.id
  this.$refs.SelectAccountDialog.show()
}

methods.setAccountAction = async function (value) {
  await this.setData({
    id: this.selectAccountDialog.sid,
    account_id: value,
  })
}

methods.confirmRemoveSetting = async function (data) {
  this.$refs.DeleteDialog.confirm(data.id, data.name)
}

methods.deleteItem = async function (id) {
  const eid = this.$route.params.id
  await this.removeSallary({ eid, data: { id }})
  await this.fetch(eid)
}

methods.addIncome = async function () {
  const eid = this.$route.params.id
  const data = {
    type: `SI_OTHER`,
    name: ``,
    multiplier: 1,
  }

  await this.addSallary({ eid, data })
  await this.fetch(eid)
}

methods.addDeduction = async function () {
  const eid = this.$route.params.id
  const data = {
    type: `SO_OTHER`,
    name: ``,
    multiplier: -1,
  }

  await this.addSallary({ eid, data })
  await this.fetch(eid)
}

/**
 * Watchers
 *
 */
const watch = {
  //
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  //
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  //
}

export default {
  components,
  props,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <Col12>
      <TableList>
        <template #head>
          <td width="300"><label>Penerimaan</label></td>
          <td><label>Mutasi Ke Akun</label></td>
          <td width="10"></td>
          <td width="120"></td>
          <td width="50"></td>
          <td width="50"></td>
        </template>

        <template #body>
          <RowData key="monthly-sallary"
            :objdata="paidableSallary.SI_MONTHLY_SALLARY"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="postional-allowance"
            :objdata="paidableSallary.SI_POSITIONAL_ALLOWANCE"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="service-cost"
            :objdata="paidableSallary.SI_SERVICE_COST"
            :ableChangeAmount="false"
          />
          <RowData key="delivery"
            :objdata="paidableSallary.SI_DELIVERY"
            :ableChangeAmount="false"
          />
          <RowData key="daily-sallary"
            :objdata="paidableSallary.SI_DAILY_SALLARY"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="overtime"
            :objdata="paidableSallary.SI_OVERTIME"
            :ableChangeAmount="false"
          />
          <RowData key="reseller"
            :objdata="paidableSallary.SI_RESELL"
            :ableChangeAmount="false"
          />
          <!-- <RowData v-for="(data) in list.siOtherPaidableList" :key="data.type"
            :objdata="data"
            :ableDelete="true"
            :ableChangeName="true"
            :ableChangeAccount="true"
            :ableChangeAmount="true"
            @setData="setData($event)"
            @setAccount="setAccount($event)"
            @delete="confirmRemoveSetting($event)"
          />
          <RowData v-for="(data) in list.siOtherMutatableList" :key="data.type"
            :objdata="data"
            :ableDelete="true"
            :ableChangeName="true"
            :ableChangeAccount="true"
            :ableChangeAmount="true"
            @setData="setData($event)"
            @setAccount="setAccount($event)"
            @delete="confirmRemoveSetting($event)"
          /> -->
          <RowData v-for="(data) in list.siOtherList" :key="data.type"
            :objdata="data"
            :ableDelete="true"
            :ableChangeName="true"
            :ableChangeAccount="true"
            :ableChangeAmount="true"
            @setData="setData($event)"
            @setAccount="setAccount($event)"
            @delete="confirmRemoveSetting($event)"
          />
        </template>

        <template #foot>
          <tr style="border-top: 1px solid #AAA">
            <td class="text-left" colspan="5">
              <Button size="xs" color="primary" text="+" @click="addIncome" style="margin-right: 10px;" />
              <span><i>Tambah Penerimaan</i></span>
            </td>
          </tr>

          <!-- <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Total Penerimaan</label></td>
            <td class="text-left"></td>
            <td class="text-left">Rp.</td>
            <td class="text-right"><label>{{ list.totalincome.readable(0) }}</label></td>
            <td class="text-left"></td>
          </tr> -->
        </template>
      </TableList>
    </Col12>

    <Col12>
      <TableList>
        <template #head>
          <td width="300"><label>Potongan</label></td>
          <td><label>Mutasi Ke Akun</label></td>
          <td width="10"></td>
          <td width="120"></td>
          <td width="50"></td>
          <td width="50"></td>
        </template>

        <template #body>
          <RowData key="absent"
            :objdata="deductionSallary.SO_ABSENT"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="late"
            :objdata="deductionSallary.SO_LATE"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="sick"
            :objdata="deductionSallary.SO_SICK"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData key="sick"
            :objdata="deductionSallary.SO_ABSENT_LEAVE"
            :ableChangeAmount="true"
            @setData="setData($event)"
          />
          <RowData v-for="(data) in list.soOtherList" :key="data.type"
            :objdata="data"
            :ableDelete="true"
            :ableChangeName="true"
            :ableChangeAccount="true"
            :ableChangeAmount="true"
            @setData="setData($event)"
            @setAccount="setAccount($event)"
            @delete="confirmRemoveSetting($event)"
          />
        </template>

        <template #foot>
          <tr style="border-top: 1px solid #AAA">
            <td class="text-left" colspan="5">
              <Button size="xs" color="primary" text="+" @click="addDeduction" style="margin-right: 10px;" />
              <span><i>Tambah Potongan</i></span>
            </td>
          </tr>

          <!-- <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Total Potongan</label></td>
            <td class="text-left"></td>
            <td class="text-left">Rp.</td>
            <td class="text-right"><label>{{ list.totaldeduction.readable(0) }}</label></td>
            <td class="text-left"></td>
          </tr> -->
        </template>
      </TableList>
    </Col12>

    <Col12>
      <TableList>
        <template #head>
          <td width="300"></td>
          <td></td>
          <td width="10"></td>
          <td width="120"></td>
          <td width="50"></td>
        </template>

        <template #foot>
          <!-- <tr style="border-top: 1px solid #AAA">
            <td class="text-right"><label>Jumlah Dibayarkan</label></td>
            <td class="text-left"></td>
            <td class="text-left">Rp.</td>
            <td class="text-right"><label>{{ list.totalpayable.readable(0) }}</label></td>
            <td class="text-left"></td>
          </tr> -->
        </template>
      </TableList>
    </Col12>

    <SelectListDialog
      id="SelectAccountDialog"
      ref="SelectAccountDialog"
      :options="operationalAccountOptions"
      @select="setAccountAction($event)" />

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>