
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/Package/list`,
  meta: `Product/Package/listMeta`,
  auth: `Auth/model`,
})

computed.canEdit = function () {
  return this.auth.hasPermission('ACCESS_CATPACKAGE_M')
}

computed.canSeeAssetPrice = function () {
  return this.auth.hasPermission('ACCESS_CATPACKAGE_ASSETPRICE')
}

computed.canSeeSalePrice = function () {
  return this.auth.hasPermission('ACCESS_CATPACKAGE_SALEPRICE')
}

computed.canSeeStorePrice = function () {
  return this.auth.hasPermission('ACCESS_CATPACKAGE_STOREPRICE')
}

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/Package/setListOption`,
  reload: `Product/Package/fetchList`,
  pagePrev: `Product/Package/pagePrev`,
  pageNext: `Product/Package/pageNext`,
  deleteItem: `Product/Package/remove`,
  updateData: `Product/Package/update`,
})

methods.edit = function (id) {
  this.$router.push(`/product/package/${id}`)
}

methods.editCover = function (id) {
  this.$router.push(`/product/package/${id}/cover`)
}

methods.editValue = function (id, property, value) {
  this.updateData({
    id,
    data: { [property]: value },
  })
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="80" class="text-left">Foto</th>
        <th class="text-left">Nama Paket</th>
        <th width="80" class="text-right" v-if="canSeeAssetPrice">HB.<br>Toko</th>
        <th width="80" class="text-right" v-if="canSeeSalePrice">HJ.<br>ke Plg</th>
        <th width="80" class="text-right" v-if="canSeeStorePrice">HJ.<br>ke Toko</th>
        <!-- <th width="80" class="text-right">Komisi Reseller</th> -->
        <th width="40" class="text-right" v-if="canEdit"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">
            <img class="link" height="64" width="64"
              :src="item.cover"
              @click="editCover(item.id)" />
          </td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/product/package/${item.id}`">
              {{ item.name }}
            </router-link>
            <p class="subdata">{{ item.description }}</p>
          </td>
          <td class="text-right" v-if="canSeeAssetPrice">
            <TextFieldChangable v-if="canEdit"
              allow="0-9"
              :forceupper=true
              :value="item.price_asset_readable"
              @submit="editValue(item.id, `price_asset`, $event)"/>

              <span v-else>{{ item.price_asset_readable }}</span>
          </td>
          <td class="text-right bg-green" v-if="canSeeSalePrice">
            <TextFieldChangable v-if="canEdit"
              allow="0-9"
              :forceupper=true
              :value="item.price_unit_readable"
              @submit="editValue(item.id, `price_unit`, $event)"/>

              <span v-else>{{ item.price_unit_readable }}</span>
          </td>
          <td class="text-right bg-orange" v-if="canSeeStorePrice">
            <TextFieldChangable v-if="canEdit"
              allow="0-9"
              :forceupper=true
              :value="item.price_store_readable"
              @submit="editValue(item.id, `price_store`, $event)"/>

              <span v-else>{{ item.price_store_readable }}</span>
          </td>
          <!-- <td class="text-right bg-orange">
            <TextFieldChangable v-if="canEdit"
              allow="0-9"
              :forceupper=true
              :value="item.resell_commission_readable"
              @submit="editValue(item.id, `resell_commission`, $event)"/>

              <span v-else>{{ item.resell_commission_readable }}</span>
          </td> -->
          <td class="text-right" v-if="canEdit">
            <TButtonDelete @click="$refs.DeleteDialog.confirm(item.id, item.name)" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>