<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'

const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Store/loadingState`,
    profile: `Store/profile`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchStoreData: `Store/fetch`,
  updateStoreConfig: `Store/updateConfig`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) {
    this.isProfileLocked = false
  }

  if (mode === `revert`) {
    this.reset()
  }
}

methods.reset = function() {
  const setting = this.profile.setting
  this.input = {
    TAX_ITEM: setting.TAX_ITEM,
    TAX_SERVICE: setting.TAX_SERVICE,
  }
  this.isProfileLocked = true
}

methods.submit = async function() {
  await this.updateStoreConfig(this.input)
  this.reset()
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="PENGATURAN PAJAK"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)"
  >
    <template #header>Setting Profil Toko</template>

    <template #content>
      <Row v-if="isProfileLocked">
        <Col12>
          <label>Pajak Barang</label>
          <p>{{ input.TAX_ITEM }} %</p>
        </Col12>

        <Col12>
          <label>Pajak Service</label>
          <p>{{ input.TAX_SERVICE }} %</p>
        </Col12>
      </Row>
      <FormGroup v-else>
        <TextField
          label="Pajak Barang"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.TAX_ITEM">
          <template #after>%</template>
        </TextField>

        <TextField
          label="Pajak Service"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.TAX_SERVICE">
          <template #after>%</template>
        </TextField>
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="submit"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>