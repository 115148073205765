
<script>

/**
 * Components
 *
 */
const components = {
  //
}

/**
 * Static Data
 *
 */
const props = {
  data: {
    type: Object,
    default: () => ({ name: ``, value: 0, type: `` })
  },
  editable: {
    type: Boolean,
    default: false,
  },
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    //
  }
}

/**
 * Computed
 *
 */
const computed = {}

computed.isIncome = function () {
  return this.data.type.startsWith(`SI_`)
}

computed.isDeduction = function () {
  return this.data.type.startsWith(`SO_`)
}

computed.name = function() {
  if (this.data.name) return this.data.name

  if (this.isIncome) {
    return `<i class="subdata">Penerimaan Lain</i>`
  } else if (this.isDeduction) {
    return `<i class="subdata">Potongan Lain</i>`
  } else {
    return ``
  }
}

computed.amount = function() {
  return this.data.value.readable(0)
}

computed.separator = function () {
  return this.name && `:`
}

/**
 * Methods
 *
 */
const methods = {}

methods.submit = async function (value) {
  this.$emit(`submit`, value)
}

/**
 * Watchers
 *
 */
const watch = {}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  //
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  //
}

export default {
  components,
  props,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <tr>
    <td class="text-left">
      <span v-html="name"></span>
    </td>

    <td class="text-left">
      <span v-html="separator"></span>
    </td>

    <td class="text-right">
      <TextFieldChangable v-if="editable"
        placeholder="0"
        :value="amount"
        @submit="submit"
      />
      <span v-else>{{ amount }}</span>
    </td>
  </tr>
</template>