
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    input: {},
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `OpPurchase/Transaction/data`,
  processing: `OpPurchase/Transaction/loadingState`,
  opPurchaseAccountOptions: `OpPurchase/Account/options`,
  cashAccountOptions: `CashAccount/options`,
})

computed.validate = function () {
  return !!this.input.category_id
    && !!this.input.cashaccount_id
    && (!!this.input.amount && this.input.amount > 0)
    && !this.processing
}

computed.dialogTitle = function () {
  if (this.input.transaction_type === 1) return `KREDIT`
  else if (this.input.transaction_type === -1) return `DEBIT`
  else return `TAMBAH RECORD`
}

/**
 * Methods
 *
 */
const methods = mapActions({
  reloadSummarize: `OpPurchase/Summarize/fetchData`,
  fetch: `OpPurchase/Transaction/fetchData`,
  entry: `OpPurchase/Transaction/update`,
})

methods.reset = function() {
  this.input = Object.assign({ transaction_type: 0 }, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  return this.fetch(null).then(this.show)
}

methods.addrecord = async function() {
  const id = this.id
  const data = this.input

  data.amount = data.amount * this.input.transaction_type
  const success = await this.entry({ id, data })

  this.reloadSummarize()
  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord">
    <FormGroup v-if="input.transaction_type === 0">
      <SelectField
        label="Akun *"
        defaultLabel="-- Pilih Akun --"
        :options="opPurchaseAccountOptions"
        v-model="input.category_id" />
      <div class="col-xs-6">
        <Button
          :block="true"
          text="Kredit"
          color="success"
          :disabled="!input.category_id"
          @click="input.transaction_type = 1" />
      </div>
      <div class="col-xs-6">
        <Button
          :block="true"
          text="Debit"
          color="danger"
          :disabled="!input.category_id"
          @click="input.transaction_type = -1" />
      </div>
    </FormGroup>

    <FormGroup v-else>
      <SelectField v-if="input.transaction_type === 1"
        label="Kredit masuk ke rekening *"
        :options="cashAccountOptions"
        v-model="input.cashaccount_id" />

      <SelectField v-if="input.transaction_type === -1"
        label="Debit dari rekening *"
        :options="cashAccountOptions"
        v-model="input.cashaccount_id" />

      <TextField
        label="Jumlah Dibayar *"
        sublabel="Nilai harus lebih besar dari 0"
        :forceupper=true
        v-model="input.amount" />

      <TextField
        label="Catatan"
        v-model="input.note" />

      <TextField
        label="Ref Pembayaran"
        v-model="input.approval_code" />
    </FormGroup>
  </EntryDialog>
</template>