
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([
      `limit=${opt.pagelimit}`,
      `skip=` + (opt.pagenumber - 1) * opt.pagelimit,
    ])

    if (opt.format) query.push(`format=${opt.format}`)
    if (opt.brand) query.push(`brand=${opt.brand}`)
    if (opt.category) query.push(`category=${opt.category}`)
    if (opt.supplier) query.push(`supplier=${opt.supplier}`)
    if (opt.search) query.push(`search=${opt.search}`)

    return query.string
  },

  data({ selectedObject: item }) {
    return {
      ...item,
      cover: item.cover || `/img/defaults/product.jpg`,
      get price_store() { return this.price_unit - this.store_discount },
    }
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      cover: item.cover || `/img/defaults/product.jpg`,
      get price_store() { return this.price_unit - this.store_discount },
      get price_store_readable() { return this.price_store.toLocaleString() },
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get price_unit_readable() { return this.price_unit.toLocaleString() },
      get resell_commission_readable() { return this.resell_commission.toLocaleString() },
    }))
  }
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/item?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchOptions({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/item?format=options`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_options`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/product/item/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async update({ commit, dispatch, rootState }, { id, data }) {
    commit(`loading_mode`, 1)

    if (!id) id = ``

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      await dispatch(`fetchData`, result.id)

      if (id) alert.success(`Data berhasil diupdate`)
      else alert.success(`Data berhasil ditambahkan`)

      return result.id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATITEM_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/product/item/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async updateCover({ commit, dispatch, rootState }, { id, media }) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)

      alert.success(`Berhasil mengganti foto`)
      await dispatch(`fetchData`, id)

      return id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATITEM_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return new Promise(resolve => {
      const params = new FormData()

      media.toBlob(blob => {
        params.append(`media`, blob)

        api.put(`/product/item/${id}/cover`, params)
          .then(r => onsuccess(r.data))
          .catch(onerror)
          .then(resolve)
      })
    })
  },

  async remove({ commit, rootState }, id) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_options`)
      // await dispatch(`fetchList`)
      alert.success(`Data berhasil dihapus`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATITEM_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.delete(`/product/item/${id}`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}