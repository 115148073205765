<script>
const props = {
  type: { type: String, default: `single` },
  title: { type: String },
  href: { type: String, default: `` },
  icon: { type: String, default: `` },
  child: { type: Array, default: () => [] },
}

const data = () => ({
  isOpen: true,
})

const computed = {
  isActive(href) {
    if (this.href) {
      return this.$route.path === this.href
    } else {
      return this.child.map(i => i.href).includes(this.$route.path)
    }
  },
  liclass() {
    return { open: this.isOpen }
  },
  aclass() {
    if (this.type === 'single') {
      return {
        active: this.isActive,
      }
    } else if (this.type === 'group') {
      return {
        active: this.isActive,
        'nav-submenu': true,
      }
    } else {
      return {}
    }
  },
}

const mounted = function() {
  this.isOpen = this.isActive
}

export default {
  props,
  data,
  computed,
  mounted,
}
</script>

<template>
  <div>
    <li v-if="type === `heading`" class="nav-main-heading">
      <span class="sidebar-mini-hide">{{ title }}</span>
    </li>

    <li v-if="type === `single`">
      <router-link :to="href" :class="[aclass]">
        <i :class="`si si-${icon}`"></i>
        <span class="sidebar-mini-hide">{{ title }}</span>
      </router-link>
    </li>

    <li v-if="type === `group`" :class="[liclass]">
      <a href="#" :class="[aclass]" @click="isOpen = !isOpen">
        <i :class="`si si-${icon}`"></i>
        <span class="sidebar-mini-hide">{{ title }}</span>
      </a>

      <ul>
        <li v-for="(item, index) in child" :key="index">
          <router-link
            :to="item.href"
            :class="[{ active: $route.path === item.href }]"
            v-html="item.title"
          />
        </li>
      </ul>
    </li>
  </div>
</template>