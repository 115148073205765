<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import Profile from './Profile'
import TaxSetting from './TaxSetting'
import PrefixSetting from './PrefixSetting'

const components = {
  layout,
  Profile,
  TaxSetting,
  PrefixSetting,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    loadingState: 0,
  }
}

/**
 * Computed
 *
 */
const computed = {
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchStoreData: `Store/fetch`,
})

/**
 * Event OnMounted
 *
 */
const mounted = function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Pengaturan Toko`, `General` ])

  this.loadingState += 1
  return this.fetchStoreData()
    .then(() => this.loadingState -= 1)
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <div v-if="!loadingState">
      <Row style="margin-bottom: 15px">
        <Profile />
        <TaxSetting />
      </Row>
      <Row style="margin-bottom: 15px">
        <PrefixSetting />
      </Row>
    </div>
  </layout>
</template>