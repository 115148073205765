<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Service/loadingState`,
    data: `Product/Service/data`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Service/fetchData`,
  updateData: `Product/Service/update`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.goback = function() {
  this.$router.push(`/product/service`)
}

methods.reset = function() {
  this.profile = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateProfile = async function() {
  const data = this.profile
  data.store_discount = data.price_unit - data.price_store

  await this.updateData({
    id: this.data.id,
    data,
  })

  this.goback()
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.profile.name))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Layanan`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <Col12>
        <h2 class="content-heading">Setting Data Layanan</h2>
      </Col12>

      <BlockForm title="DETAIL LAYANAN"
        :width=6
        :loadingState="isLoading"
        :editbutton=false
        :lockedState=false>
        <template #content>
          <FormGroup>
            <TextField
              label="Nama Layanan"
              width="8"
              :forceupper=true
              v-model="profile.name" />

            <TextArea
              label="Deskripsi"
              width="12"
              :rows=5
              :forceupper=true
              v-model="profile.description" />
          </FormGroup>
        </template>
      </BlockForm>

      <BlockForm title="SETTING HARGA"
        :width=6
        :loadingState="isLoading"
        :editbutton=false
        :lockedState=false>
        <template #content>
          <FormGroup>
            <TextField
              label="Harga Jasa Teknisi"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.worker_commission" >
              <template #before>Rp.</template>
            </TextField>

            <TextField
              label="Harga Jual ke Pelanggan"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.price_unit" >
              <template #before>Rp.</template>
            </TextField>

            <TextField
              label="Harga Jual ke Toko Lain"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.price_store" >
              <template #before>Rp.</template>
            </TextField>


            <!-- <TextField
              label="Komisi Penjualan untuk Reseller"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.resell_commission" >
              <template #before>Rp.</template>
            </TextField> -->
          </FormGroup>
        </template>

        <template #footer>
          <ButtonFormSet
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="goback"
          />
        </template>
      </BlockForm>
    </Row>
  </layout>
</template>