
<script>
export default {
  props: {
    icon: String,
    text: String,
    color: String,
  },
}
</script>

<template>
  <Button class="pull-right push-10-l"
    :icon="icon"
    :color="color"
    :text="text"
    @click="$emit(`click`)"
  />
</template>