
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
const components = {
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    current_item_id: null,
    filter_month: moment().format(`MM`),
    filter_year: moment().format(`YYYY`),
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Finance/DailyJournal/list`,
  meta: `Finance/DailyJournal/listMeta`,
  dateMonth: `dateMonth`,
  dateYear: `dateYear`,
})

computed.totalGroup = function () {
  const defaultValue = {
    expnd_paid: 0,
    sales_paid: 0,
    cost_transaction: 0,
    sales: 0,
    profit: 0,
    cost_modal: 0,
    cost_taxitem: 0,
    cost_taxserv: 0,
    cost_worcomm: 0,
    cost_rescomm: 0,
    delivery: 0,
    unpaid_transaction: 0,
    store_purchase: 0,
    other_income: 0,
    get income() { return this.profit
      + this.cost_transaction
      + this.cost_taxitem
      + this.cost_taxserv
      + this.cost_worcomm
      + this.cost_rescomm
      + this.delivery
    }
  }

  return this.list.reduce((total, group) => {
    total.expnd_paid += group.total_expnd_paid
    total.sales_paid += group.total_sales_paid
    total.cost_transaction += group.total_cost_transaction
    total.sales += group.total_sales
    total.cost_modal += group.total_cost_modal
    total.profit += group.total_profit
    total.cost_taxitem += group.total_cost_taxitem
    total.cost_taxserv += group.total_cost_taxserv
    total.cost_worcomm += group.total_cost_worcomm
    total.cost_rescomm += group.total_cost_rescomm
    total.delivery += group.total_delivery
    total.unpaid_transaction += group.total_unpaid_transaction
    total.store_purchase += group.total_store_purchase
    total.other_income += group.total_other_income
    return total
  }, defaultValue)
}


/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Finance/DailyJournal/setListOption`,
  reload: `Finance/DailyJournal/fetchList`,
  pagePrev: `Finance/DailyJournal/pagePrev`,
  pageNext: `Finance/DailyJournal/pageNext`,
  deleteItem: `Finance/DailyJournal/remove`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_month: function (n, o) {
    n !== o && this.setListOption({ month: n })
  },
  filter_year: function (n, o) {
    n !== o && this.setListOption({ year: n })
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }

  if (this.meta.filter.month) {
    this.filter_month = this.meta.filter.month
  }

  if (this.meta.filter.year) {
    this.filter_year = this.meta.filter.year
  }
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }

  .text-90 {
    font-size: 90%;
  }
</style>

<template>
  <Row>

    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li>
                <SelectFieldChangable
                  :defaultValue="false"
                  :value="dateMonth.labelFromCode[filter_month]"
                  :formValue="filter_month"
                  :options="dateMonth.options"
                  @submit="filter_month = $event"
                />
              </li>
              <li>
                <SelectFieldChangable
                  :defaultValue="false"
                  :value="filter_year"
                  :formValue="filter_year"
                  :options="dateYear.options"
                  @submit="filter_year = $event"
                />
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </Col6>

    <Col6>
      <table class="no-style pull-right">
        <tr>
          <td width="240"></td>
          <td width="10"></td>
          <td></td>
          <td width="140"></td>
        </tr>
        <tr>
          <td><label>TOTAL PEMBAYARAN SUPPLIER</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ totalGroup.expnd_paid.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL PENJUALAN</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ totalGroup.sales.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL PENDAPATAN</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ totalGroup.income.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL BELANJA TOKO</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ totalGroup.store_purchase.readable() }}</td>
        </tr>
      </table>
    </Col6>

    <Col12>
      <br style="clear: both">
    </Col12>

    <TableList style="overflow-x: scroll" class="text-90">
      <template #head>
        <th width="40" class="text-center">#</th>
        <th class="text-left">Tanggal</th>
        <th width="140" class="text-right">Pembayaran Supplier</th>
        <th width="140" class="text-right">Total Penjualan</th>
        <th width="140" class="text-right">Modal Kembali</th>
        <th width="140" class="text-right">Potongan Transaksi DC/CC</th>
        <th width="140" class="text-right">Ht. Pajak Penjualan</th>
        <th width="140" class="text-right">Ht. Pajak Layanan</th>
        <th width="140" class="text-right">Service Cost</th>
        <th width="140" class="text-right">Komisi Reseller</th>
        <th width="140" class="text-right">Delivery</th>
        <th width="140" class="text-right">Pendapatan Bruto</th>
        <th width="140" class="text-right">Trx.Belum Dibayar</th>
        <th width="140" class="text-right">Belanja Toko</th>
        <th width="140" class="text-right">Pemasukan Lain</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.date_readable }}</td>
          <td class="text-right custom-bg-blue-light">{{ item.total_expnd_paid.readable(`-`) }}</td>
          <td class="text-right custom-bg-green-light">{{ item.total_sales.readable(`-`) }}</td>
          <td class="text-right custom-bg-green-light">{{ item.total_cost_modal.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_cost_transaction.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_cost_taxitem.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_cost_taxserv.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_cost_worcomm.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_cost_rescomm.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_delivery.readable(`-`) }}</td>
          <td class="text-right custom-bg-orange-light">{{ item.total_profit.readable(`-`) }}</td>
          <td class="text-right custom-bg-red-light">{{ item.total_unpaid_transaction.readable(`-`) }}</td>
          <td class="text-right custom-bg-red-light">{{ item.total_store_purchase.readable(`-`) }}</td>
          <td class="text-right custom-bg-red-light">{{ item.total_other_income.readable(`-`) }}</td>
        </tr>
        <tr class="custom-bg-primary-light">
          <td class="text-left"></td>
          <td class="text-left"><label class="text-90">Total</label></td>
          <td class="text-right custom-bg-blue-darker">{{ totalGroup.expnd_paid.readable() }} </td>
          <td class="text-right custom-bg-green-darker">{{ totalGroup.sales.readable() }} </td>
          <td class="text-right custom-bg-green-darker">{{ totalGroup.cost_modal.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.cost_transaction.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.cost_taxitem.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.cost_taxserv.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.cost_worcomm.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.cost_rescomm.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.delivery.readable() }} </td>
          <td class="text-right custom-bg-orange-darker">{{ totalGroup.profit.readable() }} </td>
          <td class="text-right custom-bg-red-darker">{{ totalGroup.unpaid_transaction.readable() }} </td>
          <td class="text-right custom-bg-red-darker">{{ totalGroup.store_purchase.readable() }} </td>
          <td class="text-right custom-bg-red-darker">{{ totalGroup.other_income.readable() }} </td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>