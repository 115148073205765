
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    description: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `OpPurchase/Account/list`,
  meta: `OpPurchase/Account/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `OpPurchase/Account/setListOption`,
  reload: `OpPurchase/Account/fetchList`,
  deleteItem: `OpPurchase/Account/remove`,
  pagePrev: `OpPurchase/Account/pagePrev`,
  pageNext: `OpPurchase/Account/pageNext`,
})

methods.edit = function (id) {
  this.$emit(`editrequest`, id)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <div>
    <Row>
      <TableOptions
        :nrecord="meta.nrecord"
        :npage="meta.npage"
        :curpage="meta.curpage"
        :searchValue="meta.filter.search"
        @nextpage="pageNext"
        @prevpage="pagePrev"
        @showopt="showopt"
        @search="search"
      />
      <TableOptionsDialog ref="TableOptionsDialog"
      />

      <TableList>
        <template #head>
          <th width="40" class="text-center">#</th>
          <th class="text-left">Nama Akun</th>
          <th width="140" class="text-left">Jenis Akun</th>
          <th width="140" class="text-left">Rekening</th>
          <th width="200" class="text-left">Ref. Karyawan</th>
          <th width="140" class="text-left">Kredit Setiap<br>Awal Bulan</th>
          <th width="10" class="text-right"></th>
          <th width="140" class="text-right">Jumlah Kredit</th>
          <th width="40" class="text-right"></th>
        </template>

        <template #body>
          <tr v-for="(item, index) in list" :key="item.id">
            <td class="text-center" v-html="index + meta.curstart"></td>
            <td class="text-left">
              <span class="link underlined" @click="edit(item.id)">{{ item.name }}</span>
            </td>
            <td class="text-left">{{ item.category_readable }}</td>
            <td class="text-left">
              <span class="link underlined" @click="edit(item.id)">{{ item.account_name }}</span>
            </td>
            <td class="text-left">{{ item.employee_name }}</td>
            <td class="text-left">
              <span class="link underlined" @click="edit(item.id)">{{ item.counter_formula_readable }}</span>
            </td>
            <td class="text-right">Rp.</td>
            <td class="text-right">{{ item.balance.readable(0) }}</td>
            <td class="text-right">
              <TButtonDelete v-if="item.editable" @click="$refs.DeleteDialog.confirm(item.id, item.name)" />
            </td>
          </tr>
        </template>
      </TableList>

      <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
    </Row>
  </div>
</template>