
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const filterDateFrom = moment().format(`DD/MM/YYYY`)
const filterDateTo = moment().format(`DD/MM/YYYY`)
const data = function () {
  return {
    filter_datefrom: filterDateFrom,
    filter_dateto: filterDateTo,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `SalesReport/list`,
  meta: `SalesReport/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `SalesReport/setListOption`,
  reload: `SalesReport/fetchList`,
  pagePrev: `SalesReport/pagePrev`,
  pageNext: `SalesReport/pageNext`,
  deleteItem: `SalesReport/remove`,
})

methods.edit = function (id) {
  this.$router.push(`/rep/sales-transaction/${id}`)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_datefrom: {
    handler(value) {
      this.setListOption({ datefrom: value })
    }
  },
  filter_dateto: {
    handler(value) {
      this.setListOption({ dateto: value })
    }
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }

  this.setListOption({ datefrom: this.filter_datefrom, dateto: this.filter_dateto })
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>

    <Col12>
      <ul class="list-inline-style">
        <li><label>PERIODE :</label></li>
        <li><SelectDateChangable v-model="filter_datefrom"></SelectDateChangable></li>
        <li>-</li>
        <li><SelectDateChangable v-model="filter_dateto"></SelectDateChangable></li>
      </ul>
    </Col12>

    <Col12>
      <br>
    </Col12>

    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="120" class="text-left">Tanggal Order</th>
        <th width="140" class="text-left">Petugas</th>
        <th class="text-left">Record</th>
        <th width="100" class="text-left">Status Order</th>
        <th width="100" class="text-right">Penjualan</th>
        <th width="40" class="text-right"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.date_ordered_readable }}</td>
          <td class="text-left">{{ item.employee_name }}</td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/rep/sales-transaction/${item.id}`">
              {{ item.record_id }}
            </router-link>
            <label class="label label-success push-10-l" v-if="item.locked">Locked</label>
            <label class="label label-warning push-10-l" v-else>Open</label>
            <p class="subdata">Customer: {{ item.customer_name || 'Tidak terdaftar' }}</p>
          </td>
          <td class="text-left">{{ item.status_order }}</td>
          <td class="text-right">
            {{ item.total_sales_readable }}
          </td>
          <td class="text-left">
            <label class="label label-danger" v-if="item.status_paid">Lunas</label>
          </td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>