
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import SetUsernameDialog from './Entry-Account-UsernameDialog'
import SetPasswordDialog from './Entry-Account-PasswordDialog'
const components = {
  layout,
  SetUsernameDialog,
  SetPasswordDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Employee/loadingState`,
    employeeid: `Employee/id`,
    account: `Employee/account`,
    storeCode: `Auth/storecode`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Employee/fetchData`,
  updateData: `Employee/updateAccount`,
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.input = {}
  this.isProfileLocked = true

  if (this.account) {
    this.input.email = this.account.email
    this.input.password = this.account.password
  }
}

methods.updateProfile = async function() {
  await this.updateData(this.input)
  this.reset()
}

methods.resetUsername = async function() {
  this.$refs.SetUsernameDialog.show()
}

methods.setUsername = async function(email) {
  await this.updateData({ email })

  this.$refs.SetUsernameDialog.dismiss()
  this.reset()
}

methods.resetPassword = async function() {
  this.$refs.SetPasswordDialog.show()
}

methods.setPassword = async function(password) {
  await this.updateData({ password })

  this.$refs.SetPasswordDialog.dismiss()
  this.reset()
}

methods.setPermission = async function() {
  this.$router.push(`/employee/${this.employeeid}/permission`)
}

/**
 * Watch
 *
 */
const watch = {
  account: {
    deep: true,
    handler(n, o) {
      this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="AKUN DASHBOARD"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    :editbutton=false
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)" >

    <template #content v-if="account">
      <table class="table table-condensed table-borderless">
        <tbody>

        <tr>
          <td width="28%">Email</td>
          <td width="2%">:</td>
          <td width="60%">
            {{ account.email }}
            <span class="link push-10-l" @click="resetUsername"><fa-edit t="Ganti" /></span>
          </td>
        </tr>

        <tr>
          <td>Status</td>
          <td>:</td>
          <td><Label c="success">Active</Label></td>
        </tr>
        <tr>
          <td colspan="3"><span class="link" @click="resetPassword">Reset password</span></td>
        </tr>
        <tr>
          <td colspan="3"><span class="link" @click="setPermission">Setting permission</span></td>
        </tr>
        </tbody>
      </table>

      <SetUsernameDialog ref="SetUsernameDialog" @submit="setUsername" />
      <SetPasswordDialog ref="SetPasswordDialog" @submit="setPassword" />
    </template>

    <template #content v-else>
      <p v-if="isProfileLocked">
        <i>
          Karyawan ini belum memiliki akses.
          <span class="link" @click="profileMode(`edit`)">Berikan akses</span>
        </i>
      </p>

      <FormGroup v-else>
        <TextField
          label="Email"
          :forceupper=true
          :disabled=isProfileLocked
          allow="a-zA-Z0-9@.\-"
          v-model="input.email">
        </TextField>

        <TextField
          label="Set Password"
          width="8"
          :disabled=isProfileLocked
          v-model="input.password" />
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="updateProfile"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>