
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/SOpname/list`,
  meta: `Product/SOpname/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/SOpname/setListOption`,
  reload: `Product/SOpname/fetchList`,
  pagePrev: `Product/SOpname/pagePrev`,
  pageNext: `Product/SOpname/pageNext`,
  deleteItem: `Product/SOpname/remove`,
})

methods.edit = function (id) {
  this.$router.push(`/inventory/opname/${id}`)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="80" class="text-left">Tanggal</th>
        <th width="140" class="text-left">Petugas</th>
        <th class="text-left">Record</th>
        <th width="80" class="text-center">Status</th>
        <th width="100" class="text-right">Kelebihan</th>
        <th width="100" class="text-right">Kehilangan</th>
        <th width="40" class="text-right"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.date_readable }}</td>
          <td class="text-left">{{ item.employee_name }}</td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/inventory/opname/${item.id}`">
              {{ item.record_id }}
            </router-link>
            <p class="subdata">{{ item.note || 'Tidak ada keterangan' }}</p>
          </td>
          <td class="text-center">
            <label class="label label-success" v-if="item.locked">Locked</label>
            <label class="label label-warning" v-else>Open</label>
          </td>
          <td class="text-right bg-green">{{ item.overload_value_readable }}</td>
          <td class="text-right bg-orange">{{ item.missing_value_readable }}</td>
          <td class="text-right">
            <TButtonDelete @click="$refs.DeleteDialog.confirm(item.id, item.name)" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>