
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    ordertypes: [
      { label: `Semua`, value: `` },
      { label: `Draft`, value: `DRAFT` },
      { label: `Diorder`, value: `ORDERED` },
      { label: `Terkirim`, value: `SENT` },
      { label: `Belum Lunas`, value: `UNPAID` },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Sales/list`,
  meta: `Sales/listMeta`,
  infoTotalOmzet: `Sales/infoTotalOmzet`,
  allTrxInfo: `Store/Info/transaction`,
  resellerOptions: `Employee/options`,
})

computed.ordertypesLabel = function() {
  const opt = this.ordertypes.find(i => i.value === this.meta.filter.ordertypes)
  return opt.label
}

computed.resellerLabel = function() {
  const opt = this.resellerOptions.find(i => i.value === this.meta.filter.reseller)
  return opt ? opt.label : `Semua`
}

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Sales/setListOption`,
  reload: `Sales/fetchList`,
  pagePrev: `Sales/pagePrev`,
  pageNext: `Sales/pageNext`,
  deleteItem: `Sales/remove`,
  setFilterDateFrom: `Sales/setFilterDateFrom`,
  setFilterDateTo: `Sales/setFilterDateTo`,
  setFilterOrderTypes: `Sales/setFilterOrderTypes`,
  setFilterReseller: `Sales/setFilterReseller`,
  fetchStoreInfo: `Store/Info/fetch`,
})

methods.edit = function (id) {
  this.$router.push(`/sales/${id}`)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.fetchStoreInfo([`transaction`])
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }

  if (!this.meta.filter.datefrom) {
    const currentday = moment().format(`DD/MM/YYYY`)
    this.setListOption({
      datefrom: currentday,
      dateto: currentday,
    })
  }

  if (!this.meta.filter.ordertypes) {
    this.setListOption({ ordertypes: `` })
  }
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>

    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>STATUS ORDER</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li>
                <SelectFieldChangable
                  :value="ordertypesLabel"
                  :formValue="meta.filter.ordertypes"
                  :options="ordertypes"
                  @submit="setFilterOrderTypes" />
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li><SelectDateChangable :value="meta.filter.datefrom" @input="setFilterDateFrom" /></li>
              <li>-</li>
              <li><SelectDateChangable :value="meta.filter.dateto" @input="setFilterDateTo" /></li>
            </ul>
          </td>
        </tr>
        <tr>
          <td><label>RESELLER</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li>
                <SelectFieldChangable
                  :value="resellerLabel"
                  :formValue="meta.filter.reseller"
                  :options="resellerOptions"
                  @submit="setFilterReseller" />
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </Col6>

    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>Tranasksi Belum Dibayar</label></td>
          <td><label>:</label></td>
          <td>
            <label class="no-bold">
              {{ allTrxInfo.unpaid_tx_amount.readable(0) }} /
              {{ allTrxInfo.unpaid_tx_count.readable(0) }} Transaksi
            </label>
          </td>
        </tr>
        <tr>
          <td><label>Total Omzet</label></td>
          <td><label>:</label></td>
          <td>
            <label class="no-bold"> {{ infoTotalOmzet.readable(0) }}</label>
          </td>
        </tr>
      </table>
    </Col6>

    <Col12>
      <br>
    </Col12>

    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="120" class="text-left">Tanggal Order</th>
        <th width="140" class="text-left">Petugas</th>
        <th class="text-left">Record</th>
        <th width="100" class="text-left">Status Order</th>
        <th width="100" class="text-right">Penjualan</th>
        <th width="40" class="text-right"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.date_ordered_readable }}</td>
          <td class="text-left">{{ item.employee_name }}</td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/sales/${item.id}`">
              {{ item.record_id }}
            </router-link>
            <label class="label label-success push-10-l" v-if="item.locked">Locked</label>
            <label class="label label-warning push-10-l" v-else>Open</label>
            <p class="subdata">Customer: {{ item.customer_name || 'Tidak terdaftar' }}</p>
            <p class="subdata">Reseller: {{ item.reseller_name || 'Tanpa reseller' }}</p>
          </td>
          <td class="text-left">{{ item.status_order }}</td>
          <td class="text-right">
            {{ item.total_sales_readable }}
          </td>
          <td class="text-left">
            <label class="label label-danger" v-if="item.status_paid">Lunas</label>
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>