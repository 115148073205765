<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Supplier/loadingState`,
    data: `Product/Supplier/data`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Supplier/fetchData`,
  updateData: `Product/Supplier/update`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.profile = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.goback = function() {
  this.$router.push(`/supplier`)
}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.data.id,
    data: this.profile,
  })

  this.goback()
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.profile.name))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Barang`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <Col12>
        <h2 class="content-heading">Setting Data Supplier</h2>
      </Col12>

      <BlockForm title="DETAIL SUPPLIER"
        :width=6
        :loadingState="isLoading"
        :editbutton=false
        :lockedState=false>
        <template #content>
          <FormGroup>
            <TextField
              label="Nama Supplier"
              width="8"
              :forceupper=true
              v-model="profile.name" />

            <TextField
              label="Asal Kota Pengiriman"
              width="8"
              :forceupper=true
              v-model="profile.city" />

            <TextField
              label="Alamat"
              width="12"
              :forceupper=true
              v-model="profile.address" />

            <TextField
              label="Nama Kontak Person"
              width="8"
              :forceupper=true
              v-model="profile.cp_name" />

            <TextField
              label="Nomor Kontak Person"
              width="8"
              :forceupper=true
              v-model="profile.cp_phone" />

            <TextField
              label="Nomor Rekening"
              width="8"
              :forceupper=true
              v-model="profile.account_bank_no" />

            <TextField
              label="Nama Bank"
              width="4"
              :forceupper=true
              v-model="profile.account_bank" />
          </FormGroup>
        </template>

        <template #footer>
          <ButtonFormSet
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="goback"
          />
        </template>
      </BlockForm>
    </Row>
  </layout>
</template>