<script>
export default {
  props: {
    w: {
      type: null,
      default: undefined
    },
    t: {
      type: String,
      default: ``,
    }
  },
  computed: {
    style() {
      return {
        width: this.w !== undefined ? this.w + `px` : `default`,
      }
    },
  }
}
</script>

<template>
  <i class="fa fa-phone" :style="style" data-toggle="tooltip" :title="t" />
</template>