
import QueryString from './query-string'

/**
 * State
 *
 */
const state = {
  loaded: false,
  alert: null,
  message: ``,

  selectedObject: {},
  defaultObject: {},

  list: [],
  listVersion: 0,
  listOptions: {
    format: `simple`,
    pagenumber: 1,
    pagelimit: 10,
  },
  nrecord: 0,
  selectedList: [],

  options: [],
}

/**
 * Getters
 *
 */
const getters = {
  loadingState: ({ loaded }) => loaded > 0,

  errorState: ({ alert }) => alert,

  errorMessage: ({ message }) => message,

  data: ({ selectedObject: item}) => item,

  list: ({ list }) => list,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([
      `limit=${opt.pagelimit}`,
      `skip=` + (opt.pagenumber - 1) * opt.pagelimit,
    ])

    if (opt.format) query.push(`format=${opt.format}`)
    if (opt.search) query.push(`search=${opt.search}`)

    return query.string
  },

  listMeta: (state) => ({
    version: state.listVersion,
    nrecord: state.nrecord,
    pagelimit: state.listOptions.pagelimit,
    curlength: state.list.length,
    curpage: state.listOptions.pagenumber,
    filter: state.listOptions,
    get curstart() {
      if (this.nrecord < 1) return 0
      return (this.curpage - 1) * this.pagelimit + 1
    },
    get curend() {
      if (this.nrecord < 1) return 0
      return (this.curpage - 1) * this.pagelimit + this.curlength
    },
    get npage() {
      if (this.nrecord < 1) return this.curpage
      return Math.ceil(this.nrecord / this.pagelimit)
    },
  }),

  options(state) {
    return state.options.map(item => ({
      value: item.id,
      label: item.name,
    }))
  },
}

/**
 * Actions
 *
 */
const actions = {
  setListOption({ commit, state: { listOptions } }, params) {
    commit(`set_list_option`, { ...listOptions, ...params})
    commit(`update_list_version`)
  },

  pagePrev({ commit, state }) {
    if (state.listOptions.pagenumber <= 1) return false
    commit(`set_page`, state.listOptions.pagenumber - 1)
    commit(`update_list_version`)
  },

  pageNext({ commit, getters }) {
    const list = getters.listMeta
    if (list.npage <= list.curpage) return false
    commit(`set_page`, list.curpage + 1)
    commit(`update_list_version`)
  },

  async refreshData({ dispatch, state }) {
    if (!state.selectedObject.id) return 0

    dispatch(`fetchData`, state.selectedObject.id)
  },

  select({ commit }, data) {
    return data && commit(`select`, data)
  },

  deselect({ commit }, data) {
    return data && commit(`deselect`, data)
  },

  filterListSelected({ commit, state }, data) {
    const { list, selectedList } = state
    const filtered = list.filter(({ id }) => selectedList.includes(id))

    commit(`set_list`, filtered)
  },

  resetListSelected({ commit }) {
    commit(`clear_selected_list`)
    commit(`update_list_version`)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  loading_mode(state, mode) {
    mode = (mode) ? 1 : -1
    state.loaded += mode
  },

  dissmiss_alert(state) {
    state.message = ``
    state.alert = null
  },

  show_error(state, message) {
    state.message = message
    state.alert = `error`
  },

  select(state, data) {
    return !state.selectedList.includes(data)
      && state.selectedList.push(data)
  },

  deselect(state, data) {
    const index = state.selectedList.indexOf(data)
    return index >= 0 && state.selectedList.splice(index, 1)
  },

  set_nrecord(state, value) {
    state.nrecord = value
  },

  set_page(state, page) {
    state.listOptions.pagenumber = page
  },

  set_list_option(state, params) {
    state.listOptions = params
  },

  update_list_version(state) {
    state.listVersion += 1
  },

  set_list(state, list) {
    state.list = list
  },

  clear_list(state) {
    state.list = []
  },

  clear_selected_list(state) {
    state.selectedList = []
  },

  set_selected_object(state, data) {
    state.selectedObject = data
  },

  clear_selected_object(state) {
    state.selectedObject = state.defaultObject
  },

  set_options(state, options) {
    state.options = options
  },

  clear_options(state) {
    state.options = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}