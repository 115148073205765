<script>
import { mapGetters, mapActions } from 'vuex'

const computed = mapGetters({
  username: `Auth/userfullname`,
  storename: `Auth/storename`,
  storecode: `Auth/storecode`,
})

const methods = mapActions({
  logoutAction: `Auth/logout`,
})

methods.logout = async function() {
  await this.logoutAction()
  window.location.reload()
}

export default {
  computed,
  methods,
}
</script>

<style scoped>
  .storecode {
    font-size: 12px;
    color: #aaaaaa;
  }
</style>

<template>
  <header id="header-navbar" class="content-mini content-mini-full">
    <ul class="nav-header pull-right">
      <li>
        <div class="user-bar text-right">
          <h4>
            <label class="storecode">#{{ storecode }}</label>
            {{ storename.toUpperCase() }}
          </h4>
        </div>
      </li>
      <li>
        <div class="btn-group">
          <button class="btn btn-default dropdown-toggle" data-toggle="dropdown" type="button">
            <i class="glyphicon glyphicon-user push-10-r" /> <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right">
            <li class="dropdown-header">Login as</li>
            <li class="dropdown-item">
              {{ username.toUpperCase() }}
            </li>
            <li><br></li>
            <li class="dropdown-header">Action</li>
            <!-- <li>
              <a tabindex="-1" href="#">
                <i class="si si-user pull-right"></i>User Profile
              </a>
            </li> -->
            <li>
              <a tabindex="-1" href="#" @click.prevent="logout">
                <i class="si si-logout pull-right"></i>Log out
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <ul class="nav-header pull-left">
      <li class="hidden-md hidden-lg">
        <!-- Layout API, functionality initialized in App() -> uiLayoutApi() -->
        <button class="btn btn-default" data-toggle="layout" data-action="sidebar_toggle" type="button">
          <i class="fa fa-navicon"></i>
        </button>
      </li>
      <li class="hidden-xs hidden-sm">
        <!-- Layout API, functionality initialized in App() -> uiLayoutApi() -->
        <button class="btn btn-default" data-toggle="layout" data-action="sidebar_mini_toggle" type="button">
          <i class="fa fa-ellipsis-v"></i>
        </button>
      </li>
    </ul>
  </header>
</template>