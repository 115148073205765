<script>
import { mapGetters, mapActions } from 'vuex'
const $ = window.jQuery

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ImageCropper from '@/components/uicomp/ImageCropper'
const components = {
  layout,
  ImageCropper
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    croppedImage: {},
    imagePreview: {},
    imageSrc: ``,
    modalShow: false,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Item/loadingState`,
    data: `Product/Item/data`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Item/fetchData`,
  updateCoverProduct: `Product/Item/updateCover`,
})

methods.goback = function() {
  this.$router.go(-1)
}

methods.reset = function() {
  this.imageSrc = this.data.cover || `/img/defaults/product.jpg`
  this.imagePreview = this.imageSrc
}

methods.doCrop= function(event) {
  if (event.target.files.length) {
    const file = event.target.files[0]
    // const size = (file.size / 1024 / 1024).toFixed(2)

    const fileReader = new FileReader()

    fileReader.onload = (e) => {
      this.imageSrc = e.target.result
      const element = this.$refs.cropfield.$el
      $(element).modal(`show`)
    }

    fileReader.readAsDataURL(event.target.files[0])
  }
}

methods.cropImage = function(image) {
  // console.log(`cropped`, image)
  this.croppedImage = image
}

methods.saveImage = async function() {
  await this.updateCoverProduct({ id: this.data.id, media: this.croppedImage })
  this.imagePreview = this.croppedImage.toDataURL(`image/png`)
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.data.name))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
    this.$store.commit(`set_breadcrumb`, [ `Katalog Produk`, `Data Barang` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<style scoped>
  .row {
    padding-bottom: 20px;
  }

  Button {
    margin-top: 20px;
  }

  .img-preview {
    border: 1px solid #dadadd;
    border-radius: 2%;
  }
</style>

<template>
  <layout type="narrow">
    <Row>
      <BlockForm title="COVER PRODUK"
        :width=8
        :push=2
        :editbutton=false
      >
        <template #content>
          <center>
            <img :src="imagePreview" class="img-preview" width="240px" height="240px">
            <div class="form-group">
              <input type="file" ref="newImageSource"
                style="display: none;"
                accept="image/*"
                @change="doCrop"
                >
            </div>
          </center>
        </template>

        <template #footer>
          <ButtonFormSet
            labelsave="Ganti Gambar"
            @submit="$refs.newImageSource.click()"
            @cancel="goback"
          />
        </template>
      </BlockForm>

      <ModalDialog ref="cropfield" id="cropfield" title="Ganti Gambar">
        <center>
          <ImageCropper :src="imageSrc" @cropped="cropImage" />
        </center>

        <template #footer>
          <Button size="sm" color="none" text="Batal" data-dismiss="modal"></Button>
          <Button size="sm" color="primary" text="Simpan" icon="check" data-dismiss="modal" @click="saveImage"></Button>
        </template>
      </ModalDialog>
    </Row>
  </layout>
</template>
