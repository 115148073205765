
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    dialogTitle: `Tambah Record`,
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `StPurchase/Category/data`,
  processing: `StPurchase/Category/loadingState`
})
computed.validate = function() {
  return !!this.input.name && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `StPurchase/Category/fetchData`,
  entry: `StPurchase/Category/update`,
})

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  this.dialogTitle = `Tambah Record`
  return this.fetch(null).then(this.show)
}

methods.edit = async function(id) {
  this.id = id
  this.dialogTitle = `Edit Record`
  return this.fetch(id).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id
  const success = await this.entry({ id, data })

  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord" @shown="$refs.FormEntry.focus()">
    <FormGroup>
      <TextField
        ref="FormEntry"
        label="Nama Pos"
        :forceupper=true
        @enter="addrecord"
        v-model="input.name" />
    </FormGroup>
  </EntryDialog>
</template>