<script>
export default {
  props: {
    width: {
      type: Number,
      default: 12,
    },
    push: {
      type: Number,
      default: null,
    },
    title: String,
    loadingState: Boolean,
    lockedState: Boolean,
    header: {
      type: Boolean,
      default: true,
    },
    editbutton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    panelWidth() {
      return [
        `col-md-${this.width}`,
        (this.push) && `col-md-push-${this.push}`,
      ]
    }
  }
}
</script>

<style scoped>
  .block-content-footer {
    margin-top: -20px;
  }
</style>

<template>
  <div :class="panelWidth">
    <Block :header="header" :title="title" :loading="loadingState">
      <template #header-option>
        <li v-if="editbutton && lockedState">
          <Button icon="pencil" @click="$emit(`edit`)" />
        </li>
        <li v-if="editbutton && !lockedState">
          <Button icon="close" @click="$emit(`revert`)" />
        </li>
      </template>

      <slot name="content" />
    </Block>

    <div class="block-content-footer">
      <slot name="footer" />
    </div>
  </div>
</template>