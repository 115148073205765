<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    typeOptions: [
      { label: `Ya`, value: true },
      { label: `Tidak`, value: false },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Customer/Group/loadingState`,
    data: `Customer/Group/data`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Customer/Group/fetchData`,
  updateData: `Customer/Group/update`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.goback = function() {
  this.$router.push(`/customer/group`)
}

methods.updateProfile = async function() {
  await this.updateData({
    id: this.data.id,
    data: this.input,
  })

  this.goback()
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      if (n.id !== o.id) this.reset()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
  this.$store.commit(`add_breadcrumb`, this.data.name)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const id = this.$route.params.id

  if (!this.data.id || this.data.id !== id) await this.fetchData(id)

  this.$store.commit(`set_page_title`, `Kasir`)
  this.$store.commit(`set_breadcrumb`, [ `Grup Pelanggan`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <Col12>
        <h2 class="content-heading">Setting Data Grup</h2>
      </Col12>

      <BlockForm title="DETAIL SUPPLIER"
        :width=6
        :loadingState="isLoading"
        :header=false
        :editbutton=false
        :lockedState=false>
        <template #content>
          <FormGroup>
            <TextField
              label="Nama Grup"
              width="8"
              :forceupper=true
              v-model="input.name" />

            <TextArea
              label="Desrkripsi"
              width="12"
              :rows=3
              :forceupper=true
              v-model="input.description" />

            <SelectField
              label="Harga Toko"
              width="8"
              :options="typeOptions"
              v-model="input.use_store_discount" />
          </FormGroup>
        </template>

        <template #footer>
          <ButtonFormSet
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="goback"
          />
        </template>
      </BlockForm>
    </Row>
  </layout>
</template>