
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    filter: {},
    typeOptions: [
      { label: `STOK OVER`, value: `SOVR` },
      { label: `STOK KURANG`, value: `SMIN` },
    ],
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/Stock/list`,
  meta: `Product/Stock/listMeta`,
  categoryOptions: `Product/Category/options`,
  supplierOptions: `Product/Supplier/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/Stock/setListOption`,
  reload: `Product/Stock/fetchList`,
  selectObject: `Product/Stock/select`,
  deselectObject: `Product/Stock/deselect`,
  filterListSelected: `Product/Stock/filterListSelected`,
  resetListSelected: `Product/Stock/resetListSelected`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.check = function(event) {
  const { checked, value } = event.target
  if (checked) return this.selectObject(value)

  return this.deselectObject(value)
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter: {
    deep: true,
    handler(filterParams) {
      this.setListOption(filterParams)
    }
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.filter = {
    category: this.meta.filter.category,
    supplier: this.meta.filter.supplier,
    type: this.meta.filter.type,
  }

  // if (!this.filter.category) {
  //   this.filter.category = this.categoryOptions[0].value
  // }

  // this.setListOption(this.filter)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  this.$store.commit(`Product/Stock/set_list`, [])
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <Col12>
      <FormGroup>
        <SelectField
          label="Filter Kategori"
          width="3"
          defaultLabel="SEMUA KATEGORI"
          defaultValue=""
          :options="categoryOptions"
          v-model="filter.category" />

        <SelectField
          label="Filter Supplier"
          width="3"
          defaultLabel="DARI SEMUA SUPPLIER"
          defaultValue=""
          :options="supplierOptions"
          v-model="filter.supplier" />

        <SelectField
          label="Tipe"
          width="3"
          defaultLabel="DEFAULT"
          defaultValue=""
          :options="typeOptions"
          v-model="filter.type" />

        <div class="col-md-3" style="padding-top: 25px">
          <ButtonL text="Filter Seleksi" color="primary" @click="filterListSelected" />
          <ButtonL text="Reset" color="primary" @click="resetListSelected" />
        </div>
      </FormGroup>
    </Col12>

    <TableList>
      <template #head>
        <th width="20" class="text-center"></th>
        <th width="20" class="text-center">#</th>
        <th width="160" class="text-left">Kategori</th>
        <th class="text-left">Nama Produk</th>
        <th width="40" class="text-right">Stok Ada</th>
        <th width="40" class="text-right">Bala<br>nce</th>
        <th width="40" class="text-right">+/-</th>
        <th width="90" class="text-right">Harga<br>Beli</th>
        <th width="110" class="text-right">Total<br>Modal</th>
        <th width="110" class="text-right">Modal<br>Lebih</th>
        <th width="110" class="text-right">Total<br>Belanja</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center">
            <input type="checkbox"
              :value="item.id"
              :checked="item.ischecked"
              @change="check">
          </td>

          <td class="text-center">
            {{ index + meta.curstart }}
          </td>

          <td class="text-left">
            {{ item.category_name }}
          </td>

          <td class="text-left">
            <p>{{ item.name }}</p>
            <small class="subdata">Supplier: {{ item.supplier_name }}</small>
          </td>

          <td class="text-right">
            {{ item.current_stock_readable }}
          </td>

          <td class="text-right">
            {{ item.minimum_stock_readable }}
          </td>

          <td class="text-right">
            <span class="text-warning" v-if="item.balance > 0">
              +{{ item.balance }}
            </span>
            <span class="text-danger" v-if="item.balance < 0">
              {{ item.balance }}
            </span>
          </td>

          <td class="text-right">
            {{ item.price_asset_readable }}
          </td>

          <td class="text-right">
            {{ item.total_asset_readable }}
            <small class="subdata" v-if="item.current_stock > 0">
              {{ item.current_stock_readable }} x {{ item.price_asset_readable }}
            </small>
          </td>

          <td class="text-right">
            {{ item.total_over_readable }}
            <small class="subdata" v-if="item.balance > 0">
              {{ item.balance }} x {{ item.price_asset_readable }}
            </small>
          </td>

          <td class="text-right">
            <p>{{ item.total_expense_readable }}</p>
            <small class="subdata" v-if="item.minus > 0">
              {{ item.minus_readable }} x {{ item.price_asset_readable }}
            </small>
          </td>
        </tr>
      </template>

      <template #foot>
        <tr>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"></td>
          <td class="text-right"><label>TOTAL</label></td>
          <td class="text-right">{{ meta.total_asset_readable }}</td>
          <td class="text-right">{{ meta.total_over_readable }}</td>
          <td class="text-right">{{ meta.total_expense_readable }}</td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>