
/**
 * https://www.the-art-of-web.com/html/input-field-uppercase/
 *
 */

import insertTextAtCursor from 'insert-text-at-cursor'

function insert(target, string, start, end) {
  return target.substring(0, start)
    + string
    + target.substring(end)
}

export default function(e) {
  if (e.target.readOnly) return true

  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;

  if (e.type === `paste`) {
    const pastedText = e.pastedText;
    e.target.setSelectionRange(start, end);

    insertTextAtCursor(e.target, pastedText.toUpperCase())

    e.preventDefault()
    e.target.dispatchEvent(new KeyboardEvent(`input`, e))
    return;
  }

  var charInput = e.keyCode;
  if((charInput >= 97) && (charInput <= 122)) { // lowercase
    if(!e.ctrlKey && !e.metaKey && !e.altKey) { // no modifier key
      var newChar = charInput - 32;
      e.target.setSelectionRange(start, end);

      insertTextAtCursor(e.target, String.fromCharCode(newChar))

      e.preventDefault();
      e.target.dispatchEvent(new KeyboardEvent(`input`, e)) //emit event to component.
    }
  }
}