<script>
import { mapGetters, mapActions } from 'vuex'
const jQuery = window.$

/**
 * Props
 *
 */
const props = {
  valid: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: `Tambah Record`,
  },
  usebutton: {
    type: Boolean,
    default: true,
  },
  labelsave: {
    type: String,
    default: `Save`,
  },
  labelcancel: {
    type: String,
    default: `Cancel`,
  },
  size: {
    type: String,
    default: `sm`,
  },
}

/**
 * Methods
 *
 */
const methods = {}

methods.show = function () {
  jQuery(this.$refs.modal.$el).modal(`show`)
}

methods.dismiss = function () {
  jQuery(this.$refs.modal.$el).modal(`hide`)
}

export default {
  props,
  methods,
}
</script>

<template>
  <ModalDialog ref="modal" :title="title" :size="size" @shown="$emit(`shown`)">
    <slot />

    <template #footer>
      <ButtonFormSet v-if="usebutton"
        :disabled="!valid"
        :labelsave="labelsave"
        :labelcancel="labelcancel"
        @submit="$emit(`submit`)"
        @cancel="dismiss"
      />
    </template>
  </ModalDialog>
</template>
