
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ListTable from './ListTable'
const components = {
  layout,
  ListTable,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Finance/DailyJournal/loadingState`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  reload: `Finance/DailyJournal/fetchList`,
})

/**
 * Event OnMounted
 *
 */
const mounted = function() {
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Laporan`)
  this.$store.commit(`set_breadcrumb`, [ `Laporan Keuangan`, `Jurnal Harian` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <layout>
    <Row>
      <BlockTable :loading="isLoading">
        <template #header>
          Jurnal Harian
          <ButtonR icon="repeat" color="default" @click="reload" />
        </template>
        <template #content>
          <ListTable />
        </template>
      </BlockTable>
    </Row>
  </layout>
</template>