<script>
import { mapGetters, mapActions } from 'vuex'
const jQuery = window.$

/**
 * Props
 *
 */
const props = {
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `CashAccount/Transaction/data`
})

computed.TEXT_DIBAYAR = function () {
  if (this.data.amount >= 0) return `JML. KREDIT`
  else return `JML. DEBIT`
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `CashAccount/Transaction/fetchData`
})

methods.show = function (id) {
  if (!id) return false

  this.fetchData(id)
    .then(() => {
      jQuery(this.$refs.modal.$el).modal(`show`)
    })
}

methods.dismiss = function () {
  jQuery(this.$refs.modal.$el).modal(`hide`)
}

export default {
  computed,
  props,
  methods,
}
</script>

<template>
  <ModalDialog ref="modal" title="Detail" size="sm" @shown="$emit(`shown`)">
    <table class="no-style">
      <tr>
        <td width="100"></td>
        <td width="10"></td>
        <td></td>
      </tr>
      <tr>
        <td><b>REKENING</b></td>
        <td>:</td>
        <td>{{ data.account_name }}</td>
      </tr>
      <tr>
        <td><b>TANGGAL</b></td>
        <td>:</td>
        <td>{{ data.date_readable }}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td><br></td>
      </tr>
      <tr>
        <td colspan="3"><i>{{ data.note || `Tidak ada keterangan` }}</i><br><br></td>
      </tr>
      <tr>
        <td><b>{{ TEXT_DIBAYAR }}</b></td>
        <td>:</td>
        <td>Rp. {{ data.amount_abs_readable }}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td><span class="nodata">ref. {{ data.approval_code }}</span></td>
      </tr>
    </table>

    <template #footer>
      <ButtonFormSet
        labelsave="Tutup"
        :buttoncancel=false
        @submit="dismiss"
      />
    </template>
  </ModalDialog>
</template>
