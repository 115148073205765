import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import DashboardUI from '@/templates/DashboardUI.vue'
import SingleFormUI from '@/templates/SingleFormUI.vue'

Vue.use(VueRouter)

const routes = [];

/**
 * Auth Routing
 *
 */
import RegisterRoutes from './register'
routes.push({ path: '/register', component: SingleFormUI, ...RegisterRoutes })

import AuthRoutes from './auth'
routes.push({ path: '/auth', component: SingleFormUI, ...AuthRoutes })

/**
 * Dashboard Routing
 *
 */
import DashboardRoutes from './dashboard'
routes.push({ path: '/', component: DashboardUI, ...DashboardRoutes })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['Auth/isLoggedIn']) return next()

    next('/auth/signin')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.hasPerm)) {
    const perm = to.meta.hasPerm
    const isAllowed = store.getters['Auth/permission'].includes(perm)
    store.commit('Auth/content_state', isAllowed)

    return next()
  } else {
    store.commit('Auth/content_state', 1)
    next()
  }
})

/** Load Global Components */
require('../components/uicomp')
require('../components/icons')
require('../helpers/directives')

export default router
