<template>
  <label :class="__class">
    <input type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @input="handle"
    >

    <span></span>

    {{ label }}

    <p v-if="sublabel" class="sublabel" v-html="sublabel"></p>
  </label>
</template>

<script>
export default {
  props: {
    id: String,
    value: {},

    label: String,
    sublabel: String,

    disabled: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: `sm`,
    },
    color: {
      type: String,
      default: `primary`,
    },

  },
  data() {
    return {
      //
    }
  },
  computed: {
    __size() {
      return `switch-${this.size}`
    },
    __color() {
      return `switch-${this.color}`
    },
    __class() {
      return [
        `css-input`,
        `switch`,
        this.__size,
        this.__color,
      ]
    },
    checked() {
      return this.value == true
    }
  },
  methods: {
    handle(event) {
      return this.$emit(`input`, event.target.checked)
    },
  }
}
</script>

<style scoped>
  .sublabel {
    font-size: 80%;
    font-style: italic;
    margin-top: 5px;
    color: #999999;
  }
</style>