<script>
const OneUI = window.OneUI

import SidebarUI from '@/components/dashboard/SidebarUI'
import NavbarUI from '@/components/dashboard/NavbarUI'
import PageFooter from '@/components/dashboard/PageFooter'

export default {
  components: {
    SidebarUI,
    NavbarUI,
    PageFooter,
  },
  mounted() {
    OneUI.init()
    OneUI.initHelpers(['datepicker', 'notify']);
  }
}
</script>

<template>
  <div
    id="page-container"
    class="sidebar-l sidebar-o side-scroll header-navbar-fixed">

    <SidebarUI />
    <NavbarUI />

    <!-- Main Container -->
    <main id="main-container" style="padding-bottom: 40px;">
      <slot />
    </main>

    <!-- Footer -->
    <PageFooter />
  </div>
</template>