<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$http.interceptors.response.use(response => {
      return response
    }, (err) => {
      if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
        this.$store.dispatch(`Auth/logout`).then(() => {
          window.location = `/signin`
        })
      }

      return Promise.reject(err.response);
    });
  }
}
</script>

<style>
</style>
