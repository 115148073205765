<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
const components = {
  layout,
  ItemList,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    profile: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Package/loadingState`,
    data: `Product/Package/data`,
    itemlistCount: `Product/Package/itemlistCount`,
    itemlistMeta: `Product/Package/itemlistMeta`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Package/fetchData`,
  updateData: `Product/Package/update`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) this.isProfileLocked = false
  if (mode === `revert`) this.reset()
}

methods.goback = function() {
  this.$router.push(`/product/package`)
}

methods.editItem = function() {
  this.$router.push(`/product/package/${this.data.id}/item`)
}

methods.reset = function() {
  this.profile = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateProfile = async function() {
  const data = this.profile
  data.store_discount = data.price_unit - data.price_store

  await this.updateData({
    id: this.data.id,
    data,
  })

  this.reset()
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.profile.name))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Paket`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <Col12>
        <h2 class="content-heading">Setting Data Paket Produk</h2>
      </Col12>

      <BlockForm title="DETAIL PAKET"
        :width=6
        :loadingState="isLoading"
        :lockedState=isProfileLocked
        @edit="profileMode(`edit`)"
        @revert="profileMode(`revert`)"
        >
        <template #content>
          <FormGroup v-if="isProfileLocked">
            <Col12>
              <label>Nama Paket</label>
              <p>{{ data.name }}</p>
            </Col12>

            <Col12>
              <label>Deskripsi</label>
              <p>{{ data.description || `-` }}</p>
            </Col12>

            <Col12>
              <label>Harga Jual ke Pelanggan</label>
              <p>Rp. {{ data.price_unit_readable }}</p>
            </Col12>

            <Col12>
              <label>Harga Jual ke Toko</label>
              <p>Rp. {{ data.price_store_readable }}</p>
            </Col12>
          </FormGroup>

          <FormGroup v-else>
            <TextField
              label="Nama Paket"
              width="8"
              :forceupper=true
              v-model="profile.name" />

            <TextArea
              label="Deskripsi"
              width="12"
              :rows=5
              :forceupper=true
              v-model="profile.description" />

            <TextField
              label="Harga Jual ke Pelanggan"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.price_unit" >
              <template #before>Rp.</template>
            </TextField>

            <TextField
              label="Harga Jual ke Toko Lain"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.price_store" >
              <template #before>Rp.</template>
            </TextField>

            <!-- <TextField
              label="Komisi Penjualan untuk Reseller"
              width="8"
              :forceupper=true
              :laddon=true
              v-model="profile.resell_commission" >
              <template #before>Rp.</template>
            </TextField> -->
          </FormGroup>
        </template>

        <template #footer>
          <ButtonFormSet v-if="!isProfileLocked"
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="reset"
          />
        </template>
      </BlockForm>

      <BlockForm title="ISI PAKET"
        :width=6
        :lockedState=true
        @edit="editItem()"
        >

        <template #content>
          <Col12>
            <label class="pull-left">Jumlah Item</label>
            <p class="pull-right">{{ itemlistMeta.quantity }} Item</p>
            <div class="clear"></div>
          </Col12>
          <Col12>
            <label class="pull-left">Total Modal</label>
            <p class="pull-right">Rp. {{ itemlistMeta.modal_readable }}</p>
            <div class="clear"></div>
          </Col12>

          <ItemList />
        </template>
      </BlockForm>
    </Row>
  </layout>
</template>