<script>
let picker = null

export default {
  props: {
    value: String,
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
  mounted() {
    picker = window.$(this.$refs.tpicker).datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'LT',
    })
    picker.datepicker(`show`)
    picker.on(`keypress`, (e) => {
      e.preventDefault()
    })
    picker.on(`changeDate`, (e) => {
      e.preventDefault()
      this.$emit(`submit`, e.target.value)
    })
  },
}
</script>

<template>
  <input v-focus
    style="width: auto"
    ref="tpicker"
    class="tpicker form-control"
    type="text"
    data-date-format="hh:mm"
    placeholder="hh:mm"
    :value="this.value"
    >
</template>