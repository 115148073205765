<script>
import WeeklyTransactionChart from '@/components/charts/WeeklyTransactionChart'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    WeeklyTransactionChart,
  },
  data: () => ({
    lastTransactions: []
  }),
  computed: {
    ...mapGetters({
      username: `Auth/userfullname`,
      statsIsLoading: `Report/stats_is_loading`,
      statsData: `Report/stats_data`,
      top_field_1: `Report/stats_field1`,
      top_field_2: `Report/stats_field2`,
      top_field_3: `Report/stats_field3`,
      top_field_4: `Report/stats_field4`,
    }),
  },
  methods: {
    ...mapActions({
      getStats: `Report/getStats`,
      getWeeklyOverviewStatsData: `Report/getWeeklyOverviewStatsData`,
      getWeeklyOverviewSalesData: `Report/getWeeklyOverviewSalesData`,
      getLastTransactions: `Report/getLastTransactions`,
    }),
    async reloadWeeklyTransactionChart() {
      this.getWeeklyOverviewStatsData().then((data) => {
        this.$refs.weeklyTransactionChart.updateChart({
          labels: data.labels,
          datasets: data.datasets,
        })
      })
    },
    async reloadWeeklySalesChart() {
      this.getWeeklyOverviewSalesData().then((data) => {
        this.$refs.weeklySalesChart.updateChart({
          labels: data.labels,
          datasets: data.datasets,
        })
      })
    },
    async reloadLastTransactions() {
      this.getLastTransactions().then((records) => {
        const currentDateGroup = Number(moment().format('YYYYMMDD'))

        this.lastTransactions = records.map(data => ({
          ...data,
          get name() { return this.customer_name || this.record_id },
          get when() {
            const d = moment(data.date_ordered)

            return this.date_group === currentDateGroup
              ? d.format('HH.mm')
              : d.format('D MMM')
          }
        }))
      })
    },
  },
  async mounted() {
    this.getStats()
    this.reloadWeeklyTransactionChart()
    this.reloadWeeklySalesChart()
    this.reloadLastTransactions()
  }
}

</script>

<template>
  <div>
    <!-- Page Header -->
    <div class="content bg-primary-dark overflow-hidden">
      <div class="push-50-t push-15">
        <h1 class="h2 text-white">Dashboard</h1>
        <h2 class="h5 text-white-op">Welcome <b class="styleupper">{{ username }}</b></h2>
      </div>
    </div>
    <!-- END Page Header -->

    <!-- Stats -->
    <div class="content bg-white border-b">
      <div class="row items-push text-uppercase">
        <div class="col-xs-6 col-sm-3">
          <div class="font-w700 text-gray-darker animated fadeIn">{{ top_field_1.title }}</div>
          <div class="text-muted animated fadeIn"><small><i class="si si-calendar"></i> {{ top_field_1.detail }}</small></div>
          <span class="font-w300 animated fadeIn text-muted">{{ top_field_1.before }} </span>
          <span class="h2 font-w300 animated fadeIn text-primary">{{ top_field_1.value }}</span>
          <span class="font-w300 animated fadeIn text-muted"> {{ top_field_1.after }}</span>
        </div>
        <div class="col-xs-6 col-sm-3">
          <div class="font-w700 text-gray-darker animated fadeIn">{{ top_field_2.title }}</div>
          <div class="text-muted animated fadeIn"><small><i class="si si-calendar"></i> {{ top_field_2.detail }}</small></div>
          <span class="font-w300 animated fadeIn text-muted">{{ top_field_2.before }} </span>
          <span class="h2 font-w300 animated fadeIn text-primary">{{ top_field_2.value }}</span>
          <span class="font-w300 animated fadeIn text-muted"> {{ top_field_2.after }}</span>
        </div>
        <div class="col-xs-6 col-sm-3">
          <div class="font-w700 text-gray-darker animated fadeIn">{{ top_field_3.title }}</div>
          <div class="text-muted animated fadeIn"><small><i class="si si-calendar"></i> {{ top_field_3.detail }}</small></div>
          <span class="font-w300 animated fadeIn text-muted">{{ top_field_3.before }} </span>
          <span class="h2 font-w300 animated fadeIn text-primary">{{ top_field_3.value }}</span>
          <span class="font-w300 animated fadeIn text-muted"> {{ top_field_3.after }}</span>
        </div>
        <div class="col-xs-6 col-sm-3">
          <div class="font-w700 text-gray-darker animated fadeIn">{{ top_field_4.title }}</div>
          <div class="text-muted animated fadeIn"><small><i class="si si-calendar"></i> {{ top_field_4.detail }}</small></div>
          <span class="font-w300 animated fadeIn text-muted">{{ top_field_4.before }} </span>
          <span class="h2 font-w300 animated fadeIn text-primary">{{ top_field_4.value }}</span>
          <span class="font-w300 animated fadeIn text-muted"> {{ top_field_4.after }}</span>
        </div>
      </div>
    </div>
    <!-- END Stats -->

    <!-- Page Content -->
    <div class="content">
      <div class="row">
        <div class="col-lg-8">
          <!-- Main Dashboard Chart -->
          <div class="block">
            <div class="block-header">
              <ul class="block-options">
                <li>
                  <button type="button" @click="reloadWeeklyTransactionChart"><i class="si si-refresh"></i></button>
                </li>
              </ul>
              <h3 class="block-title">Transaksi Mingguan</h3>
            </div>
            <div class="block-content block-content-full bg-gray-lighter text-center">
              <div style="height: 374px;">
                <WeeklyTransactionChart id="weeklyTransactionChart" ref="weeklyTransactionChart" />
              </div>
            </div>
          </div>

          <div class="block">
            <div class="block-header">
              <ul class="block-options">
                <li>
                  <button type="button" @click="reloadWeeklySalesChart"><i class="si si-refresh"></i></button>
                </li>
              </ul>
              <h3 class="block-title">Penjualan Mingguan</h3>
            </div>
            <div class="block-content block-content-full bg-gray-lighter text-center">
              <div style="height: 374px;">
                <WeeklyTransactionChart id="weeklySalesChart" ref="weeklySalesChart" />
              </div>
            </div>
          </div>
          <!-- END Main Dashboard Chart -->
        </div>
        <div class="col-lg-4">
          <!-- Latest Sales Widget -->
          <div class="block">
            <div class="block-header">
              <ul class="block-options">
                <li>
                  <button type="button" @click="reloadLastTransactions"><i class="si si-refresh"></i></button>
                </li>
              </ul>
              <h3 class="block-title">Penjualan Terakhir</h3>
            </div>
            <div class="block-content bg-gray-lighter">
            </div>
            <div class="block-content">
              <div class="pull-t pull-r-l">
                <div>
                  <table class="table remove-margin-b font-s13">
                      <tbody>
                        <tr v-for="record in lastTransactions" :key="record.id" >
                            <td class="hidden-xs text-muted text-right" style="width: 70px;">{{ record.when }}</td>
                            <td class="font-w600">
                              <router-link :to="`/sales/${record.id}`">{{ record.name }}</router-link>
                            </td>
                            <td class="font-w600 text-success text-right" style="width: 120px;">{{ record.total_sales.readable(0) }}</td>
                        </tr>
                        <tr v-if="lastTransactions.length" >
                            <td class="text-center text-muted" colspan="3">
                              <router-link to="/sales"><i>Lihat Selengkapnya</i></router-link>
                            </td>
                        </tr>
                        <tr v-else >
                            <td class="text-center text-muted">
                              <i>Belum ada transaksi</i>
                            </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
              </div>
            </div>
          </div>
          <!-- END Latest Sales Widget -->
        </div>
      </div>
    </div>
    <!-- END Page Content -->
  </div>
</template>