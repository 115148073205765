
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    filter: {
      category: ``,
      supplier: ``,
    }
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/TransactionItem/list`,
  meta: `Product/TransactionItem/listMeta`,
  categoryOptions: `Product/Category/options`,
  supplierOptions: `Product/Supplier/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/TransactionItem/setListOption`,
  reload: `Product/TransactionItem/fetchList`,
  pagePrev: `Product/TransactionItem/pagePrev`,
  pageNext: `Product/TransactionItem/pageNext`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.edit = function(id) {
  this.$router.push(`/product/transaction/${id}`)
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter: {
    deep: true,
    handler(filterParams) {
      this.setListOption(filterParams)
    }
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="20" class="text-center">#</th>
        <th width="100" class="text-left">Tanggal</th>
        <th width="240" class="text-left">Produk</th>
        <th width="100" class="text-right">Transaksi</th>
        <th class="text-left">Note</th>
        <th width="120" class="text-left">Petugas</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center">
            {{ index + meta.curstart }}
          </td>

          <td class="text-left">
            {{ item.date_readable }}
          </td>

          <td class="text-left">
            {{ item.product_name }}
            {{ item.category_name }}
          </td>

          <td class="text-right">
            <p v-if="item.type == `O`" class="text-danger">- {{ item.quantity }}</p>
            <p v-if="item.type == `I`" class="text-success">+ {{ item.quantity }}</p>
          </td>

          <td class="text-left">
            <p v-html="item.note_html" />
          </td>

          <td class="text-left">
            {{ item.employee_name }}
          </td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>