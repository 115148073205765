<script>
import { mapGetters } from 'vuex'

import LoadingLayer from '@/components/dashboard/LoadingLayer'
import DashboardView from '@/layout/dashboard-view'

export default {
  components: {
    LoadingLayer,
    DashboardView,
  },
  data() {
    return {
      fetchProcess: 0,
    }
  },
  computed: mapGetters({
    store: `Auth/storeid`,
    contentState: `Auth/contentState`,
  }),
  sockets: {
    connect() {
      this.$socket.client.emit('define', this.store)
    }
  },
  created: async function() {
    const fetch = async (action) => {
      this.fetchProcess += 1
      await this.$store.dispatch(action)
      this.fetchProcess -= 1
    }

    fetch(`Auth/fetchPermission`)
      .then(() => {
        const perm = this.$route.meta.hasPerm
        if (perm) {
          const isAllowed = this.$store.getters['Auth/permission'].includes(perm)
          this.$store.commit('Auth/content_state', isAllowed)
        } else {
          this.$store.commit('Auth/content_state', 1)
        }
      })

    fetch(`Master/Department/fetchOptions`)
    fetch(`Product/Brand/fetchOptions`)
    fetch(`Product/Category/fetchOptions`)
    fetch(`Product/Supplier/fetchOptions`)
    fetch(`Product/Item/fetchOptions`)
    fetch(`Product/Service/fetchOptions`)
    fetch(`Product/Package/fetchOptions`)
    fetch(`PayMethod/fetchOptions`)
    fetch(`Employee/fetchOptions`)
    fetch(`Customer/fetchOptions`)
    fetch(`Customer/Group/fetchOptions`)
    fetch(`Courier/fetchOptions`)
    fetch(`CashAccount/fetchOptions`)
    fetch(`StPurchase/Category/fetchOptions`)
    fetch(`OpPurchase/Account/fetchOptions`)
  },
}
</script>

<template>
  <div>
    <LoadingLayer v-if="fetchProcess" />
    <DashboardView v-else>
      <div v-if="!contentState">
        You dont have permission to access this menu.
      </div>
      <router-view v-else />
    </DashboardView>
  </div>
</template>

<style scoped>
</style>
