<template>
  <div class="img-container">
    <img ref="image" :src="src" height="320px" width="320px" crossorigin>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'

export default {
  props: {
    src: String,
  },
  data() {
    return {
      cropper: {},
      image: {},
    }
  },
  watch: {
    src(value) {
      this.cropper.replace(value)
    }
  },
  mounted() {
    this.image = this.$refs.image
    this.cropper = new Cropper(this.image, {
      viewMode: 1,
      dragMode: `move`,
      cropBoxMovable: false,
      cropBoxResizable: false,
      minContainerWidth: 320,
      minContainerHeight: 320,
      aspectRatio: 1,
      crop: () => {
        this.$emit(`cropped`, this.cropper.getCroppedCanvas({ width : 320 }))
      }
    })
  },
}
</script>


<style scoped>
  .img-container img {
    max-width: 100%;
  }
</style>