<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
const components = {
  layout,
  ItemList,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/ExpenditureReport/loadingState`,
    data: `Product/ExpenditureReport/data`,
    supplierOptions: `Product/Supplier/options`,
    itemOptions: `Product/Item/options`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/ExpenditureReport/fetchData`,
  fetchListItem: `Product/ExpenditureReport/fetchListItem`,
  fetchListPayment: `Product/ExpenditureReport/fetchListPayment`,
})

methods.goback = function() {
  this.$router.push(`/rep/exp-transaction`)
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
  this.isProfileLocked = true
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      if (n.id !== o.id) {
        this.fetchListItem()
        this.fetchListPayment()
      }
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
  this.$store.commit(`add_breadcrumb`, this.data.record_id)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const id = this.$route.params.id

  if (!this.data.id || this.data.id !== id) await this.fetchData(id)

  this.$store.commit(`set_page_title`, `Laporan`)
  this.$store.commit(`set_breadcrumb`, [ `Laporan Pembelanjaan`, `Data Transaksi` ])
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          DETAIL RECORD
        </template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Record ID</label>
                  <p>
                    {{ data.record_id }}
                    <label class="label label-info push-10-l" v-if="data.record_type === `CARGO`">Cargo</label>
                  </p>
                </Col12>
                <Col12>
                  <label>Supplier</label>
                  <p>
                    <span v-if="data.supplier_name">{{ data.customer_name }}</span>
                    <span v-else><i>Tidak terdaftar</i></span>
                  </p>
                </Col12>
                <Col12>
                  <label>No Invoice</label>
                  <p>
                    <span v-if="data.invoice_id">{{ data.invoice_id }}</span>
                    <span v-else><i>-</i></span>
                  </p>
                </Col12>
                <Col12>
                  <label>Note</label>
                  <p>
                    <span v-if="data.note">{{ data.note }}</span>
                    <span v-else><i>-</i></span>
                  </p>
                </Col12>
              </Row>
            </div>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Status Order</label>
                  <p>{{ data.status_order }}</p>
                </Col12>
                <Col12>
                  <label>Petugas</label>
                  <p>{{ data.employee_name || `-` }}</p>
                </Col12>
                <div class="col-md-6">
                  <label>Tanggal Order</label>
                  <p v-if="data.status_order === `DRAFT`">-</p>
                  <p v-else>{{ data.date_ordered_readable }}</p>
                </div>
                <div class="col-md-6">
                  <label>Tanggal Diterima</label>
                  <p>{{ data.date_received_readable || `-` }}</p>
                </div>
                <div class="col-md-6">
                  <label>Status Pembayaran</label>
                  <p>
                    <label class="label label-danger" v-if="data.status_paid">Lunas</label>
                    <label class="label label-warning" v-else>Belum Lunas</label>
                    <span> {{ data.date_paid_readable }}</span>
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Sisa Pembayaran</label>
                  <p>Rp. {{ data.total_unpaid_readable }}</p>
                </div>
              </Row>
            </div>
          </Row>
        </template>
      </BlockTable>

      <BlockTable>
        <template #header>
          DAFTAR ITEM
        </template>

        <template #content>
          <ItemList />
        </template>
      </BlockTable>
    </Row>
  </layout>
</template>