
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import get from 'lodash/get'

/**
 * Components
 *
 */
const components = {
}

/**
 * Static Data
 *
 */
const filterDateFrom = moment().format(`DD/MM/YYYY`)
const filterDateTo = moment().format(`DD/MM/YYYY`)
const data = function () {
  return {
    current_item_id: null,
    filter_datefrom: filterDateFrom,
    filter_dateto: filterDateTo,
    filter_supplier_id: null,
    filter_brand_id: null,
    filter_type: null,
    typeListOptions: [
      { label: `Barang`, value: `ITEM` },
      { label: `Layanan`, value: `SERVICE` },
      { label: `Paket`, value: `PACKAGE` },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  listOriginal: `SalesReportItem/list`,
  meta: `SalesReportItem/listMeta`,
  brandListOptionsOriginal: `Product/Brand/options`,
  supplierListOptionsOriginal: `Product/Supplier/options`,
})

computed.total = function () {
  return this.list.reduce((total, item) => {
    total.quantity += item.quantity
    total.sales += item.subtotal
    total.modal += item.modal
    total.service_cost += item.service_cost
    total.profit += item.profit
    return total
  }, { quantity: 0, sales: 0, modal: 0, profit: 0, service_cost: 0 })
}

computed.list = function () {
  let list = [ ...this.listOriginal ]

  if (this.filter_type) list = list.filter(i => i.type === this.filter_type)
  if (this.filter_brand_id) list = list.filter(i => i.product_brand_id === this.filter_brand_id)
  if (this.filter_supplier_id) list = list.filter(i => i.product_supplier_id === this.filter_supplier_id)

  return list
}

computed.brandListOptions = function () {
  const brands = this.listOriginal.reduce((_brands, item) => {
    if (!_brands.includes(item.product_brand_id)) {
      _brands.push(item.product_brand_id)
    }

    return _brands
  }, [])

  const options = this.brandListOptionsOriginal.filter(option => brands.includes(option.value))
  return options
}

computed.supplierListOptions = function () {
  const suppliers = this.listOriginal.reduce((_suppliers, item) => {
    if (!_suppliers.includes(item.product_supplier_id)) {
      _suppliers.push(item.product_supplier_id)
    }

    return _suppliers
  }, [])

  const options = this.supplierListOptionsOriginal.filter(option => suppliers.includes(option.value))
  return options
}

computed.filter = function () {
  const filter = {
    type_value: this.filter_type,
    brand_value: this.filter_brand_id,
    supplier_value: this.filter_supplier_id,
  }

  filter.type_label = get(this.typeListOptions.find((i) => i.value === filter.type_value), `label`)
  filter.brand_label = get(this.brandListOptions.find((i) => i.value === filter.brand_value), `label`)
  filter.supplier_label = get(this.supplierListOptions.find((i) => i.value === filter.supplier_value), `label`)

  return filter
}


/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `SalesReportItem/setListOption`,
  reload: `SalesReportItem/fetchList`,
  pagePrev: `SalesReportItem/pagePrev`,
  pageNext: `SalesReportItem/pageNext`,
  deleteItem: `SalesReportItem/remove`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_datefrom: {
    handler(value) {
      this.setListOption({ datefrom: value })
    }
  },
  filter_dateto: {
    handler(value) {
      this.setListOption({ dateto: value })
    }
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }

  this.setListOption({ datefrom: this.filter_datefrom, dateto: this.filter_dateto })
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>

    <Col12>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li><SelectDateChangable v-model="filter_datefrom"></SelectDateChangable></li>
              <li>-</li>
              <li><SelectDateChangable v-model="filter_dateto"></SelectDateChangable></li>
            </ul>
          </td>
        </tr>
        <tr>
          <td><label>Tipe</label></td>
          <td><label>:</label></td>
          <td>
            <SelectFieldChangable
              placeholder="Semua Tipe"
              :value="filter.type_label"
              :formValue="filter.type_value"
              :options="typeListOptions"
              @submit="filter_type = $event"
            />
          </td>
        </tr>
        <tr>
          <td><label>Merek</label></td>
          <td><label>:</label></td>
          <td>
            <SelectFieldChangable
              placeholder="Semua Brand"
              :value="filter.brand_label"
              :formValue="filter.brand_value"
              :options="brandListOptions"
              @submit="filter_brand_id = $event"
            />
          </td>
        </tr>
        <tr>
          <td><label>Supplier</label></td>
          <td><label>:</label></td>
          <td>
            <SelectFieldChangable
              placeholder="Semua Supplier"
              :value="filter.supplier_label"
              :formValue="filter.supplier_value"
              :options="supplierListOptions"
              @submit="filter_supplier_id = $event"
            />
          </td>
        </tr>
      </table>
    </Col12>

    <Col12>
      <br>
    </Col12>

    <TableList>
      <template #head>
        <th width="40" class="text-center sticky">#</th>
        <th width="120" class="text-left">Tanggal Order</th>
        <th width="140" class="text-left">Record</th>
        <th width="10" class="text-right"></th>
        <th class="text-left">Nama Item</th>
        <th width="60" class="text-right">Jumlah</th>
        <th width="60" class="text-right">Harga</th>
        <th width="60" class="text-right">Subtotal</th>
        <th width="60" class="text-right">Modal</th>
        <th width="60" class="text-right">ServCost</th>
        <th width="60" class="text-right">Laba</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.parent_date_ordered.readabledate() }}</td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/rep/sales-transaction/${item.parent_id}`">
              {{ item.parent_record_id }}
            </router-link>
          </td>
          <td class="text-right">
            <Label v-if="item.type === `ITEM`" c="info" t="Item">I</Label>
            <Label v-if="item.type === `SERVICE`" c="success" t="Layanan">L</Label>
            <Label v-if="item.type === `PACKAGE`" c="warning" t="Paket">P</Label>
          </td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-right">{{ item.quantity.readable() }}</td>
          <td class="text-right">{{ item.price_unit.readable() }}</td>
          <td class="text-right">{{ item.subtotal.readable() }}</td>
          <td class="text-right">{{ item.modal.readable() }}</td>
          <td class="text-right">{{ item.service_cost.readable() }}</td>
          <td class="text-right">{{ item.profit.readable() }}</td>
        </tr>
        <tr class="custom-bg-primary-light">
          <td class="text-left"></td>
          <td class="text-left"><label>Total</label></td>
          <td class="text-left"></td>
          <td class="text-left"></td>
          <td class="text-left"></td>
          <td class="text-right">{{ total.quantity.readable() }}</td>
          <td class="text-right"></td>
          <td class="text-right">{{ total.sales.readable() }}</td>
          <td class="text-right">{{ total.modal.readable() }}</td>
          <td class="text-right">{{ total.service_cost.readable() }}</td>
          <td class="text-right">{{ total.profit.readable() }}</td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>