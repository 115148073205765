<script>
import { mapGetters, mapActions } from 'vuex'
import printJS from 'print-js'
import 'print-js/dist/print.css'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
import SelectListDialog from '@/components/uicomp/ModalDialog-Form-List'
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import ConfirmDialog from '@/components/uicomp/ModalDialog-Confirm'
import CustomerEntryDialog from '../Customer/EntryDialog'
import CourierSelectedDialog from './CourierSelectedDialog'
import CancelTransactionDialog from './CancelTransactionDialog'
const components = {
  layout,
  ItemList,
  SelectListDialog,
  DeleteDialog,
  ConfirmDialog,
  CustomerEntryDialog,
  CourierSelectedDialog,
  CancelTransactionDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    account: {},
    isProfileLocked: true,
    useStoreDiscountOptions: [
      { label: `Harga Customer`, value: false },
      { label: `Harga Toko`, value: true },
    ],
    addDialog: {
      options: [],
      action: ``,
    },
    selectedCourier: ``,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  isLoading: `Sales/loadingState`,
  data: `Sales/data`,
  itemOptions: `Product/Item/options`,
  serviceOptions: `Product/Service/options`,
  packageOptions: `Product/Package/options`,
  customerOptions: `Customer/options`,
  courierOptions: `Courier/options`,
  employeeOptions: `Employee/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Sales/fetchData`,
  fetchListItem: `Sales/fetchListItem`,
  fetchListPayment: `Sales/fetchListPayment`,
  updateData: `Sales/update`,
  removeData: `Sales/remove`,
  updateStatusOrder: `Sales/tagtoOrder`,
  updateStatusSent: `Sales/tagtoSent`,
  updateStatusCanceled: `Sales/tagtoCanceled`,
  lockData: `Sales/lock`,
  updateListItem: `Sales/updateListItem`,
  invoiceGenerate: `Sales/invoiceGenerate`,
  modelPay: `Sales/pay`,
})

methods.goback = function() {
  this.$router.push(`/sales`)
}

methods.dopayment = function() {
  this.$router.push(`/sales/${this.data.id}/pay`)
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateRecordStoreDiscount = async function(use_store_discount) {
  return this.updateData({
    id: this.data.id,
    data: { use_store_discount },
  })
}

methods.updateRecordInvoiceID = async function(invoice_id) {
  return this.updateData({
    id: this.data.id,
    data: { invoice_id },
  })
}

methods.updateRecordNote = async function(note) {
  return this.updateData({
    id: this.data.id,
    data: { note },
  })
}

methods.addProductItem = function() {
  this.addDialog.options = this.itemOptions
  this.addDialog.action = `ADD_ITEM`
  this.addDialog.addbutton = false
  this.$refs.AddItemDialog.show()
}

methods.addProductService = function() {
  this.addDialog.options = this.serviceOptions
  this.addDialog.action = `ADD_ITEM`
  this.addDialog.addbutton = false
  this.$refs.AddItemDialog.show()
}

methods.addProductPackage = function() {
  this.addDialog.options = this.packageOptions
  this.addDialog.action = `ADD_ITEM`
  this.addDialog.addbutton = false
  this.$refs.AddItemDialog.show()
}

methods.setCustomer = function() {
  this.addDialog.options = this.customerOptions
  this.addDialog.action = `SET_CUSTOMER`
  this.addDialog.addbutton = true
  this.$refs.AddItemDialog.show()
}

methods.setReseller = function() {
  this.addDialog.options = this.employeeOptions
  this.addDialog.action = `SET_RESELLER`
  this.addDialog.addbutton = true
  this.$refs.AddItemDialog.show()
}

methods.resetCourier = function() {
  this.updateData({
    id: this.data.id,
    data: {
      courier_id: null,
      cost_delivery: 0,
    },
  })
}

methods.setCourier = function() {
  this.addDialog.options = this.courierOptions
  this.addDialog.action = `SET_COURIER`
  this.addDialog.addbutton = false
  this.$refs.AddItemDialog.show()
}

methods.setDeliveryCost = function() {
  this.addDialog.action = `SET_DELIVERYCOST`
  this.$refs.CourierSelectedDialog.show()
}

methods.addDialogAddAction = async function(value) {
  this.addDialog.action = `ADD_CUSTOMER`
  this.$refs.AddItemDialog.dismiss()
  this.$refs.CustomerEntryDialog.show()
}

methods.addDialogAction = async function(value) {
  if (this.addDialog.action === `ADD_ITEM`) {
    return this.updateListItem({
      product_id: value,
      quantity: 1
    })
  }

  if (this.addDialog.action === `ADD_CUSTOMER`) {
    this.updateData({
      id: this.data.id,
      data: { customer_id: value },
    })

    return this.$router.push(`/customer/${value}`)
  }

  if (this.addDialog.action === `SET_CUSTOMER`) {
    this.updateData({
      id: this.data.id,
      data: { customer_id: value },
    })
  }

  if (this.addDialog.action === `SET_RESELLER`) {
    this.updateData({
      id: this.data.id,
      data: { resell_id: value },
    })
  }

  if (this.addDialog.action === `SET_COURIER`) {
    this.selectedCourier = value
    return this.setDeliveryCost()
  }

  if (this.addDialog.action === `SET_DELIVERYCOST`) {
    return this.updateData({
      id: this.data.id,
      data: {
        courier_id: this.selectedCourier,
        cost_delivery: value.cost_delivery,
      },
    })
  }
}

methods.tagtoOrder = async function() {
  this.$refs.ConfirmDialog.confirm(`ORDER`, {
    confmsg: `Apakah anda yakin ingin mengubah status menjadi`,
    title: `ORDERED`,
  })
}

methods.tagtoSent = async function() {
  this.$refs.ConfirmDialog.confirm(`SEND`, {
    confmsg: `Apakah anda yakin ingin mengubah status menjadi`,
    title: `SENT`,
  })
}

methods.tagtoCanceled = async function() {
  this.$refs.ConfirmDialog.confirm(`CANCEL`, {
    confmsg: `Apakah anda yakin ingin membatalkan transaksi ini`,
    title: ``,
  })
}

methods.tagtoLock = async function() {
  this.$refs.ConfirmDialog.confirm(`LOCK`, {
    confmsg: `Apakah anda yakin ingin mengunci record ini`,
  })
}

methods.confirmAction = async function(action) {
  if (action === `ORDER`) return this.updateStatusOrder()
  if (action === `SEND`) return this.updateStatusSent()
  if (action === `LOCK`) return this.lockData()
  if (action === `CANCEL`) {
    if (!this.data.total_paid) {
      return this.confirmCancelTransaction({ amount: 0 })
    }


    return this.$refs.CancelTransactionDialog.show()
  }
}

methods.confirmDelete = async function() {
  this.$refs.DeleteDialog.confirm(this.data.id, this.data.record_id)
}

methods.confirmCancelTransaction = async function(params) {
  if (!params.amount) return this.updateStatusCanceled()

  params.amount = params.amount * -1
  const isRefundSuccess = await this.modelPay(params)

  if (isRefundSuccess) return this.updateStatusCanceled()
}

methods.deleteData = async function() {
  await this.removeData(this.data.id)
  this.goback()
}

methods.invoiceDownload = async function() {
  const fileurl = await this.invoiceGenerate()

  printJS({
    printable: fileurl,
    type:'pdf',
    scanStyles: false,
    showModal:true
  })
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      if (n.id !== o.id) {
        this.fetchListItem()
        this.fetchListPayment()
      }
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
  this.$store.commit(`add_breadcrumb`, this.data.record_id)
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  const id = this.$route.params.id

  if (!this.data.id || this.data.id !== id) await this.fetchData(id)

  this.$store.commit(`set_page_title`, `Kasir`)
  this.$store.commit(`set_breadcrumb`, [ `Data Pembelanjaan`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
  a.link > span {
    color: #646464;
    border-bottom: 1px dashed #aaaaaa;
  }
</style>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          DETAIL RECORD

          <ButtonR text="Hapus Record" color="danger"
            v-if="!data.locked && data.isDraft"
            @click="confirmDelete"
          />
          <ButtonR text="Kunci Record" color="success"
            v-if="((data.isSent && data.isPaid) || data.isCanceled) && !data.locked"
            @click="tagtoLock"
          />
          <ButtonR text="Batalkan" color="danger"
            v-if="(data.isOrdered || data.isSent) && (data.status_order !== 'CANCELED')"
            @click="tagtoCanceled"
          />
          <ButtonR text="Order" color="primary"
            v-if="!data.locked && data.isDraft"
            @click="tagtoOrder"
          />
          <ButtonR text="Barang Terkirim" color="primary"
            v-if="!data.isDraft && !data.isSent && !data.isCanceled"
            @click="tagtoSent"
          />
          <ButtonR text="Pembayaran" color="primary"
            v-if="!data.isDraft"
            @click="dopayment"
          />
          <ButtonR text="Inovice" color="primary"
            v-if="!data.isDraft"
            @click="invoiceDownload"
          />
        </template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Record ID</label>
                  <p>{{ data.record_id }}</p>
                </Col12>
                <Col12>
                  <label>Reseller</label>
                  <p v-if="data.isSent">
                    <span>{{ data.resell_name || `-` }}</span>
                  </p>
                  <p v-else>
                    <a class="link" href="#" @click.prevent="setReseller">
                      <span v-if="data.resell_id">{{ data.resell_name }}</span>
                      <span v-else><i>Tanpa reseller</i></span>
                    </a>
                  </p>
                </Col12>
                <Col12>
                  <label>Customer</label>
                  <p v-if="data.isSent">
                    <span>{{ data.customer_name || `-` }}</span>
                  </p>
                  <p v-else>
                    <a class="link" href="#" @click.prevent="setCustomer">
                      <span v-if="data.customer_id">{{ data.customer_name }}</span>
                      <span v-else><i>Tanpa customer</i></span>
                    </a>
                  </p>
                </Col12>
                <Col12>
                  <label>Jenis Harga</label>
                  <p>{{ data.use_store_discount_readable }}</p>
                </Col12>
                <Col12>
                  <label>Note</label>
                  <p>
                    <span v-if="data.locked">{{ data.note || `-` }}</span>
                    <TextAreaChangable
                      v-else
                      placeholder="Tidak ada keterangan"
                      :rows=3
                      :forceupper=true
                      :value="data.note"
                      @submit="updateRecordNote($event)"/>
                  </p>
                </Col12>
              </Row>
            </div>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Status Order</label>
                  <p>{{ data.status_order }}</p>
                </Col12>
                <div class="col-md-6">
                  <label>Petugas</label>
                  <p>{{ data.employee_name || `-` }}</p>
                </div>
                <div class="col-md-6">
                  <label>Kurir</label>
                  <p v-if="data.isSent">
                    <span>{{ data.courier_name || `-` }}</span>
                  </p>
                  <p v-else>
                    <a class="link" href="#" @click.prevent="setCourier">
                      <span v-if="data.courier_id">{{ data.courier_name }}</span>
                      <span v-else><i>Tanpa kurir</i></span>
                    </a>
                    <a v-if="data.courier_id" class="link" href="#" @click.prevent="resetCourier">
                      <fa-close class="text-danger" style="margin-left: 10px;" />
                    </a>
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Tanggal Order</label>
                  <p v-if="data.status_order === `DRAFT`">-</p>
                  <p v-else>{{ data.date_ordered_readable }}</p>
                </div>
                <div class="col-md-6">
                  <label>Tanggal Dikirim</label>
                  <p>{{ data.date_sent_readable || `-` }}</p>
                </div>
                <div class="col-md-6">
                  <label>Status Pembayaran</label>
                  <p>
                    <label class="label label-danger" v-if="data.status_paid">Lunas</label>
                    <label class="label label-warning" v-else>Belum Lunas</label>
                    <span> {{ data.date_paid_readable }}</span>
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Sisa Pembayaran</label>
                  <p>Rp. {{ data.total_unpaid_readable }}</p>
                </div>
              </Row>
            </div>
          </Row>
        </template>

        <template #footer>
          <ButtonFormSet v-if="!isProfileLocked"
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="reset"
          />
        </template>
      </BlockTable>

      <BlockTable>
        <template #header>
          DAFTAR BARANG

          <ButtonR v-if="!data.isSent"
            text="Tambah Paket"
            color="primary"
            @click="addProductPackage" />

          <ButtonR v-if="!data.isSent"
            text="Tambah Service"
            color="primary"
            @click="addProductService" />

          <ButtonR v-if="!data.isSent"
            text="Tambah Barang"
            color="primary"
            @click="addProductItem" />
        </template>

        <template #content>
          <ItemList />
        </template>
      </BlockTable>
    </Row>

    <DeleteDialog ref="DeleteDialog" @submit="deleteData($event)" />
    <ConfirmDialog ref="ConfirmDialog" @submit="confirmAction($event)" />
    <CancelTransactionDialog ref="CancelTransactionDialog" @submit="confirmCancelTransaction" />

    <SelectListDialog
      id="AddItemDialog"
      ref="AddItemDialog"
      :options="addDialog.options"
      :addbutton="addDialog.addbutton"
      @addaction="addDialogAddAction"
      @select="addDialogAction($event)" />

    <CustomerEntryDialog ref="CustomerEntryDialog"
      @success="addDialogAction($event)"
    />

    <CourierSelectedDialog ref="CourierSelectedDialog"
      @success="addDialogAction($event)"
    />
  </layout>
</template>