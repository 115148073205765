
import Promise from 'bluebird'
import moment from 'moment'

import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()
const defaultDate = moment()

/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  summary: {
    income: 0,
    total_other_income: 0,
    total_store_purchase: 0,
    total_omzet: 0,
  },

  listOptions: {
    datefrom: defaultDate.format(`DD/MM/YYYY`),
    dateto: defaultDate.format(`DD/MM/YYYY`),
  },
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([])

    query.push(`datefrom=${opt.datefrom}`)
    query.push(`dateto=${opt.dateto}`)

    return query.string
  },

  summary(state) {
    return {
      ...state.summary,
      get income() { return this.total_sales - this.total_cost_modal },
      get omzet() { return this.income + this.total_other_income - this.total_store_purchase },
    }
  }
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchData({ commit, getters }) {
    commit(`loading_mode`, 1)

    const asyncTask = []

    asyncTask.push(async () => {
      return api.get(`/cashaccount/summarize?` + getters.listOptions)
        .then(({ data }) => {
          commit(`set_list`, data)
        })
        .catch(err => {
          commit(`show_error`, err.message)
          return 0
        })
    })

    return Promise.map(asyncTask, t => t())
      .then(() => commit(`loading_mode`, 0))
  },

  async fetchDataCurrentPeriod({ commit, getters }, { override = true } = {}) {
    commit(`loading_mode`, 1)

    let query = ''
    query += 'datefrom=' + moment(defaultDate).startOf('month').format('DD/MM/YYYY')
    query += '&dateto=' + moment(defaultDate).endOf('month').format('DD/MM/YYYY')

    return api.get(`/cashaccount/summarize?` + query)
      .then(({ data }) => {
        if (override) {
          commit(`set_list`, data)
        }

        commit(`loading_mode`, 0)

        return data
      })
      .catch(err => {
        commit(`show_error`, err.message)
        commit(`loading_mode`, 0)
        return null
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_summary(state, data) {
    state.summary = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}