
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Product/Supplier/list`,
  meta: `Product/Supplier/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/Supplier/setListOption`,
  reload: `Product/Supplier/fetchList`,
  pagePrev: `Product/Supplier/pagePrev`,
  pageNext: `Product/Supplier/pageNext`,
  deleteItem: `Product/Supplier/remove`,
})

methods.edit = function (id) {
  this.$router.push(`/supplier/${id}`)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="240" class="text-left">Nama Supplier</th>
        <th class="text-left">Asal Pengiriman</th>
        <th width="120" class="text-left">Kontak Person</th>
        <th width="120" class="text-left">No Rekening</th>
        <th width="120" class="text-right">Produk</th>
        <th width="40" class="text-right"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/supplier/${item.id}`">
              {{ item.name }}
            </router-link>
          </td>
          <td class="text-left">
            {{ item.city }}
            <p class="subdata">{{ item.address }}</p>
          </td>
          <td class="text-left">
            {{ item.cp_name }}
            <p class="subdata">{{ item.cp_phone }}</p>
          </td>
          <td class="text-left">
            {{ item.account_bank }}
            <p class="subdata">{{ item.account_bank_no }}</p>
          </td>
          <td class="text-right">{{ item.product_count }} item</td>
          <td class="text-right">
            <TButtonDelete @click="$refs.DeleteDialog.confirm(item.id, item.name)" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>