<script>
export default {
  created() {
  }
}
</script>

<template>
  <div class="content overflow-hidden">
    <div class="row">
      <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">

        <router-view />

      </div>
    </div>
  </div>
</template>

<style scoped>
  body {
    background-color: #666666;
  }
</style>
