
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
const components = {
    //
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    description: {},
    filter_datefrom: ``,
    filter_dateto: ``,
    showNoteEnabled: false,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Finance/CashAccountBalanceSummarize/list`,
  meta: `Finance/CashAccountBalanceSummarize/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Finance/CashAccountBalanceSummarize/setListOption`,
  reload: `Finance/CashAccountBalanceSummarize/fetchData`,
})



/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <Row>

    <TableList style="overflow-x: scroll">
      <template #head>
        <th width="100" class="text-left"></th>
        <th v-for="item in list" :key="`header-${item.id}`" width="100" class="text-right">
          {{ item.name }}
        </th>
      </template>

      <template #body>
        <tr>
          <td><label>Saldo Akhir Kas</label></td>
          <td class="text-right text-success" v-for="item in list" :key="`saldo-kas-${item.id}`">
            <span v-html="item.accounts.account.readable()" />
          </td>
        </tr>
        <tr>
          <td><label>&nbsp;</label></td>
          <td class="text-right" v-for="item in list" :key="`space-1-${item.id}`">
            &nbsp;
          </td>
        </tr>
        <tr>
          <td><label>Jurnal</label></td>
          <td class="text-right text-danger" v-for="item in list" :key="`jurnal-${item.id}`">
            <span v-html="item.accounts.journal.readable()" />
          </td>
        </tr>
        <tr>
          <td><label>Akun</label></td>
          <td class="text-right text-danger" v-for="item in list" :key="`akun-${item.id}`">
            <span v-html="item.accounts.operational.readable()" />
          </td>
        </tr>
        <tr>
          <td><label>Modal</label></td>
          <td class="text-right text-danger" v-for="item in list" :key="`modal-${item.id}`">
            <span v-html="item.accounts.modal.readable()" />
          </td>
        </tr>
        <tr>
          <td><label>&nbsp;</label></td>
          <td class="text-right" v-for="item in list" :key="`space-1-${item.id}`">
            &nbsp;
          </td>
        </tr>
        <tr>
          <td><label>Surplus</label></td>
          <td class="text-right" v-for="item in list" :key="`modal-${item.id}`">
            <span v-html="item.accounts.surplus.readable()" />
          </td>
        </tr>
      </template>
    </TableList>

  </Row>
</template>