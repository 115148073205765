
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import moment from 'moment'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  itemlist: [],
  paymentlist: [],
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  listOptions({ listOptions: opt }) {
    const query = new QueryString([
      `limit=${opt.pagelimit}`,
      `skip=` + (opt.pagenumber - 1) * opt.pagelimit,
      `type=report`,
    ])

    if (opt.format) query.push(`format=${opt.format}`)
    if (opt.search) query.push(`search=${opt.search}`)
    if (opt.datefrom) query.push(`datefrom=${opt.datefrom}`)
    if (opt.dateto) query.push(`dateto=${opt.dateto}`)

    return query.string
  },

  data({ selectedObject: item }) {
    if (!Object.keys(item).length) return {}

    return {
      ...item,
      get total_expenditure_readable() {
        return this.total_expenditure.toLocaleString()
      },
      get total_paid_readable() {
        return this.total_paid.toLocaleString()
      },
      get total_unpaid() {
        return this.total_expenditure - this.total_paid
      },
      get total_unpaid_readable() {
        return this.total_unpaid.toLocaleString()
      },
      get locked_date_readable() {
        if (!this.locked_date) return ``
        return moment(this.locked_date).format(`DD/MM/YYYY HH:mm:ss`)
      },
      get date_ordered_readable() {
        if (!this.date_ordered) return ``
        return moment(this.date_ordered).format(`DD/MM/YYYY`)
      },
      get date_received_readable() {
        if (!this.date_received) return ``
        return moment(this.date_received).format(`DD/MM/YYYY`)
      },
      get date_paid_readable() {
        if (!this.date_paid) return ``
        return moment(this.date_paid).format(`DD/MM/YYYY`)
      },
      get isDraft() {
        if (this.locked) return false
        return this.status_order === `DRAFT`
      },
      get isOrdered() {
        if (this.locked) return true
        return this.status_order === `ORDERED`
      },
      get isReceived() {
        if (this.locked) return true
        return this.status_order === `RECEIVED`
      },
      get isCanceled() {
        if (this.locked) return true
        return this.status_order === `CANCELED`
      },
      get isPaid() {
        if (this.locked) return true
        if (this.status_order === `DRAFT`) return false
        return this.status_paid
      },
    }
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get date_ordered_readable() {
        if (!this.date_ordered) return ``
        return moment(this.date_ordered).format(`DD/MM/YYYY`)
      },
      get total_expenditure_readable() {
        return this.total_expenditure.toLocaleString()
      },
    }))
  },

  itemlist({ itemlist }) {
    return itemlist.map((item) => ({
      ...item,
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get price_bought_readable() { return this.price_bought.toLocaleString() },
      get subtotal() { return this.price_bought * this.quantity },
      get subtotal_readable() { return this.subtotal.toLocaleString() },
    }))
  },

  paymentlist({ paymentlist }) {
    return paymentlist.map((item) => ({
      ...item,
      get amount_readable() { return this.amount.toLocaleString() },
      get date_readable() {
        if (!this.date) return ``
        return moment(this.date).format(`DD/MM/YYYY`)
      },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/expenditure?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchOptions({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/expenditure?format=options`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_options`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/expenditure/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async fetchListItem({ commit, state }) {
    const id = state.selectedObject.id

    if (!id) return commit(`clear_listitem`)

    commit(`loading_mode`, 1)

    return api.get(`/expenditure/${id}/item`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_listitem`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchListPayment({ commit, state }) {
    const id = state.selectedObject.id

    if (!id) return commit(`clear_listpayment`)

    commit(`loading_mode`, 1)

    return api.get(`/expenditure/${id}/payment`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_listpayment`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_listitem(state, list) {
    state.itemlist = list
  },

  clear_listitem(state) {
    state.itemlist = []
  },

  set_listpayment(state, list) {
    state.paymentlist = list
  },

  clear_listpayment(state) {
    state.paymentlist = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}