<script>
import forceucase from '@/helpers/forminput-forceupper'

export default {
  props: {
    value: null,
    allow: null,
    placeholder: null,
    forceupper: null,
  },
  data: () => ({
    editmode: 0,
    inputValue: ``,
  }),
  computed: {
    filterExp() {
      if (!this.allow) return false
      return RegExp('[^' + this.allow + ']', `g`)
    },
  },
  methods: {
    submit() {
      this.$emit(`submit`, this.inputValue)
      this.editmode = 0
    },
    handle(event) {
      this.inputValue = event.target.value
    },
    keypress(event) {
      if (this.filterExp && event.key.match(this.filterExp)) {
        event.preventDefault()
        return false
      }

      if (this.forceupper) forceucase(event)
    },
    paste(event) {
      const pastedText = event.clipboardData.getData('text')
      event.pastedText = pastedText

      if (this.filterExp && pastedText.match(this.filterExp)) {
        const filteredText = pastedText.replace(this.filterExp, ``)
        event.pastedText = filteredText
      }

      if (this.forceupper) forceucase(event)
    }
  },
}
</script>

<style scoped>
  .text-editable {
    color: #646464;
    border-bottom: 1px dashed #aaaaaa;
  }
</style>

<template>
  <div>
    <a v-if="!editmode" href="#" @click.prevent="editmode = 1">
      <span class="text-editable" v-if="value">{{ value }}</span>
      <span class="text-editable" v-if="!value && placeholder"><i>{{ placeholder }}</i></span>
    </a>

    <input v-else v-focus class="form-control"
      @blur="editmode = 0"
      @input="handle"
      @keypress="keypress"
      @paste="paste"
      @keyup="($event.keyCode === 13) && submit()"
    />
  </div>
</template>