
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
const components = {
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    attendStatusOptions: [
      { label: `Tidak Hadir`, value: 10 },
      { label: `Sakit`, value: 11 },
      { label: `Izin`, value: 12 },
      { label: `Hadir`, value: 20 },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Employee/Presence/list`,
  meta: `Employee/Presence/listMeta`,
  dategroup: `Employee/Presence/dategroup`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Employee/Presence/setListOption`,
  reload: `Employee/Presence/fetchList`,
  update: `Employee/Presence/update`,
})

methods.setFilterDateGroup = function (value) {
  this.setListOption({ dategroup: value })
}

methods.attendStatus = function (status) {
  const label = this.attendStatusOptions.find(i => i.value === status)

  return label ? label.label : null
}

methods.updateRecord = function (employee_id, field, value) {
  const data = {
    employee_id,
    [field]: value
  }

  this.update({ data })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({
    dategroup: moment().format(`DD/MM/YYYY`)
  })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
}

/**
 * Event Socket
 *
 */
const sockets = {
  EMPLOYEE_ATTENDANCE_DATA_UPDATED({ date_group }) {
    if (date_group === this.dategroup) {
      this.reload()
    }
  }
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>

    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li><SelectDateChangable :value="meta.filter.dategroup" @input="setFilterDateGroup" /></li>
            </ul>
          </td>
        </tr>
      </table>
    </Col6>

    <Col12>
      <br style="clear: both">
    </Col12>

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="140" class="text-left">Status Karyawan</th>
        <th width="240" class="text-left">Nama Karyawan</th>
        <th width="140" class="text-left">Kehadiran</th>
        <th width="100" class="text-left">Jam</th>
        <th class="text-left">Keterangan</th>
        <th width="100" class="text-right">Potongan</th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.division }}</td>
          <td class="text-left">{{ item.name.toUpperCase() }}</td>
          <td class="text-left">
            <SelectFieldChangable
              placeholder="(Belum Absen)"
              :value="attendStatus(item.attend_status)"
              :formValue="item.attend_status"
              :options="attendStatusOptions"
              @submit="updateRecord(item.id, `attend_status`, $event)"
            />
          </td>
          <td class="text-left">{{ item.date }}</td>
          <td class="text-left">
            <TextFieldChangable
              placeholder="(Tidak ada keterangan)"
              :value="item.note"
              @submit="updateRecord(item.id, `note`, $event)"
            />
          </td>
          <td class="text-right">
            <TextFieldChangable
              placeholder="0"
              :value="item.deduction.abs()"
              @submit="updateRecord(item.id, `deduction`, $event)"
            />
          </td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>