<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
import SelectListDialog from '@/components/uicomp/ModalDialog-Form-List'
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import ConfirmDialog from '@/components/uicomp/ModalDialog-Confirm'
const components = {
  layout,
  ItemList,
  SelectListDialog,
  DeleteDialog,
  ConfirmDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Transaction/loadingState`,
    data: `Product/Transaction/data`,
    itemOptions: `Product/Item/options`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Transaction/fetchData`,
  updateData: `Product/Transaction/update`,
  removeData: `Product/Transaction/remove`,
  lockData: `Product/Transaction/lock`,
  updateListItem: `Product/Transaction/updateListItem`,
})

methods.goback = function() {
  this.$router.push(`/product/transaction`)
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateRecordNote = async function(note) {
  return this.updateData({
    id: this.data.id,
    data: { note },
  })
}

methods.addProductItem = function() {
  this.$refs.AddItemDialog.show()
}

methods.addProduct = async function(product_id, quantity) {
  await this.updateListItem({ product_id, quantity })
}

methods.tagtoLock = async function() {
  this.$refs.ConfirmDialog.confirm(`LOCK`, {
    confmsg: `Apakah anda yakin ingin mengunci record ini`,
  })
}

methods.confirmAction = async function(action) {
  if (action === `LOCK`) return this.lockData()
}

methods.confirmDelete = async function() {
  this.$refs.DeleteDialog.confirm(this.data.id, this.data.record_id)
}

methods.deleteData = async function() {
  await this.removeData(this.data.id)
  this.goback()
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.data.record_id))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Produk dan Inventory`)
  this.$store.commit(`set_breadcrumb`, [ `Inventory`, `Data Transaksi` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          DETAIL RECORD

          <ButtonR text="Hapus Record" color="danger"
            v-if="!data.locked"
            @click="confirmDelete"
          />
          <ButtonR text="Kunci Record" color="primary"
            v-if="!data.locked"
            @click="tagtoLock"
          />
        </template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Record ID</label>
                  <p>{{ data.record_id }}</p>
                </Col12>
                <Col12>
                  <label>Jenis Transaksi</label>
                  <p>{{ data.type_readable }}</p>
                </Col12>
                <Col12>
                  <label>Note</label>
                  <p>
                    <span v-if="data.locked" v-html="data.note_html" />
                    <TextAreaChangable
                      v-else
                      placeholder="Tidak ada keterangan"
                      :rows=3
                      :forceupper=true
                      :value="data.note"
                      @submit="updateRecordNote($event)"/>
                  </p>
                </Col12>
              </Row>
            </div>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Petugas</label>
                  <p>{{ data.employee_name || `-` }}</p>
                </Col12>
                <div class="col-md-6">
                  <label>Tanggal Input</label>
                  <p>{{ data.date_readable }}</p>
                </div>
              </Row>
            </div>
          </Row>
        </template>

        <template #footer>
          <ButtonFormSet v-if="!isProfileLocked"
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="reset"
          />
        </template>
      </BlockTable>

      <BlockTable>
        <template #header>
          DAFTAR BARANG

          <ButtonR v-if="!data.locked"
            text="Tambah Barang"
            color="primary"
            @click="addProductItem" />
        </template>

        <template #content>
          <ItemList />
        </template>
      </BlockTable>
    </Row>

    <DeleteDialog ref="DeleteDialog" @submit="deleteData($event)" />
    <ConfirmDialog ref="ConfirmDialog" @submit="confirmAction($event)" />

    <SelectListDialog
      id="AddItemDialog"
      ref="AddItemDialog"
      :options="itemOptions"
      @select="addProduct($event, 1)" />
  </layout>
</template>