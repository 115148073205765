<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import ItemList from './EntryItemList'
const components = {
  layout,
  ItemList,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    account: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/SOpname/loadingState`,
    data: `Product/SOpname/data`,
    itemlistCount: `Product/SOpname/itemlistCount`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/SOpname/fetchData`,
  fetchListItem: `Product/SOpname/fetchListItem`,
  refetchData: `Product/SOpname/refetchData`,
  updateData: `Product/SOpname/update`,
  lockData: `Product/SOpname/lock`,
})

methods.goback = function() {
  this.$router.push(`/inventory/opname`)
}

methods.reset = function() {
  this.input = Object.assign({}, this.data)
  this.isProfileLocked = true
}

methods.updateRecordNote = async function(note) {
  return this.updateData({
    id: this.data.id,
    data: { note },
  })
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.data.record_id))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Inventory`)
  this.$store.commit(`set_breadcrumb`, [ `Stok Opname`, `List` ])
}

/**
 * Event Socket Emitted
 *
 */
const sockets = {
  SALES_DATA_UPDATED: function () {
    this.refetchData()
    this.fetchListItem()
  },
  EXPENDITURE_DATA_UPDATED: function () {
    this.refetchData()
    this.fetchListItem()
  },
  PRODTRX_DATA_UPDATED: function () {
    this.refetchData()
    this.fetchListItem()
  },
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
  sockets,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable>
        <template #header>
          DETAIL RECORD

          <ButtonR text="Kunci Record" color="danger"
            @click="lockData"
          />
        </template>

        <template #content>
          <Row>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Record ID</label>
                  <p>{{ data.record_id }}</p>
                </Col12>
                <Col12>
                  <label>Petugas</label>
                  <p>{{ data.employee_name || `-` }}</p>
                </Col12>
                <Col12>
                  <label>Note</label>
                  <TextAreaChangable
                    width="12"
                    :rows=3
                    :forceupper=true
                    :value="data.note"
                    placeholder="Tidak ada keterangan"
                    @submit="updateRecordNote($event)"/>
                </Col12>
              </Row>
            </div>
            <div class="col-md-6">
              <Row>
                <Col12>
                  <label>Tanggal</label>
                  <p>{{ data.date_readable }}</p>
                </Col12>
                <Col12>
                  <label>Status</label>
                  <p>
                    <label class="label label-success" v-if="data.locked">Locked</label>
                    <label class="label label-warning" v-else>Open</label>
                    <span> {{ data.locked_date_readable }}</span>
                  </p>
                </Col12>
                <div class="col-md-6">
                  <label>Total Modal Overload</label>
                  <p>Rp. {{ data.overload_value_readable }}</p>
                </div>
                <div class="col-md-6">
                  <label>Total Modal Hilang</label>
                  <p>Rp. {{ data.missing_value_readable }}</p>
                </div>
              </Row>
            </div>
          </Row>
        </template>

        <template #footer>
          <ButtonFormSet v-if="!isProfileLocked"
            labelcancel="Tutup"
            @submit="updateProfile"
            @cancel="reset"
          />
        </template>
      </BlockTable>

      <BlockTable>
        <template #header>
          DAFTAR BARANG
        </template>

        <template #content>
          <ItemList />
        </template>
      </BlockTable>
    </Row>
  </layout>
</template>