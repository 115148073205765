<template>
  <button
    :type="datatype"
    :data-toggle="datatoggle"
    :title="titletoggle"

    :class="[
      `btn`,
      `btn-${size || 'md'}`,
      color && `btn-${color || 'default'}`,
      block && `btn-block`,
    ]"
    :disabled="disabled"
    @click="$emit(`click`)"
    >

    <i v-if="icon && iconpos !== `right`"
      :class="[`fa`, `fa-${icon}`, (text) ? `push-${iconMargin}-r` : ``]" />

    <span>{{ text }}</span>
    <slot />

    <i v-if="icon && iconpos === `right`"
      :class="[`fa`, `fa-${icon}`, (text) ? `push-${iconMargin}-l` : ``]" />

  </button>
</template>

<script>
// @ is an alias to /src

export default {
  props: {
    size: String,
    color: String,
    text: String,
    icon: String,
    iconpos: String,
    tooltip: String,
    type: String,
    block: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      iconMargin: (this.size !== `xs`) ? 10 : 5
    }
  },
  computed: {
    datatoggle() {
      return (this.tooltip) ? `tooltip` : ``
    },
    datatype() {
      return (this.type) ? this.type : `submit`
    },
    titletoggle() {
      return (this.tooltip) ? this.tooltip : ``
    },
  }
}
</script>

<style scoped>
</style>