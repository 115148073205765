<script>
import Chart from 'chart.js'

export default {
    name: 'WeeklyTransactionChart',
    props: {
        data: Object,
    },
    data: () => ({
        context: null,
        chart: null,

        chartdata: {
            labels: [],
            datasets: [
                {
                    label: 'Minggu Ini',
                    backgroundColor: 'rgba(84, 105, 63, .4)',
                    borderColor: 'rgba(84, 105, 63, .55)',
                    borderWidth: 2,
                    pointBackgroundColor: 'rgba(84, 105, 63, .55)',
                    pointRadius: 5,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(84, 105, 63, 1)',
                    data: [],
                },
                {
                    label: 'Minggu Lalu',
                    backgroundColor: 'rgba(44, 52, 63, .2)',
                    borderColor: 'rgba(44, 52, 63, .25)',
                    borderWidth: 2,
                    pointBackgroundColor: 'rgba(44, 52, 63, .25)',
                    pointRadius: 5,
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(44, 52, 63, 1)',
                    data: [],
                }
            ]
        },
        options: {
            animation: {
                duration: 0,
            },
            hover: {
                intersect: false,
                animationDuration: 0,
            },
            tooltips: {
                mode: `index`,
                titleFontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                bodyFontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                displayColors: false,
            },
            legend: {
                display: false,
                labels: {
                    fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                }
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            // display: false,
                            lineWidth: 1,
                            color: 'rgba(0, 0, 0, .05)',
                        },
                        ticks: {
                            fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            lineWidth: 1,
                            color: 'rgba(0, 0, 0, .05)',
                        },
                        ticks: {
                            suggestedMin: 0,
                            fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                        }
                    }
                ],
            }
        },
    }),
    methods: {
        initChart() {
            this.chart = new Chart(this.context, {
                type: `line`,
                data: this.chartdata,
                options: this.options,
            })
        },
        updateChart(data) {
            this.chartdata.labels = data.labels
            this.chartdata.datasets.forEach((dataset, i) => {
                dataset.data = data.datasets[i]
            })
            this.chart.update();
        }
    },
    mounted() {
        this.context = this.$refs.weeklyChart
        this.initChart()
    },
    created() {
    }
}
</script>

<template>
    <canvas ref="weeklyChart" id="weekly-chart"></canvas>
</template>