<script>
let picker = null

export default {
  props: {
    value: String,
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
  mounted() {
    picker = window.$(this.$refs.dpicker).datepicker({
      autoclose: true,
      todayHighlight: false ,
    })
    picker.datepicker(`show`)
    picker.on(`keypress`, (e) => {
      e.preventDefault()
    })
    picker.on(`changeDate`, (e) => {
      e.preventDefault()
      this.$emit(`submit`, e.target.value)
    })
  },
}
</script>

<template>
  <input v-focus
    style="width: auto"
    ref="dpicker"
    class="dpicker form-control"
    type="text"
    data-date-format="dd/mm/yyyy"
    placeholder="dd/mm/yyyy"
    :value="this.value"
    >
</template>