
import socket from './socket'
import moment from 'moment'

/**
 * Const
 *
 */

const navSingle = (title, icon, href, hasPerm) => ({ type: 'single', title, icon, href, hasPerm })
const navHeading = (title, hasPerms) => ({ type: 'heading', title, hasPerms })
const navGroup = (title, icon, child) => ({ type: 'group', title, icon, child })

const sidebarList = [
  navSingle(`Dashboard`, `rocket`, `/`),

  navHeading(`Manajemen Toko`, [`ACCESS_STCONF_(.+)_R`, `ACCESS_EMP(.+)_R`]),
  navSingle(`General`, `settings`, `/store/setting`, `ACCESS_STCONF_GENERAL_R`),
  navSingle(`Rekening Keuangan`, `wallet`, `/store/cashaccount`, `ACCESS_STCONF_CACCOUNT_R`),
  navSingle(`Metode Pembayaran`, `credit-card`, `/store/paymethod`, `ACCESS_STCONF_MPAYMENT_R`),
  navGroup(`Data Karyawan`, `users`, [
    { title: `List Karyawan`, href: `/employee`, hasPerm: `ACCESS_EMPLIST_R` },
    { title: `Absensi`, href: `/employee/presence`, hasPerm: `ACCESS_EMPPRESENCE_R` },
  ]),

  navHeading(`Produk dan Inventori`, [`ACCESS_CAT(.+)_R`, `ACCESS_INV(.+)_R`, `ACCESS_CATSUPPLIER_R`, `ACCESS_EXPND_R`, `ACCESS_REPEXPNDITEM_R`]),
  navGroup(`Katalog Produk`, `social-dropbox`, [
    { title: `Data Brand`, href: `/product/brand`, hasPerm: `ACCESS_CATBRAND_R` },
    { title: `Data Kategori`, href: `/product/category`, hasPerm: `ACCESS_CATCATEGORY_R` },
    { title: `Data Barang`, href: `/product/item`, hasPerm: `ACCESS_CATITEM_R` },
    { title: `Data Layanan`, href: `/product/service`, hasPerm: `ACCESS_CATSERVICE_R` },
    { title: `Paket Produk`, href: `/product/package`, hasPerm: `ACCESS_CATPACKAGE_R` },
  ]),
  navGroup(`Inventori`, `layers`, [
    { title: `Data Keluar Masuk`, href: `/product/transaction`, hasPerm: `ACCESS_INVTRANSC_R` },
    { title: `Lap. Pergerakan`, href: `/product/transaction/report`, hasPerm: `ACCESS_INVTRANSREP_R` },
    { title: `Stok Barang`, href: `/product/stock`, hasPerm: `ACCESS_INVSTOCKREC_R` },
    { title: `Stok Opname`, href: `/inventory/opname`, hasPerm: `ACCESS_INVSTOCKOP_R` },
  ]),

  navHeading(`Kasir`, [`ACCESS_SALES_R`, `ACCESS_REPSALESITEM_R`, `ACCESS_CUST_R`, `ACCESS_COUR_R`]),
  navGroup(`Penjualan`, `notebook`, [
    { title: `Data Transaksi`, href: `/sales`, hasPerm: `ACCESS_SALES_R` },
    // { title: `Data Transaksi`, href: `/rep/sales-transaction`, hasPerm: `ACCESS_REPSALESITEM_R` },
    { title: `Item Terjual`, href: `/rep/sales-transaction-item`, hasPerm: `ACCESS_REPSALESITEM_R` },
    { title: `Data Pembayaran`, href: `/rep/sales-transaction-payment`, hasPerm: `ACCESS_REPSALESITEM_R` },
  ]),
  navGroup(`Supply Produk`, `note`, [
    { title: `Data Supplier`, href: `/supplier`, hasPerm: `ACCESS_CATSUPPLIER_R` },
    { title: `Order Barang`, href: `/expenditure`, hasPerm: `ACCESS_EXPND_R` },
    // { title: `Data Transaksi`, href: `/rep/exp-transaction`, hasPerm: `ACCESS_REPEXPNDITEM_R` },
    { title: `Item Dibeli`, href: `/rep/exp-transaction-item`, hasPerm: `ACCESS_REPEXPNDITEM_R` },
    { title: `Data Pembayaran`, href: `/rep/exp-transaction-payment`, hasPerm: `ACCESS_REPEXPNDITEM_R` },
  ]),
  navGroup(`Pelanggan`, `paper-plane`, [
    { title: `Data Pelanggan`, href: `/customer`, hasPerm: `ACCESS_CUST_R` },
    { title: `Grup Pelanggan`, href: `/customer/group`, hasPerm: `ACCESS_STCONF_CUSTGROUP_R` },
  ]),
  navGroup(`Delivery`, `paper-plane`, [
    { title: `Data Kurir`, href: `/courier`, hasPerm: `ACCESS_COUR_R` },
    // { title: `Data Pengiriman`, href: `/`, hasPerm: `ACCESS_COUR_R` },
  ]),

  navHeading(`Operasional Toko`, [`ACCESS_STPURCHASE_R`]),
  navSingle(`Belanja Toko`, `basket-loaded`, `/stpurchase`, `ACCESS_STPURCHASE_R`),
  navSingle(`Pemasukan Lain`, `plus`, `/otherincome`, `ACCESS_STPURCHASE_R`),
  navSingle(`Akun`, `wallet`, `/oppurchase/transaction`, `ACCESS_STPURCHASE_R`),

  navHeading(`Laporan`, [`ACCESS_REPFINANCE_R` ]),
  navGroup(`Lap. Keuangan`, `book-open`, [
    { title: `Jurnal Harian`, href: `/rep/daily-journal`, hasPerm: `ACCESS_REPFINANCE_R` },
    { title: `Kas`, href: `/cashaccount/transaction`, hasPerm: `ACCESS_REPFINANCE_R` },
    { title: `Rekening`, href: `/cashaccount/balance-summarize`, hasPerm: `ACCESS_REPFINANCE_R` },
    // { title: `Arus Kas`, href: `/`, hasPerm: `ACCESS_REPFINANCE_R` },
    // { title: `Neraca`, href: `/`, hasPerm: `ACCESS_REPFINANCE_R` },
  ]),
]

const dateMonth = {
  labelFromCode: {
    '01': `Jan`,
    '02': `Feb`,
    '03': `Mar`,
    '04': `Apr`,
    '05': `Mei`,
    '06': `Jun`,
    '07': `Jul`,
    '08': `Agu`,
    '09': `Sep`,
    '10': `Oct`,
    '11': `Nov`,
    '12': `Dec`,
  },
  options: [
    { value: '01', label: `Jan` },
    { value: '02', label: `Feb` },
    { value: '03', label: `Mar` },
    { value: '04', label: `Apr` },
    { value: '05', label: `Mei` },
    { value: '06', label: `Jun` },
    { value: '07', label: `Jul` },
    { value: '08', label: `Agu` },
    { value: '09', label: `Sep` },
    { value: '10', label: `Oct` },
    { value: '11', label: `Nov` },
    { value: '12', label: `Dec` },
  ]
}

const dateYear = {
  startYear: 2000,
  currentYear: moment().add(1, 'year').format(`YYYY`),
  get options() {
    const opts = []
    for (let year = this.currentYear; year >= this.startYear; year -= 1) {
      opts.push({ label: year, value: year })
    }
    return opts
  }
}


/**
 * State
 *
 */
const state = {
  loading: 0,

  /** Page */
  page: {
    title: ``,
    subtitle: ``,
  },

  /** Routes */
  sidebar: {
    list: sidebarList,
  },

  /** Breadcrumb */
  breadcrumb: {
    links: [],
  },

  /** Options */
}


/**
 * Getters
 *
 */
const getters = {
  getLoadingState: (state) => state.loading > 0,

  getLinks: (state) => state.breadcrumb.links.map((title, id) => ({ id, title })),
  getLinkCount: (state) => state.breadcrumb.links.length,

  sidebarList: (state) => state.sidebar.list,

  pageTitle: ({ page }) => page.title,
  pageSubtitle: ({ page }) => page.subtitle,

  dateMonth: () => dateMonth,
  dateYear: () => dateYear,
}


/**
 * Actions
 *
 */
const actions = {
  loadState({ commit }, value) {
    commit(`loading`, value)
  },

  ...socket.actions,
}

/**
 * Mutations
 *
 */
const mutations = {
  loading(state, value) {
    state.loading += (value) ? 1 : -1
  },

  set_page_title(state, value) {
    state.page.title = value || state.title
  },

  set_page_subtitle(state, value) {
    state.page.subtitle = value || state.title
  },

  set_breadcrumb(state, value) {
    state.breadcrumb.links = value || state.breadcrumb.links
  },

  add_breadcrumb(state, value) {
    state.breadcrumb.links.push(value)
  },

  ...socket.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
