<script>
import SidebarMenu from '@/components/dashboard/SidebarMenu'

export default {
  components: {
    SidebarMenu
  }
}
</script>

<template>
  <nav id="sidebar">
    <div id="sidebar-scroll">
      <div class="sidebar-content">
        <div class="side-header side-content bg-white-op">
          <!-- Layout API, functionality initialized in App() -> uiLayoutApi() -->
          <button class="btn btn-link text-gray pull-right hidden-md hidden-lg" type="button" data-toggle="layout" data-action="sidebar_close">
            <i class="fa fa-times"></i>
          </button>

          <a class="h5 text-white" href="/dashboard">
            <span class="h4 font-w600">
              K<span class="sidebar-mini-hide">ASWARUNG</span>
            </span>
          </a>
        </div>

        <SidebarMenu />
      </div>
    </div>
  </nav>
</template>