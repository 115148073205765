
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    passwordConfirmation: ``,
  }
}

/**
 * Computed
 *
 */
const computed = {
  validate() {
    return this.input.password === this.passwordConfirmation
  }
}

/**
 * Methods
 *
 */
const methods = {}

methods.reset = function() {
  this.input = {}
  this.passwordConfirmation = ``
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.addrecord = async function() {
  this.$emit(`submit`, this.input.password)
}

export default {
  components,
  data,
  computed,
  methods,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>

      <div class="text-field col-sm-12">
        <label for="password">Password Baru</label>
        <input class="form-control"
          autocomplete="new-password"
          type="password"
          v-model="input.password"
        />
      </div>

      <div class="text-field col-sm-12">
        <label for="password-confirmation">Konfirmasi Password</label>
        <input class="form-control"
          type="password"
          v-model="passwordConfirmation"
        />
      </div>
    </FormGroup>
  </EntryDialog>
</template>