
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
const api = new Api()

/**
 * Const
 *
 */
const global = { root: true }



/**
 * State
 *
 */
const state = {
  loaded: 0,
  alert: null,
  message: ``,

  data: null,
  setting: {},
}


/**
 * Getters
 *
 */
const getters = {
  loadingState: ({ loaded }) => loaded > 0,
  errorState: (state) => state.alert,
  errorMessage: (state) => state.message,

  profile: ({ data }) => data,

  prefixSetting(state) {
    return state.setting.prefix
  },
}


/**
 * Actions
 *
 */
const actions = {
  async fetch({ commit, rootGetters }) {
    commit(`loading_mode`, 1)

    const id = rootGetters[`Auth/storeid`]

    return api.get(`/store/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async update({ commit, state, rootState }, { data }) {
    commit(`loading_mode`, 1)

    const id = state.data.id

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)

      commit('Auth/set_store', result, global)
      commit(`set_selected_object`, result)

      alert.success(`Data berhasil diupdate`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!id) {
      return onerror({ message: `Error retrieving data` })
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STCONF_GENERAL_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/store/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async updateConfig({ commit, state, dispatch, rootState }, data) {
    commit(`loading_mode`, 1)

    const id = state.data.id

    const onsuccess = async () => {
      await dispatch('fetch')

      commit(`loading_mode`, 0)
      alert.success(`Data berhasil diupdate`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!id) {
      return onerror({ message: `Error retrieving data` })
    }

    if (!rootState.Auth.permission.includes(`ACCESS_STCONF_GENERAL_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.post(`/setstconfig`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  loading_mode(state, mode) {
    mode = (mode) ? 1 : -1
    state.loaded += mode
  },

  dissmiss_alert(state) {
    state.message = ``
    state.alert = null
  },

  show_error(state, message) {
    state.message = message
    state.alert = `error`
  },

  set_selected_object(state, data) {
    state.data = data
  },

  clear_selected_object(state) {
    state.data = null
  },
}

import MonthlySummarize from './monthly-summarize'
import Info from './info'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    MonthlySummarize,
    Info,
  }
}