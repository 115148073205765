<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import SelectListDialog from '@/components/uicomp/ModalDialog-Form-List'
const components = {
  layout,
  DeleteDialog,
  SelectListDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Product/Package/loadingState`,
    data: `Product/Package/data`,
    list: `Product/Package/itemlist`,
    total: `Product/Package/itemlistMeta`,
    serviceOptions: `Product/Service/options`,
    itemOptions: `Product/Item/options`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchData: `Product/Package/fetchData`,
  fetchItem: `Product/Package/fetchListItem`,
  addItem: `Product/Package/addListItem`,
  removeItem: `Product/Package/removeListItem`,
})

methods.goback = function() {
  this.$router.go(-1)
}

methods.reset = function() {
  this.input = {}
}

methods.confirmDelete = function(id, name) {
  this.$refs.DeleteDialog.confirm(id, name)
}

methods.deleteItem = async function(product_id) {
  await this.removeItem({ product_id })
}

methods.addProductItem = function() {
  this.$refs.AddItemDialog.show()
}

methods.addProductService = function() {
  this.$refs.AddServiceDialog.show()
}

methods.addProduct = async function(product_id, quantity) {
  await this.addItem({ product_id, quantity })
}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  const id = this.$route.params.id
  Promise.resolve()
    .then(() => this.fetchData(id))
    .then(() => this.fetchItem())
    .then(() => this.reset())
    .then(() => this.$store.commit(`add_breadcrumb`, this.data.name))
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  this.$store.commit(`set_page_title`, `Manajemen Toko`)
  this.$store.commit(`set_breadcrumb`, [ `Data Paket`, `List` ])
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <layout type="narrow">
    <Row>
      <ButtonL icon="chevron-left" text="KEMBALI" color="none" @click="goback" />
    </Row>
    <Row>
      <BlockTable :width=10 :loading="isLoading">
        <template #header>
          ISI PRODUK

          <ButtonR text="Tambah Barang" color="primary" @click="addProductItem" />
          <ButtonR text="Tambah Service" color="primary" @click="addProductService" />
        </template>
        <template #content>
          <Row>
            <div class="col-xs-4">
              <label>Nama Paket</label>
              <p>{{ data.name }}</p>
            </div>
            <div class="col-xs-2">
              <label>Jumlah Item</label>
              <p>{{ total.quantity }}</p>
            </div>
            <div class="col-xs-3">
              <label>Total Modal Paket</label>
              <p>Rp. {{ total.modal_readable }}</p>
            </div>
            <div class="col-xs-3">
              <label>Total Service Cost</label>
              <p>Rp. {{ total.scost_readable }}</p>
            </div>

            <TableList>
              <template #head>
                <th width="40" class="text-center">#</th>
                <th width="80" class="text-left">Tipe</th>
                <th class="text-left">Nama Item</th>
                <th width="100" class="text-right">Modal</th>
                <th width="40" class="text-right">Jumlah</th>
                <th width="100" class="text-right">Subtotal</th>
                <th width="40"></th>
              </template>

              <template #body>
                <tr v-for="(item, index) in list" :key="item.id">
                  <td class="text-center" v-html="index + 1"></td>
                  <td class="text-left">{{ item.product_type }}</td>
                  <td class="text-left">{{ item.product_name }}</td>
                  <td class="text-right">{{ item.price_asset_and_comm_readable }}</td>
                  <td class="text-right">
                    <TextFieldChangable
                      :value="item.quantity"
                      @submit="addProduct(item.product_id, $event)"
                    />
                  </td>
                  <td class="text-right">{{ item.price_modal_readable }}</td>
                  <td class="text-right">
                    <TButtonDelete @click="confirmDelete(item.product_id, item.product_name)" />
                  </td>
                </tr>
              </template>

              <template #foot>
                <tr>
                  <td class="text-center"></td>
                  <td class="text-left"></td>
                  <td class="text-left">TOTAL</td>
                  <td class="text-right"></td>
                  <td class="text-right"><strong>{{ total.quantity }}</strong></td>
                  <td class="text-right"><strong>{{ total.modal_readable }}</strong></td>
                  <td class="text-right"></td>
                </tr>
              </template>
            </TableList>
          </Row>
        </template>
      </BlockTable>
    </Row>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />

    <SelectListDialog
      id="AddServiceDialog"
      ref="AddServiceDialog"
      :options="serviceOptions"
      @select="addProduct($event, 1)" />

    <SelectListDialog
      id="AddItemDialog"
      ref="AddItemDialog"
      :options="itemOptions"
      @select="addProduct($event, 1)" />
  </layout>
</template>