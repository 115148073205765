
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  DeleteDialog,
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Finance/OtherIncome/list`,
  meta: `Finance/OtherIncome/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Finance/OtherIncome/setListOption`,
  reload: `Finance/OtherIncome/fetchList`,
  pagePrev: `Finance/OtherIncome/pagePrev`,
  pageNext: `Finance/OtherIncome/pageNext`,
  deleteItem: `Finance/OtherIncome/remove`,
})

methods.edit = function (id) {
  this.$emit(`editrequest`, id)
}

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <TableOptions
      :nrecord="meta.nrecord"
      :npage="meta.npage"
      :curpage="meta.curpage"
      :searchValue="meta.filter.search"
      @nextpage="pageNext"
      @prevpage="pagePrev"
      @showopt="showopt"
      @search="search"
    />
    <TableOptionsDialog ref="TableOptionsDialog"
    />

    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="140" class="text-left">Tanggal</th>
        <th width="140" class="text-left">Petugas</th>
        <th class="text-left">Kebutuhan</th>
        <th width="100" class="text-right">Jumlah</th>
        <th width="200" class="text-left">Metode Bayar</th>
        <th width="40" class="text-right"></th>
      </template>

        <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">
            <span class="link underlined" @click="edit(item.id)">{{ item.date_readable }}</span>
          </td>
          <td class="text-left">{{ item.employee_name }}</td>
          <td class="text-left">
            {{ item.item }}
            <p class="subdata">{{ item.merchant }}</p>
          </td>
          <td class="text-right">{{ item.amount_readable }}</td>
          <td class="text-left">
            {{ item.method_name }}
            <p class="subdata">{{ item.approval_code }}</p>
          </td>
          <td class="text-right">
            <TButtonDelete @click="$refs.DeleteDialog.confirm(item.id, item.needs)" />
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>