<script>
import { mapGetters } from 'vuex'
import SidebarItem from '@/components/dashboard/SidebarItem'

export default {
  components: {
    SidebarItem
  },
  computed: {
    ...mapGetters({
      list: `sidebarList`,
      permission: `Auth/permission`,
    }),
    sidelist() {
      const list = JSON.parse(JSON.stringify(this.list))
      const perms = this.permission

      return list.filter(nav => {
        if (nav.type === `single` && nav.hasPerm) return perms.includes(nav.hasPerm)
        if (nav.type === `group`) {
          nav.child = nav.child.filter(child => perms.includes(child.hasPerm))
          return nav.child.length
        }
        if (nav.type === `heading` && nav.hasPerms) {
          const childs = nav.hasPerms.map((perm) => {
            const matcher = RegExp(perm)
            return perms.some(p => p.match(matcher))
          })

          return childs.some(c => c)
        }

        return 1
      })
    }
  }
}
</script>

<template>
  <div class="side-content">
    <ul class="nav-main">
      <SidebarItem
        v-for="(item, index) in sidelist"
        :key="index"
        :type="item.type"
        :title="item.title"
        :href="item.href"
        :icon="item.icon"
        :child="item.child"
      />
    </ul>
  </div>
</template>