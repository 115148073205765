
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import DeleteDialog from '@/components/uicomp/ModalDialog-Delete'
const components = {
  DeleteDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Employee/list`,
  meta: `Employee/listMeta`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Employee/setListOption`,
  reload: `Employee/fetchList`,
  deleteItem: `Employee/remove`,
  pagePrev: `Employee/pagePrev`,
  pageNext: `Employee/pageNext`,
})

methods.edit = function (id) {
  this.$router.push(`/employee/${id}`)
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="100" class="text-left">Status</th>
        <th class="text-left">Nama Karyawan</th>
        <th width="300" class="text-left">Alamat</th>
        <th width="120" class="text-left">Kontak</th>
        <th width="120" class="text-left">User</th>
        <th width="120" class="text-right"></th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left" v-html="item.department_name"></td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/employee/${item.id}`">
              {{ item.name.toUpperCase() }}
            </router-link>
          </td>
          <td class="text-left" v-html="item.address"></td>
          <td class="text-left" v-html="item.phone"></td>
          <td class="text-left">
            <div v-if="item.account_id">
              {{ item.account_email || item.account_username }}
            </div>
            <div v-else>
              <i>(Belum ada akun)</i>
            </div>
          </td>

          <td class="text-right">
            <TButtonDelete @click="$refs.DeleteDialog.confirm(item.id, item.name)" />

            <router-link :to="`/employee/sallary/${item.id}`">
              <TButton color="primary" text="Gaji" tooltip="Lihat Gaji" />
            </router-link>
          </td>
        </tr>
      </template>
    </TableList>

    <DeleteDialog ref="DeleteDialog" @submit="deleteItem($event)" />
  </Row>
</template>