
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  itemlist: [],
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  data({ selectedObject: item }) {
    if (!Object.keys(item).length) return {}

    return {
      ...item,
      cover: item.cover || `/img/defaults/product.jpg`,
      get price_store() { return this.price_unit - this.store_discount },
      get price_store_readable() { return this.price_store.toLocaleString() },
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get price_unit_readable() { return this.price_unit.toLocaleString() },
      get resell_commission_readable() { return this.resell_commission.toLocaleString() },
      get worker_commission_readable() { return this.worker_commission.toLocaleString() },
    }
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      cover: item.cover || `/img/defaults/product.jpg`,
      get price_store() { return this.price_unit - this.store_discount },
      get price_store_readable() { return this.price_store.toLocaleString() },
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get price_unit_readable() { return this.price_unit.toLocaleString() },
      get resell_commission_readable() { return this.resell_commission.toLocaleString() },
    }))
  },

  itemlistMeta({ itemlist }) {
    const base = {
      quantity: 0,
      modal: 0,
      get modal_readable() { return this.modal.toLocaleString() },
    }

    return itemlist.reduce((ac, item) => {
      ac.quantity += item.quantity
      ac.modal += (item.price_asset + item.worker_commission) * item.quantity
      return ac
    }, base)
  },

  itemlist({ itemlist }) {
    return itemlist.map((item) => ({
      ...item,
      get price_asset_and_comm() {
        return (this.price_asset + this.worker_commission)
      },
      get price_modal() {
        return (this.price_asset_and_comm) * this.quantity
      },
      get price_asset_and_comm_readable() { return this.price_asset_and_comm.toLocaleString() },
      get price_modal_readable() { return this.price_modal.toLocaleString() },
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get worker_commission_readable() { return this.worker_commission.toLocaleString() },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/package?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchOptions({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/product/package?format=options`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_options`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/product/package/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async fetchListItem({ commit, state }) {
    const id = state.selectedObject.id

    if (!id) return commit(`clear_listitem`)

    commit(`loading_mode`, 1)

    return api.get(`/product/package/${id}/item`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_listitem`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async update({ commit, dispatch, rootState }, { id, data }) {
    commit(`loading_mode`, 1)

    if (!id) id = ``

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      await dispatch(`fetchData`, result.id)

      if (id) alert.success(`Data berhasil diupdate`)
      else alert.success(`Data berhasil ditambahkan`)

      return result.id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATPACKAGE_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/product/package/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async updateCover({ commit, dispatch, rootState }, { id, media }) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)

      alert.success(`Berhasil mengganti foto`)
      await dispatch(`fetchData`, id)

      return id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATPACKAGE_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return new Promise(resolve => {
      const params = new FormData()

      media.toBlob(blob => {
        params.append(`media`, blob)

        api.put(`/product/package/${id}/cover`, params)
          .then(r => onsuccess(r.data))
          .catch(onerror)
          .then(resolve)
      })
    })
  },

  async remove({ commit, rootState }, id) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)
      commit(`clear_options`)
      // await dispatch(`fetchList`)
      alert.success(`Data berhasil dihapus`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_CATPACKAGE_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.delete(`/product/package/${id}`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async addListItem({ commit, dispatch, state }, data) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)
      await dispatch(`refetchData`)
      await dispatch(`fetchListItem`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      return 0
    }

    const id = state.selectedObject.id
    if (!id) return onerror()

    return api.put(`/product/package/${id}/item`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async removeListItem({ commit, dispatch, state }, data) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)
      await dispatch(`refetchData`)
      await dispatch(`fetchListItem`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      return 0
    }

    const id = state.selectedObject.id
    if (!id) return onerror()

    return api.delete(`/product/package/${id}/item`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_listitem(state, list) {
    state.itemlist = list
  },

  clear_listitem(state) {
    state.itemlist = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}