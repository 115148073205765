<template>
  <div :class="blockClass">
    <div v-if="header" :class="blockHeaderClass">
      <div class="push-10-t">
        <div class="block-options">
          <ul class="block-options">
            <slot name="header-option" />
            <li v-if="onclose">
              <button type="button" class="close-btn" @click="onclose">
                <i class="si si-close"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <h3 class="block-title" v-html="title" />
    </div>

    <slot name="tabs" />

    <div :class="blockContentClass">
      <slot />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: `DashboardBlock`,
  props: {
    title: String,
    color: String,
    narrow: Boolean,
    loading: Boolean,
    header: Boolean,
    onclose: {
      type: Function,
    },
  },
  computed: {
    blockClass() {
      return [
        `block`,
        (this.color) ? `block-themed` : ``,
        (this.loading) ? `block-opt-refresh` : ``,
      ]
    },
    blockHeaderClass() {
      return [
        `block-header`,
        (this.color) ? `bg-${this.color}` : ``,
      ]
    },
    blockContentClass() {
      return [
        'block-content',
        (this.narrow) ? 'block-content-narrow' : '',
      ]
    },
  }
}
</script>

<style scoped>
  .block-content {
    padding: 30px;
  }

  ul.block-options .close-btn {
    margin-top: -2px;
    font-size: 140%;
  }
</style>