
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/SOpname/data`,
  processing: `Product/SOpname/loadingState`,
  categoryOptions: `Product/Category/options`,
})
computed.validate = function() {
  return !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Product/SOpname/fetchData`,
  entry: `Product/SOpname/update`,
})

methods.reset = function() {
  this.input = { category_id: null }
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.addrecord = async function() {
  const data = this.input
  const successid = await this.entry({ data })

  if (successid) {
    this.dismiss()
    this.$router.push(`/inventory/opname/${successid}`)
  }
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Pilihan Kategori"
        defaultLabel="Semua Kategori"
        defaultValue=""
        :options="categoryOptions"
        v-model="input.category_id" />
    </FormGroup>
  </EntryDialog>
</template>