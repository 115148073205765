
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    dialogTitle: `Tambah Record`,
    input: {}
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Finance/OtherIncome/data`,
  processing: `Finance/OtherIncome/loadingState`,
  paymentMethodOptions: `PayMethod/options`,
})
computed.validate = function() {
  return !!this.input.method_id
    && !!this.input.item
    && !!this.input.merchant
    && this.input.amount > 0
    && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Finance/OtherIncome/fetchData`,
  entry: `Finance/OtherIncome/update`,
})

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  this.dialogTitle = `Tambah Record`
  return this.fetch(null).then(this.show)
}

methods.edit = async function(id) {
  this.id = id
  this.dialogTitle = `Edit Record`
  return this.fetch(id).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id
  const success = await this.entry({ id, data })

  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord" size='md'>
    <FormGroup>
        <TextField
          label="Keterangan Pemasukan"
          :forceupper=true
          v-model="input.item" />
        <TextField
          width="6"
          label="User"
          :forceupper=true
          v-model="input.merchant" />
        <TextField
          width="6"
          label="Jumlah Pembayaran"
          :forceupper=true
          v-model="input.amount" />
        <SelectField
          label="Metode Bayar"
          width="6"
          defaultLabel="-- Pilih Metode Bayar --"
          defaultValue=""
          :options=paymentMethodOptions
          v-model="input.method_id" />
        <TextField
          width="6"
          label="Ref. Pembayaran"
          :forceupper=true
          v-model="input.approval_code" />
    </FormGroup>
  </EntryDialog>
</template>