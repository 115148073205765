<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import layout from '@/layout/page-with-header'

const components = {
  layout,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
    isProfileLocked: true,
  }
}

/**
 * Computed
 *
 */
const computed = {
  ...mapGetters({
    isLoading: `Store/loadingState`,
    profile: `Store/profile`,
  }),
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetchStoreData: `Store/fetch`,
  updateStoreConfig: `Store/updateConfig`
})

methods.profileMode = function(mode) {
  if (mode === `edit`) {
    this.isProfileLocked = false
  }

  if (mode === `revert`) {
    this.reset()
  }
}

methods.reset = function() {
  const setting = this.profile.setting
  this.input = {
    RECPREF_PRODUCTTRANSACTION: setting.RECPREF_PRODUCTTRANSACTION,
    RECPREF_STOCKOPNAME: setting.RECPREF_STOCKOPNAME,
    RECPREF_SALES: setting.RECPREF_SALES,
    RECPREF_EXPENDITURE: setting.RECPREF_EXPENDITURE,
    RECPREF_CUSTOMER: setting.RECPREF_CUSTOMER,
  }
  this.isProfileLocked = true
}

methods.submit = async function() {
  await this.updateStoreConfig(this.input)
  this.reset()
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.reset()
}

/**
 * Event OnCreated
 *
 */
const created = function() {
}

export default {
  components,
  data,
  computed,
  methods,
  mounted,
  created,
}
</script>

<template>
  <BlockForm title="PENGATURAN PENGKODEAN"
    :width=6
    :loadingState="isLoading"
    :lockedState="isProfileLocked"
    @edit="profileMode(`edit`)"
    @revert="profileMode(`revert`)"
  >
    <template #header>Setting Profil Toko</template>

    <template #content>
      <FormGroup>
        <TextField
          label="Record Stok Masuk / Keluar"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.RECPREF_PRODUCTTRANSACTION">
          <template #after>000001</template>
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          label="Record Stok Opname"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.RECPREF_STOCKOPNAME">
          <template #after>000001</template>
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          label="Record Pembelanjaan"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.RECPREF_EXPENDITURE">
          <template #after>000001</template>
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          label="Record / Invoice Penjualan"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.RECPREF_SALES">
          <template #after>000001</template>
        </TextField>
      </FormGroup>

      <FormGroup>
        <TextField
          label="Record Customer"
          width="8"
          :disabled=isProfileLocked
          :forceupper=true
          :raddon=true
          v-model="input.RECPREF_CUSTOMER">
          <template #after>00001</template>
        </TextField>
      </FormGroup>
    </template>

    <template #footer v-if="!isProfileLocked">
      <ButtonFormSet
        @submit="submit"
        @cancel="profileMode(`revert`)"
      />
    </template>
  </BlockForm>
</template>