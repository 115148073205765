
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
import DescriptionDialog from './ListTable-Detail'
const components = {
  DescriptionDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    description: {},
    filter_datefrom: ``,
    filter_dateto: ``,
    showNoteEnabled: false,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  list: `Finance/CashAccountSummarize/list`,
  meta: `Finance/CashAccountSummarize/listMeta`,
  // summary: `Finance/CashAccountSummarize/summary`,
})

computed.datatable = function () {
  const records = []

  if (this.list.records) {
    this.list.records.forEach(group => {
      for (let row = 0; row < group.span; row += 1) {
        let rowdata = [ moment(group.date, `YYYYMMDD`).format(`DD/MM/YYYY`) ]
        group.rec.forEach(recdata => rowdata.push(recdata[row] || { amount: 0 }))
        records.push(rowdata)
      }
    })
  }

  return records
}

computed.datatableTotal = function () {
  const total = []

  this.datatable.forEach((row) => {
    row.forEach((rowdata, index) => {
      if (index === 0) return false
      if (!total[index - 1]) total[index - 1] = 0

      total[index - 1] += rowdata.amount
    })
  })

  return total
}

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Finance/CashAccountSummarize/setListOption`,
  reload: `Finance/CashAccountSummarize/fetchData`,
})

methods.edit = function (id) {
  this.$emit(`editrequest`, id)
}

methods.showDescription = function (id) {
  this.$refs.DescriptionDialog.show(id)
}

methods.dismissDescription = function () {
  this.$refs.DescriptionDialog.dismiss()
}



/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_datefrom: function (n, o) {
    n !== o && this.setListOption({ datefrom: n })
  },
  filter_dateto: function (n, o) {
    n !== o && this.setListOption({ dateto: n })
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  this.setListOption({ format: `deep` })
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.datefrom) {
    this.filter_datefrom = this.meta.filter.datefrom
  }

  if (this.meta.filter.dateto) {
    this.filter_dateto = this.meta.filter.dateto
  }
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
</style>

<template>
  <Row>
    <Col6>
      <table class="no-style">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
          <td width="140"></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td colspan="2">
            <ul class="list-inline-style">
              <li><SelectDateChangable :value="filter_datefrom" @input="filter_datefrom = $event" /></li>
              <li>-</li>
              <li><SelectDateChangable :value="filter_dateto" @input="filter_dateto = $event" /></li>
            </ul>
          </td>
        </tr>
      </table>
    </Col6>

    <!-- <Col6>
      <table class="no-style pull-right">
        <tr>
          <td width="160"></td>
          <td width="10"></td>
          <td></td>
          <td width="140"></td>
        </tr>
        <tr>
          <td><label>TOTAL PEMASUKAN</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.income.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL PEMASUKAN LAIN</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.total_other_income.readable() }}</td>
        </tr>
        <tr>
          <td><label>TOTAL BELANJA TOKO (-)</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.total_store_purchase.readable() }}</td>
        </tr>
        <tr class="border-top">
          <td><label>TOTAL LABA KOTOR</label></td>
          <td><label>:</label></td>
          <td>Rp.</td>
          <td class="text-right">{{ summary.omzet.readable() }}</td>
        </tr>
      </table>
    </Col6> -->

    <Col12>
      <br style="clear: both">

      <SwitchButton
        label="Tampilkan note"
        :value=showNoteEnabled
        @input="showNoteEnabled = $event"
      />
    </Col12>

    <TableList style="overflow-x: scroll">
      <template #head>
        <th width="100" class="text-left">Riwayat Transaksi</th>
        <th v-for="item in list.headers" :key="`header-${item.id}`" width="100" class="text-right">
          {{ item.name }}
        </th>
      </template>

      <template #body>
        <tr>
          <td><label>Saldo Awal</label></td>
          <td class="text-right" v-for="item in list.headers" :key="`prev-credit-total-${item.id}`">
            <span v-html="item.last_balance.readable()" />
          </td>
        </tr>
        <tr v-for="(record, dtIndex) in datatable" :key="`rec-date-${dtIndex}`">
          <td v-for="(data, dataIndex) in record" :key="`rec-data-${dataIndex}`" class="text-left">
            <div v-if="dataIndex === 0" class="pull-right">
              {{ data }}
            </div>

            <div v-else class="link" @click="showDescription(data.id)">
              <div class="text-right">
                <span v-if="!data.amount" class="text-primary-lighter" v-html="data.amount" />
                <span v-else-if="data.amount > 0" class="text-success" v-html="data.amount.readable()" />
                <span v-else class="text-danger" v-html="data.amount.readable()" />
              </div>
              <p v-if="showNoteEnabled" class="subdata" v-html="data.note" />
            </div>
          </td>
        </tr>
        <!-- <tr>
          <td><label>Pergerakan Saldo</label></td>
          <td class="text-right" v-for="item in list.headers" :key="`credit-total-${item.id}`">
            <span v-html="item.onperiod_credit.readable()" />
          </td>
        </tr> -->
        <tr class="custom-bg-primary-light">
          <td><label>Saldo Akhir</label></td>
          <td class="text-right" v-for="item in list.headers" :key="`credit-${item.id}`">
            <strong v-html="(item.last_balance + item.onperiod_credit).readable()" />
          </td>
        </tr>
      </template>
    </TableList>

    <DescriptionDialog ref="DescriptionDialog" />

  </Row>
</template>