
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    input: {},
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `CashAccount/Transaction/data`,
  processing: `CashAccount/Transaction/loadingState`,
  cashAccountOptions: `CashAccount/options`,
})

computed.validated = function () {
  const hasTargetAccountId = (this.input.type === `mutate`)
    ? !!this.input.target_account_id
    : true

  return hasTargetAccountId
    && !!this.input.approval_code
    && (!!this.input.amount && this.input.amount > 0)
    && !this.processing
}

computed.dialogTitle = function () {
  if (this.input.transaction_type === 1) return `KREDIT`
  else if (this.input.transaction_type === -1) return `DEBIT`
  else return `INPUT MUTASI`
}

/**
 * Methods
 *
 */
const methods = mapActions({
  reloadSummarize: `Finance/CashAccountSummarize/fetchData`,
  fetch: `CashAccount/Transaction/fetchData`,
  entry: `CashAccount/Transaction/update`,
})

methods.reset = function() {
  this.input = {
    amount: ``,
    account_id: ``,
    note: ``,
    approval_code: ``,
    type: null,
  }
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.SwitchDialog.show()
  this.reset()
}

methods.setType = function(type) {
  this.input.type = type

  this.$refs.EntryDialog.show()
  this.$refs.SwitchDialog.dismiss()
}

methods.addrecord = async function() {
  this.dismiss()

  const data = this.input

  if (this.input.type === `debit`) {
    data.amount = data.amount * -1
  }

  if (this.input.type === `mutate`) {

    // Entry to target cashaccount
    await this.entry({ data: {
      ...data,
      account_id: data.target_account_id,
    }})

    // Then debit selected account
    await this.entry({ data: {
      ...data,
      amount: data.amount * -1,
    }})

  } else {
    await this.entry({ data })
  }

  this.reloadSummarize()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
<div>
  <EntryDialog ref="SwitchDialog" :title="dialogTitle" :usebutton="false">
    <FormGroup>
      <SelectField
        label="Rekening *"
        defaultLabel="-- Pilih Rekening --"
        :options="cashAccountOptions"
        v-model="input.account_id" />
      <div class="col-xs-6">
        <Button
          :block="true"
          text="Kredit"
          color="primary"
          :disabled="!input.account_id"
          @click="setType(`credit`)" />
      </div>
      <div class="col-xs-6">
        <Button
          :block="true"
          text="Debit"
          color="primary"
          :disabled="!input.account_id"
          @click="setType(`debit`)" />
      </div>
      <div class="col-xs-12" style="margin-top: 20px">
        <Button
          :block="true"
          text="Pindah Saldo"
          color="primary"
          :disabled="!input.account_id"
          @click="setType(`mutate`)" />
      </div>
    </FormGroup>
  </EntryDialog>

  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validated" @submit="addrecord">
    <FormGroup>
      <SelectField v-if="input.type === `mutate`"
        label="Rekening Tujuan *"
        defaultLabel="-- Pilih Rekening --"
        :options="cashAccountOptions"
        v-model="input.target_account_id" />

      <TextField
        :label="(input.type === `credit`) ? `Jumlah Masuk *` : `Jumlah Dibayar *`"
        sublabel="Nilai harus lebih besar dari 0"
        :forceupper=true
        v-model="input.amount" />

      <TextField
        label="Catatan"
        v-model="input.note" />

      <TextField
        label="Ref Pembayaran"
        v-model="input.approval_code" />
    </FormGroup>
  </EntryDialog>
</div>
</template>