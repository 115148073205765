
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    input: {},
    courierOptions: [
      { label: `KURIR TOKO`, value: `COURIER` },
      { label: `MITRA`, value: `MITRA` },
    ],
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Courier/data`,
  processing: `Courier/loadingState`,
  employeeOptions: `Employee/options`,
})
computed.validate = function() {
  return !!this.input.type && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `Courier/fetchData`,
  entry: `Courier/update`,
})

methods.reset = function() {
  this.input = Object.assign({}, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = undefined
  return this.fetch(undefined).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id
  const successid = await this.entry({ id, data })

  if (successid) {
    this.dismiss()
  }
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Tipe Kurir"
        :options="courierOptions"
        v-model="input.type" />

      <SelectField v-if="input.type === `COURIER`"
        label="Nama Kurir"
        :options="employeeOptions"
        v-model="input.employee_id" />

      <TextField v-else
        label="Nama Mitra"
        :forceupper="true"
        v-model="input.name" />
    </FormGroup>
  </EntryDialog>
</template>