
import Vue from 'vue'

import Row from '@/components/uicomp/Row.vue'
Vue.component(`Row`, Row)

import Col6 from '@/components/uicomp/Col6.vue'
Vue.component(`Col6`, Col6)

import Col8 from '@/components/uicomp/Col8.vue'
Vue.component(`Col8`, Col8)

import Col10 from '@/components/uicomp/Col10.vue'
Vue.component(`Col10`, Col10)

import Col10Center from '@/components/uicomp/Col10Center.vue'
Vue.component(`Col10Center`, Col10Center)

import Col12 from '@/components/uicomp/Col12.vue'
Vue.component(`Col12`, Col12)

import Button from '@/components/uicomp/Button'
Vue.component(`Button`, Button)

import ButtonL from '@/components/uicomp/ButtonL'
Vue.component(`ButtonL`, ButtonL)

import ButtonR from '@/components/uicomp/ButtonR'
Vue.component(`ButtonR`, ButtonR)

import ButtonRXS from '@/components/uicomp/ButtonRXS'
Vue.component(`ButtonRXS`, ButtonRXS)

import FormGroup from '@/components/uicomp/FormGroup'
Vue.component(`FormGroup`, FormGroup)

import CheckButton from '@/components/uicomp/CheckButton'
Vue.component(`CheckButton`, CheckButton)

import SwitchButton from '@/components/uicomp/SwitchButton'
Vue.component(`SwitchButton`, SwitchButton)

import TextField from '@/components/uicomp/TextField'
Vue.component(`TextField`, TextField)

import TextFieldChangable from '@/components/uicomp/TextField-Changable'
Vue.component(`TextFieldChangable`, TextFieldChangable)

import TextArea from '@/components/uicomp/TextArea'
Vue.component(`TextArea`, TextArea)

import TextAreaChangable from '@/components/uicomp/TextArea-Changable'
Vue.component(`TextAreaChangable`, TextAreaChangable)

import SelectField from '@/components/uicomp/SelectField'
Vue.component(`SelectField`, SelectField)

import SelectFieldChangable from '@/components/uicomp/SelectField-Changable'
Vue.component(`SelectFieldChangable`, SelectFieldChangable)

import SelectDateChangable from '@/components/uicomp/SelectDate-Changable'
Vue.component(`SelectDateChangable`, SelectDateChangable)

import DatePicker from '@/components/uicomp/DatePicker'
Vue.component(`DatePicker`, DatePicker)

import TimePicker from '@/components/uicomp/TimePicker'
Vue.component(`TimePicker`, TimePicker)

import Block from '@/components/uicomp/Block'
Vue.component(`Block`, Block)

import BlockForm from '@/components/uicomp/Block-Form-Single'
Vue.component(`BlockForm`, BlockForm)

import BlockTable from '@/components/uicomp/Block-Table'
Vue.component(`BlockTable`, BlockTable)

import ButtonFormSet from '@/components/uicomp/Button-Form-Set'
Vue.component(`ButtonFormSet`, ButtonFormSet)

import Label from '@/components/uicomp/Label'
Vue.component(`Label`, Label)

import TableList from '@/components/uicomp/TableList'
Vue.component(`TableList`, TableList)

import TableOptions from '@/components/uicomp/TableOptions'
Vue.component(`TableOptions`, TableOptions)

import TableButton from '@/components/uicomp/TableButton'
Vue.component(`TButton`, TableButton)

import TableButtonEdit from '@/components/uicomp/TableButton-Edit'
Vue.component(`TButtonEdit`, TableButtonEdit)

import TableButtonDelete from '@/components/uicomp/TableButton-Delete'
Vue.component(`TButtonDelete`, TableButtonDelete)

import ModalDialog from '@/components/uicomp/ModalDialog'
Vue.component(`ModalDialog`, ModalDialog)

export default 0