
import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import moment from 'moment'

/**
 * Const
 *
 */
const api = new Api()



/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  itemlist: [],
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  data({ selectedObject: item }) {
    if (!Object.keys(item).length) return {}

    return {
      ...item,
      get locked_date_readable() {
        if (!this.locked_date) return ``
        return moment(this.locked_date).format(`DD/MM/YYYY HH:mm:ss`)
      },
      get date_readable() { return moment(this.date).format(`DD/MM/YYYY`) },
      get overload_value_readable() { return this.overload_value.toLocaleString() },
      get missing_value_readable() { return this.missing_value.toLocaleString() },
    }
  },

  list({ list }) {
    return list.map((item) => ({
      ...item,
      get date_readable() { return moment(this.date).format(`DD/MM/YYYY`) },
      get overload_value_readable() { return this.overload_value.toLocaleString() },
      get missing_value_readable() { return this.missing_value.toLocaleString() },
    }))
  },

  itemlistMeta({ itemlist }) {
    const base = {
      quantity: 0,
      modal: 0,
      scost: 0,
      get modal_readable() { return this.modal.toLocaleString() },
      get scost_readable() { return this.scost.toLocaleString() },
    }
    return itemlist.reduce((ac, item) => {
      ac.quantity += item.quantity
      ac.scost += item.worker_commission * item.quantity
      ac.modal += (item.price_asset + item.worker_commission) * item.quantity
      return ac
    }, base)
  },
  itemlist({ itemlist }) {
    return itemlist.map((item) => ({
      ...item,
      get price_asset_readable() { return this.price_asset.toLocaleString() },
      get diff_stock() { return this.actual_stock - this.current_stock },
      get price_overload() {
        return (this.diff_stock > 0) ? this.diff_stock * this.price_asset : 0
      },
      get price_overload_readable() {
        return this.price_overload.toLocaleString()
      },
      get price_missing() {
        return (this.diff_stock < 0) ? this.diff_stock * this.price_asset * -1 : 0
      },
      get price_missing_readable() {
        return this.price_missing.toLocaleString()
      },
    }))
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchList({ commit, getters }) {
    commit(`loading_mode`, 1)

    return api.get(`/sorecord?` + getters.listOptions)
      .then(({ data, nrecord }) => {
        commit(`loading_mode`, 0)
        commit(`set_list`, data)
        commit(`set_nrecord`, nrecord)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async fetchData({ commit }, id) {
    if (!id) return commit(`clear_selected_object`)

    commit(`loading_mode`, 1)

    return api.get(`/sorecord/${id}`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_selected_object`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async refetchData({ dispatch }) {
    const id = state.selectedObject.id
    return dispatch(`fetchData`, id)
  },

  async fetchListItem({ commit, state }) {
    const id = state.selectedObject.id

    if (!id) return commit(`clear_listitem`)

    commit(`loading_mode`, 1)

    return api.get(`/sorecord/${id}/item`)
      .then(({ data }) => {
        commit(`loading_mode`, 0)
        commit(`set_listitem`, data)
      })
      .catch(err => {
        commit(`loading_mode`, 0)
        commit(`show_error`, err.message)
        return 0
      })
  },

  async update({ commit, dispatch, rootState }, { id, data }) {
    commit(`loading_mode`, 1)

    if (!id) id = ``

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)
      await dispatch(`fetchData`, result.id)

      if (id) alert.success(`Data berhasil diupdate`)
      else alert.success(`Data berhasil ditambahkan`)

      return result.id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_INVSTOCKOP_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.put(`/sorecord/${id}`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async lock({ commit, dispatch, rootState }) {
    commit(`loading_mode`, 1)

    const id = state.selectedObject.id
    if (!id) return onerror()

    const onsuccess = async () => {
      commit(`loading_mode`, 0)

      alert.success(`Data berhasil diupdate`)
      return id
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_INVSTOCKOP_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.post(`/sorecord/${id}/lock`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async remove({ commit, rootState }, id) {
    commit(`loading_mode`, 1)

    const onsuccess = async () => {
      commit(`loading_mode`, 0)

      alert.success(`Data berhasil dihapus`)
      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_INVSTOCKOP_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    return api.delete(`/sorecord/${id}`)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },

  async updateListItem({ commit, dispatch, state, rootState }, data) {
    commit(`loading_mode`, 1)

    const onsuccess = async (result) => {
      commit(`loading_mode`, 0)
      commit(`clear_list`)
      await dispatch(`refetchData`)
      await dispatch(`fetchListItem`)

      return 1
    }

    const onerror = (err) => {
      commit(`loading_mode`, 0)
      alert.danger(err.message)
      return 0
    }

    if (!rootState.Auth.permission.includes(`ACCESS_INVSTOCKOP_M`)) {
      return onerror({ message: `Anda tidak memiliki permission untuk mengedit data ini `})
    }

    const id = state.selectedObject.id
    if (!id) return onerror()

    return api.put(`/sorecord/${id}/item`, data)
      .then(r => onsuccess(r.data))
      .catch(onerror)
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_listitem(state, list) {
    state.itemlist = list
  },

  clear_listitem(state) {
    state.itemlist = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}