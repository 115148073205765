
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    id: null,
    dialogTitle: `Tambah Record`,
    input: {},
    counterTypes: [
      { label: `STATIS`, value: `ST` },
      { label: `DINAMIS`, value: `DY` },
    ],
    accountCategory: [
      { label: `TOKO`, value: `STOR_ACC` },
      { label: `OPERASIONAL`, value: `OPRA_ACC` },
      { label: `PIUTANG`, value: `DEPT_ACC` },
      { label: `KASBON`, value: `CBON_ACC` },
    ],
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `OpPurchase/Account/data`,
  processing: `OpPurchase/Account/loadingState`,
  accountOptions: `CashAccount/options`,
})
computed.validate = function() {
  return !!this.input.name && !this.processing
}

/**
 * Methods
 *
 */
const methods = mapActions({
  fetch: `OpPurchase/Account/fetchData`,
  entry: `OpPurchase/Account/update`,
})

methods.reset = function() {
  this.input = Object.assign({ editable: true }, this.data)
}

methods.dismiss = function() {
  this.id = null
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.new = async function() {
  this.id = null
  this.dialogTitle = `Tambah Record`
  return this.fetch(null).then(this.show)
}

methods.edit = async function(id) {
  this.id = id
  this.dialogTitle = `Edit Record`
  return this.fetch(id).then(this.show)
}

methods.addrecord = async function() {
  const data = this.input
  const id = this.id

  const sortIndex = {
    STOR_ACC: 1,
    OPRA_ACC: 10,
    DEPT_ACC: 100,
    CBON_ACC: 110,
  }

  data.sort_index = sortIndex[data.category]

  await this.entry({ id, data })
  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" :title="dialogTitle" :valid="validate" @submit="addrecord">
    <FormGroup>
      <SelectField
        label="Kategori Akun"
        :options="accountCategory"
        v-model="input.category" />

      <SelectField
        label="Rekening"
        :options="accountOptions"
        v-model="input.account_id" />

      <TextField
        label="Nama Akun"
        :disabled="!input.editable"
        :forceupper=true
        v-model="input.name" />

      <div v-if="input.category === `OPRA_ACC`">
        <TextField
          v-if="input.counter_type == `MN`"
          :disabled=true
          label="Penghitungan Kredit"
          value="OLEH SISTEM" />

        <SelectField v-else
          label="Penghitungan Kredit"
          :disabled="!input.editable"
          :options="counterTypes"
          v-model="input.counter_type" />

        <TextField
          v-if="input.counter_type && input.counter_type == `ST`"
          label="Jumlah Dikredit Perbulan"
          :forceupper=true
          v-model="input.counter_formula" />

        <TextField
          v-if="input.counter_type && input.counter_type == `DY`"
          label="Persen Dikredit Dari Bruto"
          :forceupper=true
          v-model="input.counter_formula" />
      </div>

    </FormGroup>
  </EntryDialog>
</template>