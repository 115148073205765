
import Promise from 'bluebird'
import moment from 'moment'

import BaseModel from '../../../helpers/basemodel'
import alert from '../../../helpers/alert'
import Api from '../../../helpers/api'
import QueryString from '../../../helpers/query-string'

/**
 * Const
 *
 */
const api = new Api()
const defaultDate = moment()

/**
 * State
 *
 */
const state = {
  ...BaseModel.state,

  summary: {
    total_sales_income: 0,
    total_other_income: 0,
    total_store_purchase: 0,
    total_omzet: 0,
  },

  listOptions: {
    month: defaultDate.format(`MM`),
    year: defaultDate.format(`YYYY`),
  },
}


/**
 * Getters
 *
 */
const getters = {
  ...BaseModel.getters,

  currentPeriod({ listOptions: opt }) {
    return `${opt.year}${opt.month}`
  },

  prevPeriod({ listOptions: opt }) {
    return moment(`${opt.year}${opt.month}`, `YYYYMM`)
      .subtract(1, `month`)
      .format(`YYYYMM`)
  },

  listOptions({ listOptions: opt }) {
    const query = new QueryString([])

    if (opt.month && opt.year) {
      query.push(`period=${opt.year}${opt.month}`)
    }

    return query.string
  },

  summary(state) {
    return {
      ...state.summary,
      get omzet() { return this.total_sales_income + this.total_other_income - this.total_store_purchase },
    }
  },
}


/**
 * Actions
 *
 */
const actions = {
  ...BaseModel.actions,

  async fetchData({ commit, getters }) {
    commit(`loading_mode`, 1)

    const asyncTask = []

    asyncTask.push(async () => {
      return api.get(`/oppurchase/summarize?` + getters.listOptions)
        .then(({ data }) => {
          commit(`set_list`, data)
        })
        .catch(err => {
          commit(`show_error`, err.message)
          return 0
        })
    })

    asyncTask.push(async () => {
      return api.get(`/monthlysummary/${getters.currentPeriod}`)
        .then(({ data, period }) => {
          commit(`set_summary`, data)
        })
        .catch(err => {
          commit(`show_error`, err.message)
          return 0
        })
    })

    return Promise.map(asyncTask, t => t())
      .then(() => commit(`loading_mode`, 0))
  },

  async fetchDataCurrentPeriod({ commit, getters }, { override = true } = {}) {
    commit(`loading_mode`, 1)

    const period = defaultDate.format(`YYYYMM`)
    const asyncTask = []

    asyncTask.push(async () => {
      return api.get(`/oppurchase/summarize?period=${period}`)
        .then(({ data }) => {
          if (override) {
            commit(`set_list`, data)
          }

          return data
        })
        .catch(err => err)
    })

    asyncTask.push(async () => {
      return api.get(`/monthlysummary/${period}`)
        .then(({ data, period }) => {
          if (override) {
            commit(`set_summary`, data)
          }

          return data
        })
        .catch(err => err)
    })

    return Promise.map(asyncTask, t => t())
      .then(([account, journal]) => {
        commit(`loading_mode`, 0)
        return { account, journal }
      })
  },
}

/**
 * Mutations
 *
 */
const mutations = {
  ...BaseModel.mutations,

  set_summary(state, data) {
    state.summary = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}