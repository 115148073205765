
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import TableOptionsDialog from './ListOptionsDialog'
const components = {
  TableOptionsDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  data: `Product/SOpname/data`,
  list: `Product/SOpname/itemlist`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  reload: `Product/SOpname/fetchListItem`,
  updateListItem: `Product/SOpname/updateListItem`,
})

methods.updateActualStock = async function (product_id, actual_stock) {
  return this.updateListItem({
    product_id,
    actual_stock,
  })
}

/**
 * Watch
 *
 */
const watch = {
  data: {
    deep: true,
    handler(n, o) {
      n.id !== o.id && this.reload()
    },
  }
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
  await this.reload()
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  //
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>
    <TableList>
      <template #head>
        <th class="text-center" width="48">#</th>
        <th width="160" class="text-left">Kategori</th>
        <th class="text-left">Nama Produk</th>
        <th width="60" class="text-center">Stok Sistem</th>
        <th width="60" class="text-center">Stok Aktual</th>
        <th width="60" class="text-center">+/-</th>
        <th width="60" class="text-right">Harga Satuan</th>
        <th width="80" class="text-right">Modal Overload</th>
        <th width="80" class="text-right">Modal Hilang</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in list" :key="item.id">
          <td class="text-center">{{ index + 1 }}</td>
          <td>{{ item.category_name }}</td>
          <td>{{ item.product_name }}</td>
          <td class="text-center">{{ item.current_stock }}</td>
          <td class="text-center">
            <span v-if="data.locked">{{ item.actual_stock }}</span>
            <TextFieldChangable v-else
              :value="item.actual_stock || `0`"
              @submit="updateActualStock(item.product_id, $event)"
            />
          </td>
          <td class="text-center">{{ item.diff_stock || `-` }}</td>
          <td class="text-right">{{ item.price_asset_readable }}</td>
          <td class="text-right">{{ item.price_overload_readable }}</td>
          <td class="text-right">{{ item.price_missing_readable }}</td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>