
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

/**
 * Components
 *
 */
const components = {
}

/**
 * Static Data
 *
 */
const filterDateFrom = moment().format(`DD/MM/YYYY`)
const filterDateTo = moment().format(`DD/MM/YYYY`)
const data = function () {
  return {
    current_item_id: null,
    filter_datefrom: filterDateFrom,
    filter_dateto: filterDateTo,
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  listOriginal: `Product/ExpenditureReportItem/list`,
  meta: `Product/ExpenditureReportItem/listMeta`,
})

computed.list = function () {
  const group = []
  let currentGroup = null
  let currentGroupID = null
  let total_quantity = 0
  let total_expenditure = 0

  this.listOriginal.forEach((item) => {
    if (!currentGroupID) {
      currentGroupID = item.product_id
      currentGroup = []
    } else if (currentGroupID !== item.product_id) {
      group.push({ list: currentGroup, total_quantity, total_expenditure })

      currentGroupID = item.product_id
      currentGroup = []
      total_quantity = 0
      total_expenditure = 0
    }

    currentGroup.push(item)
    total_quantity += item.quantity
    total_expenditure += item.subtotal
  })

  group.push({ list: currentGroup, total_quantity, total_expenditure })

  return group
}

computed.totalExpend = function () {
  return this.list.reduce((total, group) => {
    total += group.total_expenditure
    return total
  }, 0)
}

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Product/ExpenditureReportItem/setListOption`,
  reload: `Product/ExpenditureReportItem/fetchList`,
  pagePrev: `Product/ExpenditureReportItem/pagePrev`,
  pageNext: `Product/ExpenditureReportItem/pageNext`,
  deleteItem: `Product/ExpenditureReportItem/remove`,
})

methods.showopt = function () {
  this.$refs.TableOptionsDialog.show()
}

methods.search = function (value) {
  this.setListOption({ search: value })
}

/**
 * Watch
 *
 */
const watch = {
  meta: {
    deep: true,
    handler(n, o) {
      n.version !== o.version && this.reload()
    },
  },
  filter_datefrom: {
    handler(value) {
      this.setListOption({ datefrom: value })
    }
  },
  filter_dateto: {
    handler(value) {
      this.setListOption({ dateto: value })
    }
  },
}

/**
 * Event OnMounted
 *
 */
const mounted = async function() {
}

/**
 * Event OnCreated
 *
 */
const created = async function() {
  if (this.meta.filter.format !== `deep`) {
    this.setListOption({ format: `deep` })
  }

  this.setListOption({ datefrom: this.filter_datefrom, dateto: this.filter_dateto })
}

/**
 * Event Socket
 *
 */
const sockets = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
  sockets,
}
</script>

<style scoped>
  .bg-green {
    background-color: #80ef7324;
  }
  .bg-orange {
    background-color: #f1bd6e40;
  }
</style>

<template>
  <Row>

    <Col12>
      <table class="no-style">
        <tr>
          <td width="140"></td>
          <td width="10"></td>
          <td></td>
        </tr>
        <tr>
          <td><label>PERIODE</label></td>
          <td><label>:</label></td>
          <td>
            <ul class="list-inline-style">
              <li><SelectDateChangable v-model="filter_datefrom"></SelectDateChangable></li>
              <li>-</li>
              <li><SelectDateChangable v-model="filter_dateto"></SelectDateChangable></li>
            </ul>
          </td>
        </tr>
        <tr>
          <td><label>TOTAL BELANJA</label></td>
          <td><label>:</label></td>
          <td>{{ totalExpend.toLocaleString() }}</td>
        </tr>
      </table>
    </Col12>

    <Col12>
      <br>
    </Col12>

    <TableList v-for="(groupitem, groupindex) in list" :key="groupindex">
      <template #head>
        <th width="40" class="text-center">#</th>
        <th width="120" class="text-left">Tanggal Order</th>
        <th width="140" class="text-left">Record</th>
        <th class="text-left">Nama Barang</th>
        <th width="100" class="text-right">Jumlah</th>
        <th width="100" class="text-right">Harga</th>
        <th width="100" class="text-right">Subtotal</th>
      </template>

      <template #body>
        <tr v-for="(item, index) in groupitem.list" :key="item.id">
          <td class="text-center" v-html="index + meta.curstart"></td>
          <td class="text-left">{{ item.parent_date_ordered_readable }}</td>
          <td class="text-left">
            <router-link class="link underlined" :to="`/rep/exp-transaction/${item.parent_id}`">
              {{ item.parent_record_id }}
            </router-link>
          </td>
          <td class="text-left">{{ item.product_name }}</td>
          <td class="text-right">{{ item.quantity }}</td>
          <td class="text-right">{{ item.price_bought_readable }}</td>
          <td class="text-right">{{ item.subtotal_readable }}</td>
        </tr>
        <tr class="custom-bg-primary-light">
          <td class="text-left"></td>
          <td class="text-left"><label>Total</label></td>
          <td class="text-left"></td>
          <td class="text-left"></td>
          <td class="text-right">{{ groupitem.total_quantity }}</td>
          <td class="text-right"></td>
          <td class="text-right">{{ groupitem.total_expenditure.toLocaleString() }}</td>
        </tr>
      </template>
    </TableList>
  </Row>
</template>