
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/**
 * Const
 *
 */
const debug = process.env.NODE_ENV !== 'production'

/**
 * Modules
 *
 * */
const modules = {}

import Master from './modules/master'
modules.Master = Master

import Auth from './modules/auth'
modules.Auth = Auth

import Store from './modules/store'
modules.Store = Store

import Product from './modules/product'
modules.Product = Product

import PayMethod from './modules/paymentmethod'
modules.PayMethod = PayMethod

import Customer from './modules/customer'
modules.Customer = Customer

import Employee from './modules/employee'
modules.Employee = Employee

import CashAccount from './modules/cashaccount'
modules.CashAccount = CashAccount

import Sales from './modules/sales'
modules.Sales = Sales

import SalesReport from './modules/sales-report'
modules.SalesReport = SalesReport

import SalesReportItem from './modules/sales-report-item'
modules.SalesReportItem = SalesReportItem

import SalesReportPayment from './modules/sales-report-payment'
modules.SalesReportPayment = SalesReportPayment

import Courier from './modules/courier'
modules.Courier = Courier

import Finance from './modules/finance'
modules.Finance = Finance

import StPurchase from './modules/stpurchase'
modules.StPurchase = StPurchase

import OpPurchase from './modules/oppurchase'
modules.OpPurchase = OpPurchase

import Report from './modules/report'
modules.Report = Report

/**
 * Export
 *
 * */
import base from './modules/base'
export default new Vuex.Store({
  ...base,
  modules,
  strict: debug,
})