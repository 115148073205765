<script>
export default {
  props: {
    c: {
      type: String,
      default: ``
    },
    t: {
      type: String,
      default: ``,
    }
  },
  computed: {
    classes() {
      return {
        'label-danger': this.c === `danger`,
        'label-success': this.c === `success`,
        'label-info': this.c === `info`,
        'label-warning': this.c === `warning`,
      }
    },
  }
}
</script>

<template>
  <label class="label" :class="classes" data-toggle="tooltip" :title="t">
    <slot />
  </label>
</template>