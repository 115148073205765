
import Vue from 'vue'

import faedit from './fa-edit.vue'
Vue.component(`fa-edit`, faedit)

import faclose from './fa-close.vue'
Vue.component(`fa-close`, faclose)

import fasearch from './fa-search.vue'
Vue.component(`fa-search`, fasearch)

import fafilter from './fa-filter.vue'
Vue.component(`fa-filter`, fafilter)

import faphone from './fa-phone.vue'
Vue.component(`fa-phone`, faphone)

import fatag from './fa-tag.vue'
Vue.component(`fa-tag`, fatag)

import fatruck from './fa-truck.vue'
Vue.component(`fa-truck`, fatruck)

export default 0