
<script>

/**
 * Components
 *
 */
const components = {
  //
}

/**
 * Static Data
 *
 */
const props = {
  objdata: {
    type: Object,
    default: () => ({ name: ``, amount: 0, type: `` })
  },

  ableChangeName: {
    type: Boolean,
    default: false,
  },

  ableChangeAmount: {
    type: Boolean,
    default: false,
  },

  ableChangeAccount: {
    type: Boolean,
    default: false,
  },

  ableDelete: {
    type: Boolean,
    default: false,
  },
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    //
  }
}

/**
 * Computed
 *
 */
const computed = {}

computed.isIncome = function () {
  return this.objdata.type.startsWith(`SI_`)
}

computed.amount = function () {
  return this.objdata.amount && this.objdata.amount.readable(0)
}

computed.account = function () {
  return this.objdata.account
    ? this.objdata.account.name
    : this.isIncome
      ? `<i class="subdata"> (Dibayarkan) </i>`
      : `<i class="subdata"> (Tidak ada) </i>`
}

computed.separator = function () {
  return `Rp.`
}

/**
 * Methods
 *
 */
const methods = {}

methods.setAmount = async function (amount) {
  this.$emit(`setData`, {
    amount,
    id: this.objdata.id,
  })
}

methods.setName = async function (name) {
  this.$emit(`setData`, {
    name,
    id: this.objdata.id,
  })
}

methods.setAccount = async function () {
  this.$emit(`setAccount`, {
    id: this.objdata.id
  })
}

methods.remove = async function () {
  this.$emit(`delete`, {
    id: this.objdata.id,
    name: this.objdata.name
  })
}

/**
 * Watchers
 *
 */
const watch = {}

/**
 * Event OnMounted
 *
 */
const mounted = function() {
  //
}

/**
 * Event OnCreated
 *
 */
const created = function() {
  //
}

export default {
  components,
  props,
  data,
  computed,
  methods,
  watch,
  mounted,
  created,
}
</script>

<style scoped>
</style>

<template>
  <tr>
    <td class="text-left">
      <TextFieldChangable v-if="ableChangeName"
        :placeholder="isIncome ? `Nama Penerimaan` : `Nama Potongan`"
        :value="objdata.name"
        :forceupper="true"
        @submit="setName"
      />
      <span v-else>{{ objdata.name }}</span>
    </td>

    <td class="text-left">
      <span v-if="ableChangeAccount">
        <a href="#" @click.prevent="setAccount">
          <span class="text-editable" v-html="account"></span>
        </a>
      </span>
      <span v-else v-html="account"></span>
    </td>

    <td class="text-right">
      <span>{{ separator }}</span>
    </td>

    <td class="text-right">
      <TextFieldChangable v-if="ableChangeAmount"
        placeholder="0"
        :value="amount"
        @submit="setAmount"
      />

      <span v-else class="subdata"><i>(Otomatis)</i></span>
    </td>

    <td class="text-left">
      <span v-if="objdata.isPerMinute" class="subdata"><i>/menit</i></span>
      <span v-else-if="objdata.isDaily" class="subdata"><i>/hari</i></span>
      <span v-else class="subdata"><i>/bulan</i></span>
    </td>

    <td class="text-right">
      <TButton v-if="ableDelete" color="danger" icon="trash" @click="remove" />
    </td>
  </tr>
</template>