
<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Components
 *
 */
import EntryDialog from '@/components/uicomp/ModalDialog-Form-Single'
const components = {
  EntryDialog,
}

/**
 * Static Data
 *
 */
const data = function () {
  return {
    opt: {},
    nlimitOptions: [
      { label: `10`, value: 10 },
      { label: `20`, value: 20 },
      { label: `50`, value: 50 },
      { label: `100`, value: 100 },
    ]
  }
}

/**
 * Computed
 *
 */
const computed = mapGetters({
  meta: `Customer/listMeta`,
  processing: `Customer/loadingState`,
  customerGroupOptions: `Customer/Group/options`,
})

/**
 * Methods
 *
 */
const methods = mapActions({
  setListOption: `Customer/setListOption`,
})

methods.reset = function() {
  this.opt = Object.assign({}, this.meta)
}

methods.dismiss = function() {
  this.$refs.EntryDialog.dismiss()
}

methods.show = function() {
  this.$refs.EntryDialog.show()
  this.reset()
}

methods.save = async function() {
  this.setListOption({
    pagelimit: this.opt.pagelimit,
    group: this.opt.group,
  })
  this.dismiss()
}

/**
 * Watch
 *
 */
const watch = {
}

export default {
  components,
  data,
  computed,
  methods,
  watch,
}
</script>

<style scoped>
</style>

<template>
  <EntryDialog ref="EntryDialog" title="Table Setting" @submit="save">
    <FormGroup>
      <SelectField
        label="Record Per Page"
        :forceupper=true
        :options=nlimitOptions
        v-model="opt.pagelimit" />

      <SelectField
        label="Grup"
        :forceupper=true
        :options=customerGroupOptions
        defaultValue=""
        defaultLabel="Semua Grup"
        v-model="opt.group" />
    </FormGroup>
  </EntryDialog>
</template>